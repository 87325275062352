import React, { useState, useEffect, useCallback } from 'react'

const updateVh = setVh => {
  // eslint-disable-next-line
  const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  const vh = iOS && isSafari ? window.innerHeight : window.innerHeight
  setVh(vh)
}

export function useAvailableHeight() {
  const [vh, setVh] = useState(0)
  const update = useCallback(() => updateVh(setVh), [setVh])

  useEffect(() => {
    update()
    window.addEventListener('resize', update)

    return function cleanup() {
      window.removeEventListener('resize', update)
    }
  })

  return [vh]
}

function AvailableHeight() {
  return Child => {
    const WrappedComponent = Child

    class Container extends React.Component {
      static WrappedComponent = WrappedComponent
      state = { vh: 0 }

      updateVh = () => {
        // eslint-disable-next-line
        const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
        const iOS =
          /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
        const vh =
          iOS && isSafari ? window.innerHeight - 30 : window.innerHeight

        this.setState({ vh })
      }

      componentDidMount() {
        this.updateVh()
        window.addEventListener('resize', this.updateVh)
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.updateVh)
      }

      render() {
        const { vh } = this.state
        const { children, ...childProps } = this.props
        return (
          <Child availableHeight={vh} {...childProps}>
            {children}
          </Child>
        )
      }
    }

    return Container
  }
}

export default AvailableHeight
