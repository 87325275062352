import { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { setup as xhrSetup, options } from '@logicea/xhr-helpers/lib'

const Setup = () => {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    xhrSetup('/api', {
      loginUrl: `/home${location.search}`,
      pushFn: history.push,
      headers: { ...options.headers, scope: 'ui', client: 'browser' },
    })
  }, [location.search, history.push])

  useEffect(() => {
    window.ga && window.ga('send', 'pageview', location.pathname)
  }, [location.pathname])

  return null
}

export default Setup
