import React, { useContext, useEffect } from 'react'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import {
  GA,
  Gtag,
  FBE,
  SnapChat,
  HotJar,
  Antifraud,
  TikTok,
  Twitter,
} from 'components/HeaderScripts'
import { useDataLayerEvents } from 'containers/dataLayerEvents'
import { AuthenticationContext } from 'containers/AuthProvider'

const Analytics = () => {
  const {
    meta: {
      trackerId,
      gtagId,
      fbeId,
      snapChatId,
      hotjarId,
      gtagId2,
      tikTokId,
      screenViews = {},
      defaultLang,
      twitterId,
    },
  } = React.useContext(ConfigurationContext)
  const { auth } = useContext(AuthenticationContext)
  const { dataLayerPush } = useDataLayerEvents()
  const user = auth ? auth.user : {}
  const { username, id } = user
  const userId = !!id ? id : ''
  const userTypeAdvanced = !!username
    ? user?.userStatus === 'SUBSCRIBED'
      ? 'subscribed'
      : 'registered'
    : 'anonymous'
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const dataLayerObject = {
    event: 'pageView',
    screenName: window.location.pathname,
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userTypeAdvanced,
  }
  const page = window.location.pathname.replace('/', '')
  const allScreenViews = screenViews[page]
  useEffect(() => {
    if (!!allScreenViews) {
      dataLayerPush(dataLayerObject)
    }
  }, [auth.user.username, window.location.pathname])

  return (
    <React.Fragment>
      <GA trackerId={trackerId} />
      <Gtag gtagId={gtagId} />
      <Gtag gtagId={gtagId2} />
      <FBE fbeId={fbeId} />
      <SnapChat snapChatId={snapChatId} />
      <HotJar hotjarId={hotjarId} />
      <TikTok tikTokId={tikTokId} />
      <Twitter twitterId={twitterId} />
      <Antifraud />
    </React.Fragment>
  )
}

export default Analytics
