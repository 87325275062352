import * as R from 'ramda'
import React, { useContext, useState, useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'react-emotion'
import { themeProp } from 'utils/theming'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { __RouterContext } from 'react-router'
import { I18nContext } from 'containers/I18nProvider'
import useMobileQuery from 'containers/MobileQuery'

const StyledFooter = styled('div')`
  position: relative;
  height: 85px;
  line-height: 85px;
  font-size: 14px;
  color: ${({ theme }) => themeProp(['footer', 'color'], theme)};
  background-color: ${({ theme }) =>
    themeProp(['footer', 'backgroundColor'], theme)};
  display: flex;
  flex-direction: ${({ rtl }) => (rtl ? 'row-reverse' : 'row')};
  justify-content: space-between;
  z-index: ${({ shareLinkButton }) => (!shareLinkButton ? '13' : '11')};
  padding: 0 80px;
  > div {
    z-index: 11;
    text-align: ${({ rtl }) => (rtl ? 'right' : 'left')};
  }
`

const FooterLinks = styled('ul')`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
  gap: 70px;

  > li {
    list-style: none;
  }
`
export const SocialMediaContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: ${({ rtl }) => (rtl ? 'flex-start' : 'flex-end')};
  gap: ${({ mobile }) => (mobile ? '20px' : '30px')};
`
export const SocialMediaLink = styled('a')`
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;

  &:first-child {
    width: 10px;
  }
`

export const socialClass = imgurl => css`
  background-image: url(${imgurl});
`
const socialMediaOffeset = centerFooter => css`
  width: ${centerFooter};
`

const shareLinkBtnWrapper = (theme, shareBtnPopupState) => css`
  display: flex;
  position: relative;
  background-image: url(${themeProp(['footer', 'shareIcon'], theme)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  background-color: ${themeProp(['footer', 'shareBtnBackgroundColor'], theme)};
  padding: 3px;
  border-radius: ${themeProp(['footer', 'shareBtnBorderRadius'], theme)};

/*   &:before {
    content: '';
    display: ${shareBtnPopupState ? 'block' : 'none'};
    position: absolute;
    z-index: 9998;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid
      ${themeProp(['footer', 'shareBtnPopupBackground'], theme)};
  } */
`
const shareLinkBtn = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  color: transparent;
  cursor: pointer;
`

const shareLinkPopup = (theme, rtl) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7px;
  position: absolute;
  width: 190px;
  height: 65px;
  z-index: 9999;
  top: -70px;
  right: ${!rtl ? '0' : ''};
  left: ${rtl ? '0' : ''};
  transform: translateX(0);
  padding: 0 20px;
  color: ${themeProp(['footer', 'shareBtnPopupColor'], theme)};
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background: ${themeProp(['footer', 'shareBtnPopupBackground'], theme)};
  border-radius: 3px;
  box-shadow: ${themeProp(['footer', 'shareBtnClosePopupShadow'], theme)};
`

const shareLinkCloseBtn = (theme, rtl) => css`
  position: absolute;
  background: ${themeProp(['footer', 'shareBtnCloseBtnBackground'], theme)};
  width: 25px;
  height: 25px;
  z-index: 99999;
  cursor: pointer;
  border-radius: 50%;
  top: -12px;
  right: ${!rtl ? '-12px' : ''};
  left: ${rtl ? '-12px' : ''};
  box-shadow: ${themeProp(['footer', 'shareBtnCloseBtnphadow'], theme)};
  background-image: url(${themeProp(['footer', 'shareBtnCloseIcon'], theme)});
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 11px;
  background-position: center;
`
const shareLinkInput = css`
  width: 100%;
  padding: 0;
  margin: 0;
  border: 1px solid #00000030;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 14px;
  padding: 4px;
`

const Footer = ({ items }) => {
  const {
    i18n: { t, rtl },
  } = useContext(I18nContext)
  const { theme, meta } = useContext(ConfigurationContext)
  const { location } = useContext(__RouterContext)
  const { isMobile } = useMobileQuery()
  const returnTo = location.state ? location.state.returnTo : location.pathname
  const socialMedia = meta.social
  const { shareLinkButton } = meta
  const [centerFooter, setCenterFooter] = useState('auto')
  const socialmediacontainer = useRef()
  const shareContainerRef = useRef(null)
  const sharBtnRef = useRef(null)
  const shareInputRef = useRef(null)
  const handleFooterCentering = setCenterFooter => {
    const SocialMediaWidth = socialmediacontainer?.current?.offsetWidth
    return setCenterFooter(`${SocialMediaWidth}px`)
  }

  const [shareBtnPopupState, setShareBtnPopupState] = useState(false)

  const toggleSharePopup = () => {
    return setShareBtnPopupState(!shareBtnPopupState)
  }

  const handleClickOutsidePopup = event => {
    const targetCheck =
      shareContainerRef?.current &&
      !shareContainerRef?.current.contains(event?.target) &&
      !sharBtnRef?.current.contains(event?.target)
    if (targetCheck) return setShareBtnPopupState(false)
  }

  const windowLocation = window.location
  const host = R.join('', [
    windowLocation.protocol,
    '//',
    windowLocation.host,
    '/home?utm_source=friend',
  ])
  useEffect(() => {
    document.addEventListener('mousedown', e => {
      handleClickOutsidePopup(e)
    })
    return () => {
      document.removeEventListener('mousedown', e => {
        handleClickOutsidePopup(e)
      })
    }
  })
  useEffect(() => {
    if (shareBtnPopupState) return shareInputRef?.current.select()
  }, [shareBtnPopupState, shareInputRef])
  useEffect(() => {
    handleFooterCentering(setCenterFooter)
  }, [socialmediacontainer])

  return (
    <StyledFooter rtl={rtl} theme={theme} shareLinkButton={shareLinkButton}>
      <div className={socialMediaOffeset(centerFooter)} />
      <div>
        {items ? (
          <FooterLinks>
            {items.map((item, idx) => (
              <li key={idx}>
                <NavLink
                  to={{
                    pathname: item.url,
                    state: { returnTo },
                    search: location.search,
                  }}
                >
                  {t(item.textKey)}
                </NavLink>
              </li>
            ))}
          </FooterLinks>
        ) : null}
      </div>
      <React.Fragment>
        {socialMedia ? (
          <SocialMediaContainer
            innerRef={socialmediacontainer}
            rtl={rtl}
            mobile={isMobile}
          >
            {shareLinkButton ? (
              <div className={shareLinkBtnWrapper(theme, shareBtnPopupState)}>
                <div
                  ref={sharBtnRef}
                  className={shareLinkBtn}
                  onClick={toggleSharePopup}
                ></div>
                {shareBtnPopupState ? (
                  <div
                    ref={shareContainerRef}
                    className={shareLinkPopup(theme, rtl)}
                  >
                    <div>{'share.clipboard.tooltip.a'}</div>
                    <input
                      ref={shareInputRef}
                      className={shareLinkInput}
                      value={host}
                      readOnly
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {socialMedia.map((item, idx) => (
              <SocialMediaLink
                target="_blank"
                rtl={rtl}
                key={idx}
                href={item.link}
                className={socialClass(item.backgroundImage)}
              />
            ))}
          </SocialMediaContainer>
        ) : null}
      </React.Fragment>
    </StyledFooter>
  )
}

export default Footer
