import React from 'react'
import useMobileQuery from 'containers/MobileQuery'
import ContentLoader from 'react-content-loader'

const fullWidth = window.innerWidth
const fullHeight = window.innerHeight
const headerHeight = fullHeight / 20
const inputHeight = fullHeight / 15
const lineHeight = fullHeight / 50
const sidebarWidth = fullWidth / 4
const contentHeight = fullHeight / 2
const contentTop = fullHeight / 3

const MobileContent = () => (
  <React.Fragment>
    <circle cx="50" cy={40} r="20" />
    <rect
      x="75"
      y={30}
      rx="0"
      ry="0"
      width={sidebarWidth - 50}
      height={lineHeight}
    />
    <circle cx={fullWidth - fullWidth / 10} cy="40" r="15" />
    <rect
      x="25"
      y={100}
      rx="0"
      ry="0"
      width={fullWidth - 50}
      height={lineHeight}
    />
    <rect
      x="25"
      y={100 + lineHeight * 2}
      rx="0"
      ry="0"
      width={fullWidth - 50}
      height={lineHeight}
    />
    <rect
      x="25"
      y={contentTop - 40}
      rx="0"
      ry="0"
      width={fullWidth - 50}
      height={contentHeight}
    />
    <rect
      x="25"
      y={fullHeight - inputHeight * 3}
      rx="0"
      ry="0"
      width={fullWidth - 50}
      height={inputHeight}
    />
    <rect
      x="25"
      y={fullHeight - inputHeight * 3 + inputHeight * 1.5}
      rx="0"
      ry="0"
      width={fullWidth - 50}
      height={inputHeight}
    />
  </React.Fragment>
)

const DesktopContent = () => (
  <React.Fragment>
    <circle cx={fullWidth - fullWidth / 10} cy="30" r="15" />
    <circle cx="50" cy={contentTop - 40} r="20" />
    <rect
      x="75"
      y={contentTop - 47}
      rx="0"
      ry="0"
      width={sidebarWidth - 50}
      height={lineHeight}
    />
    <rect
      x="25"
      y={contentTop}
      rx="0"
      ry="0"
      width={sidebarWidth}
      height={lineHeight}
    />
    <rect
      x="25"
      y={contentTop + lineHeight * 2}
      rx="0"
      ry="0"
      width={sidebarWidth}
      height={lineHeight}
    />
    <rect
      x="25"
      y={contentTop + lineHeight * 6}
      rx="0"
      ry="0"
      width={sidebarWidth}
      height={inputHeight}
    />
    <rect
      x="25"
      y={contentTop + lineHeight * 6 + inputHeight * 1.5}
      rx="0"
      ry="0"
      width={sidebarWidth}
      height={inputHeight}
    />
    <rect
      x={fullWidth / 2.5}
      y={contentTop}
      rx="0"
      ry="0"
      width={fullWidth / 2}
      height={contentHeight}
    />
    <rect
      x="0"
      y={fullHeight - headerHeight}
      rx="0"
      ry="0"
      width={fullWidth}
      height={headerHeight}
    />
  </React.Fragment>
)

const LoadingIntro = () => {
  const { isMobile } = useMobileQuery()
  return (
    <ContentLoader
      height={fullHeight}
      width={fullWidth}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      {isMobile ? <MobileContent /> : <DesktopContent />}
    </ContentLoader>
  )
}

export default LoadingIntro
