import React from 'react'
import Button from 'components/Button'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import Pixel from 'containers/Pixel'
import styled, { cx, css } from 'react-emotion'
import { Private } from 'lib/auth-utils'
import { styles as answerStyles } from 'components/Game/AnswerSection'
import { themeProp } from 'utils/theming'
import { gameVerticalClass } from 'components/Game/layouts/Default'
import { Link } from 'react-router-dom'

const WelcomeSection = styled('div')`
  width: calc(100vw - 54px);
  max-width: 580px;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  > div {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: ${({ theme }) =>
      themeProp(['page', 'backgroundColor'], theme)};
  }
`

const WelcomeContainer = styled('div')`
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`

const WelcomeHeader = styled('h4')`
  max-width: 300px;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin: 0 auto 10px;
  font-weight: normal;
  color: ${({ theme }) => themeProp(['content', 'headerColor'], theme)};
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
`

const imageContainerClass = css`
  margin: 0 auto;
  height: 90px;
  width: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
`

const WelcomeSubHeader = styled('p')`
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  margin: 15px 10px;
  color: ${({ theme }) => themeProp(['content', 'subheaderColor'], theme)};
  > span {
    display: block;
  }
`

const buttonContainer = css`
  width: calc(100% - 40px);
  margin: 0 20px;
  > a:first-child {
    width: 75%;
  }
`

const backHome = css`
  text-decoration: underline;
  color: rgb(43, 30, 135) !important;
  padding: 12px 0 0;
`

const pageClass = ({ theme, mobile, nologo }) => css`
  font-family: ${themeProp(['page', 'fontFamily'], theme)};
  color: ${themeProp(['page', 'color'], theme)};
  background-color: ${themeProp(['page', 'backgroundColor'], theme)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${mobile ? '50%' : '50%'};
  width: 100vw;
  height: 100dvh;
  display: flex;
  margin: 0 auto;
  flex-flow: column;
  transition: background-color 0.2s ease-in;
  > div:nth-child(1) {
    min-height: ${nologo ? 0 : '56px'};
    max-height: ${nologo ? 'fit-content' : '103px'};
  }
`
const Layout = ({
  location,
  user,
  theme,
  availableHeight,
  rtl,
  t,
  canPlay,
  canBuy,
  userStatus,
  onChange,
  onResubscribe,
  gamePath,
}) => (
  <Private user={user}>
    <BackgroundImageWrapper
      imgsrc={themeProp(['page', 'backgroundImage'], theme)}
      className={pageClass({ theme, nologo: true })}
    >
      <BackgroundImageWrapper
        className={gameVerticalClass({ theme, availableHeight })}
        theme={theme}
      >
        <WelcomeSection theme={theme}>
          <div>
            <WelcomeContainer>
              <WelcomeHeader theme={theme} rtl={rtl}>
                {t(`welcome.header`)}
              </WelcomeHeader>
              <BackgroundImageWrapper
                className={imageContainerClass}
                theme={theme}
                imgsrc={themeProp(['content', 'backgroundImage'], theme)}
              />

              <WelcomeSubHeader theme={theme}>
                <span>{t(`welcome.subheader.a`)}</span>
                <span>{t(`welcome.subheader.b`)}</span>
              </WelcomeSubHeader>
              <div className={cx(answerStyles.bottom, buttonContainer)}>
                {canPlay ? (
                  <Button
                    to={{ pathname: gamePath, search: location.search }}
                    text={t('play.button')}
                    theme={theme}
                  />
                ) : canBuy ? (
                  <Button
                    to={{ pathname: '/credits', search: location.search }}
                    text={t('buy.button')}
                    theme={theme}
                  />
                ) : userStatus === 'UNSUBSCRIBED' ||
                  userStatus === 'REGISTERED' ? (
                  <Button
                    text={t('login.button.start')}
                    onClick={onResubscribe(user, onChange)}
                    theme={theme}
                  />
                ) : (
                  <Button
                    text={t('play.button')}
                    theme={theme}
                    disabled={true}
                  />
                )}

                <Link
                  className={backHome}
                  to={{ pathname: '/home', search: location.search }}
                >
                  {t('result.back.home')}
                </Link>
              </div>
            </WelcomeContainer>
          </div>
        </WelcomeSection>
        <Pixel />
      </BackgroundImageWrapper>
    </BackgroundImageWrapper>
  </Private>
)

export default Layout
