import React, { useContext, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { AuthenticationContext } from 'containers/AuthProvider'
import {
  TPayImpl,
  BeelineImpl,
  IndosatImpl,
  CelcomImpl,
  DjezzyImpl,
  NgaImpl,
  UcellImpl,
  GloNgaImpl,
} from './implementations'

const HE = ({ location, history }) => {
  const { auth } = useContext(AuthenticationContext)
  const { meta } = useContext(ConfigurationContext)
  const { headerOperator: { id: headerOperatorId } = {} } = meta
  let rendered = undefined
  switch (headerOperatorId) {
    case 'TPAY':
      rendered = <TPayImpl auth={auth} />
      break
    case 'BEELINE':
      rendered = (
        <BeelineImpl auth={auth} location={location} history={history} />
      )
      break
    case 'INDOSAT':
      rendered = (
        <IndosatImpl auth={auth} location={location} history={history} />
      )
      break
    case 'DJEZZY':
      rendered = (
        <DjezzyImpl auth={auth} location={location} history={history} />
      )
      break
    case 'CELCOM':
      rendered = (
        <CelcomImpl auth={auth} location={location} history={history} />
      )
      break
    case 'MTNNGA':
      rendered = <NgaImpl auth={auth} location={location} history={history} />
      break
    case 'UCELL':
      rendered = <UcellImpl auth={auth} location={location} history={history} />
      break
    case 'GLONGA':
      rendered = (
        <GloNgaImpl auth={auth} location={location} history={history} />
      )
      break
    default:
      rendered = <Fragment />
      break
  }

  return rendered
}

export default withRouter(HE)
