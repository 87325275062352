import { useState, useEffect, useCallback } from 'react'
import { mediaBreakpoints } from 'utils/theming'

const checkMedia = setIsMobile => {
  const availableWidth = Number(document.body.clientWidth)
  const breakpoint = Number(mediaBreakpoints.phoneLimit)
  const isMobile = availableWidth <= breakpoint
  setIsMobile(isMobile)
}

const useMobileQuery = () => {
  const [isMobile, setIsMobile] = useState(false)
  const update = useCallback(() => checkMedia(setIsMobile), [setIsMobile])

  useEffect(() => {
    update()
    window.addEventListener('resize', update)

    return () => window.removeEventListener('resize', update)
  }, [update])

  return { isMobile }
}

export default useMobileQuery
