import * as R from 'ramda'
import React from 'react'
import styled from 'react-emotion'
import { themeProp, hexToRgbA } from 'utils/theming'

export const Select = styled('select')`
  outline: none;
  height: 50px;
  width: 210px;
  line-height: 50px;
  background-color: ${({ theme }) =>
    themeProp(['input', 'backgroundColor'], theme)};
  border-radius: 100px;
  font-size: 14px;
  color: ${({ theme }) => themeProp(['input', 'color'], theme)};
  padding: 0 20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, error }) =>
    error
      ? '#e74c3c'
      : `${hexToRgbA(themeProp(['input', 'borderColor'], theme), 0.4)}`};
`

const ErrorText = styled('span')`
  font-size: 12px;
  color: #e74c3c;
`

const hasError = (name, errors) =>
  errors && errors.length
    ? R.compose(
        R.contains(name),
        R.pluck('fieldname'),
        R.propOr([], 'fields')
      )(errors)
    : false

const getError = errors => R.pathOr(null, ['error', 'key'], errors)

const SelectText = ({
  theme,
  errors,
  name,
  placeholder,
  value,
  options,
  onChange,
  t,
}) => (
  <React.Fragment>
    <Select
      theme={theme}
      error={hasError(name, errors)}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    >
      {options.map((o, idx) => (
        <option key={idx} value={o.value} disabled={idx === 0}>
          {o.label}
        </option>
      ))}
    </Select>
    {getError(errors) ? <ErrorText>{t(getError(errors))}</ErrorText> : null}
  </React.Fragment>
)

export default SelectText
