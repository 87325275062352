import * as R from 'ramda'
import React, { useState, useContext } from 'react'
import qs from 'query-string'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import styled, { css } from 'react-emotion'
import { NavLink, withRouter } from 'react-router-dom'
import { Spring } from 'react-spring'
import { themeProp } from 'utils/theming'
import { IsAuthenticated } from 'lib/auth-utils'
import { drawerLeft, drawerRight } from 'animations'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { AuthenticationContext } from 'containers/AuthProvider'
import { __RouterContext } from 'react-router'
import {
  SocialMediaContainer,
  SocialMediaLink,
  socialClass,
} from 'components/Footer'
import { I18nContext } from 'containers/I18nProvider'
import useMobileQuery from 'containers/MobileQuery'
import { useExternalTnc } from 'containers/ExternalTnc'

const Container = styled('div')`
  position: absolute;
  top: 0;
  left: ${({ rtl }) => (rtl ? 'auto' : '0')};
  right: ${({ rtl }) => (rtl ? '0' : 'auto')};
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
  z-index: 10;
  width: calc(100vw - 100px);
  height: 100dvh;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: ${({ theme }) =>
    themeProp(['usermenu', 'backgroundColor'], theme)};
  transform: ${({ moveX }) =>
    moveX ? `translateX(${moveX}px) !important` : 'none'};
`

const Overlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  z-index: 3;
`

const MenuItem = styled('div')`
  font-size: 14px;
  height: 35px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => themeProp(['usermenu', 'mobileColor'], theme)};
  > a {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    > span {
      font-size: 24px;
      width: 40px;
      padding: 0 15px 0 0;
    }
  }
`

const Separator = styled('div')`
  width: 100%;
  height: 1px;
  opacity: 0.4;
  margin: 18px 0;
  background-color: ${({ theme }) =>
    themeProp(['usermenu', 'mobileColor'], theme)};
`

const Icon = rtl => css`
  display: block;
  width: 22px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: ${!rtl ? '0 10px 0 0' : '0 0 0 10px'};
`
const LogoutContainer = styled('div')`
  margin-top: 6px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => themeProp(['usermenu', 'mobileColor'], theme)};
  > div {
    display: inline-block;
  }
`
const socialMenu = css`
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  > a {
    &:first-child {
      width: 10px;
    }
  }
`

const disableTncButton = css`
  pointer-events: none;
`

const shareLinkBtn = theme => css`
  position: relative;
  background-image: url(${themeProp(['footer', 'mobileShareIcon'], theme)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
`

const MenuLinks = ({ user, routes, location, t, rtl, theme }) => {
  const filterRoutes = user.canSelectGame
    ? routes
    : routes.filter(r => r.url !== '/select-game')
  return R.map(r => {
    const link = (
      <MenuItem key={r.url} theme={theme}>
        <NavLink
          to={{
            pathname: r.url,
            search: location.search,
            state: { returnTo: location.pathname, menu: false },
          }}
          replace
        >
          <BackgroundImageWrapper
            className={Icon(rtl)}
            imgsrc={`/images/${r.iconName}_icon.png`}
          />
          {t(`${r.url.replace('/', '')}.link`)}
        </NavLink>
      </MenuItem>
    )
    if (r.url === '/daily-content') {
      return user && user.enableContent ? IsAuthenticated(user)(link) : null
    } else return r.private ? IsAuthenticated(user)(link) : link
  }, filterRoutes)
}

const FooterLinks = ({ routes, location, t, rtl, theme, tncPath }) => {
  return R.map(r => {
    const Link = () =>
      r.external ? (
        <a
          className={!tncPath ? disableTncButton : ''}
          href={tncPath}
          target="_blank"
          rel="noopener noreferrer"
        >
          <BackgroundImageWrapper
            className={Icon(rtl)}
            imgsrc={`/images/${r.iconName}_icon.png`}
          />
          {t(r.textKey)}
        </a>
      ) : (
        <NavLink
          to={{
            pathname: r.url,
            search: location.search,
            state: { returnTo: location.pathname, menu: false },
          }}
          replace
        >
          <BackgroundImageWrapper
            className={Icon(rtl)}
            imgsrc={`/images/${r.iconName}_icon.png`}
          />
          {t(r.textKey)}
        </NavLink>
      )
    return (
      <MenuItem key={r.url} theme={theme}>
        <Link />
      </MenuItem>
    )
  }, routes)
}

const getNewSearch = (l, location) => {
  const search = qs.parse(location.search)
  const paramObj = R.omit(['lang'], search)
  return !R.isEmpty(paramObj)
    ? `?lang=${l}&${qs.stringify(paramObj)}`
    : `?lang=${l}`
}

const changeLang = (l, location, history) => evt => {
  evt.preventDefault()
  const newSearch = getNewSearch(l, location)
  history.replace(`${location.pathname}${newSearch}`)
}

const LangLinks = ({ languages, location, t, rtl, theme, lang, history }) => {
  return R.map(
    l =>
      l.lang !== lang ? (
        <MenuItem key={l.lang} theme={theme}>
          <NavLink to="#" onClick={changeLang(l.lang, location, history)}>
            <BackgroundImageWrapper
              className={Icon(rtl)}
              imgsrc={`/images/globe_icon.png`}
            />
            {t(`lang.${l.lang}`)}
          </NavLink>
        </MenuItem>
      ) : null,
    languages
  )
}

const touchMoveHandler = (setMoveX, container, rtl) => e => {
  const touchList = e.changedTouches

  for (let i = 0; i < touchList.length; i++) {
    const clientX = touchList[i].clientX
    const containerWidth = container ? container.offsetWidth : 0
    const containerOffsetWidth = window.outerWidth - containerWidth
    const moveLeft = clientX <= containerWidth ? clientX - containerWidth : '0'
    const moveRight =
      clientX + containerWidth >= containerWidth
        ? clientX - containerOffsetWidth
        : '0'
    const moveX = rtl ? moveRight : moveLeft

    setMoveX(moveX)
  }
}

const touchEndHandler = (
  history,
  location,
  moveX,
  setMoveX,
  container
) => e => {
  const clientX = moveX
  const containerWidth = container ? container.offsetWidth : 0
  const close = Math.abs(clientX) > containerWidth / 4

  if (close) {
    return history.replace({
      pathname: location.pathname,
      search: location.search,
      state: { menu: false },
    })
  } else {
    setMoveX(0)
  }
}

const MobileMenu = ({ history }) => {
  const {
    i18n: { t, rtl, lang },
  } = useContext(I18nContext)
  const [moveX, setMoveX] = useState(0)
  const [container, setContainer] = useState(undefined)
  const {
    theme,
    meta,
    links: { menulinks, footerlinks },
  } = useContext(ConfigurationContext)
  const { auth } = useContext(AuthenticationContext)
  const { location } = useContext(__RouterContext)
  const { user, onLogout } = auth
  const animation = rtl ? drawerRight : drawerLeft
  const {
    languages,
    social: socialMedia,
    logoutEnabled,
    shareLinkButton,
  } = meta
  const socialMediaImage = item =>
    item.mobileImage ? item.mobileImage : item.backgroundImage
  const { isMobile } = useMobileQuery()
  const tncPath = useExternalTnc()
  const clipURL = `${window.location.origin}/home?utm_source=friend`

  const handleShareAction = () => {
    if (navigator.share) {
      navigator
        .share({
          url: clipURL,
        })
        .then()
        .catch(error => console.error('Error sharing', error))
    }
  }

  return (
    <Spring from={animation['atEnter']} to={animation['atActive']}>
      {props => (
        <div style={props}>
          <Overlay key="overlay" theme={theme} rtl={rtl} />
          <Container
            theme={theme}
            innerRef={el => setContainer(el)}
            key="drawer"
            moveX={moveX}
            rtl={rtl}
            onTouchMove={touchMoveHandler(setMoveX, container, rtl)}
            onTouchEnd={touchEndHandler(
              history,
              location,
              moveX,
              setMoveX,
              container
            )}
          >
            <MenuLinks
              user={user}
              routes={menulinks}
              theme={theme}
              location={location}
              t={t}
              rtl={rtl}
            />
            {menulinks.length > 0 ? <Separator theme={theme} /> : null}
            <FooterLinks
              routes={footerlinks}
              theme={theme}
              location={location}
              t={t}
              rtl={rtl}
              tncPath={tncPath}
            />
            {languages.length > 1 || (user.token && logoutEnabled) ? (
              <Separator theme={theme} />
            ) : null}
            <LangLinks
              languages={languages}
              location={location}
              theme={theme}
              lang={lang}
              rtl={rtl}
              t={t}
              history={history}
            />
            {user.token && logoutEnabled ? (
              <LogoutContainer theme={theme} onClick={onLogout}>
                <BackgroundImageWrapper
                  className={Icon(rtl)}
                  imgsrc={`/images/logout_icon.png`}
                />
                <span>{t('my.account.option.2')}</span>
              </LogoutContainer>
            ) : null}
            {socialMedia.length > 0 ||
            languages.length > 1 ||
            shareLinkButton ? (
              <Separator theme={theme} />
            ) : null}
            {socialMedia || shareLinkButton ? (
              <SocialMediaContainer
                rtl={rtl}
                mobile={isMobile}
                className={socialMenu}
              >
                {shareLinkButton ? (
                  <div
                    className={shareLinkBtn(theme)}
                    onClick={handleShareAction}
                  ></div>
                ) : null}
                {socialMedia.map((item, idx) => (
                  <SocialMediaLink
                    target="_blank"
                    rtl={rtl}
                    key={idx}
                    href={item.link}
                    className={socialClass(socialMediaImage(item))}
                  />
                ))}
              </SocialMediaContainer>
            ) : null}
          </Container>
        </div>
      )}
    </Spring>
  )
}

export default withRouter(MobileMenu)
