import React, { useContext, useState, useEffect, createRef } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import styled, { css } from 'react-emotion'
import { themeProp } from 'utils/theming'
import { I18nContext } from 'containers/I18nProvider'
import { ConfigurationContext } from 'containers/ConfigurationProvider'

const SelectContainer = styled('div')`
  position: relative;
`

const DefaultOption = styled('div')`
  position: relative;
  padding: 0 20px 0 0;
  background-color: transparent;
  color: ${({ theme }) => themeProp(['usermenu', 'color'], theme)};
  cursor: pointer;

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-color: ${({ theme, menuDropped }) =>
      !menuDropped
        ? `${themeProp(
            ['usermenu', 'color'],
            theme
          )} transparent transparent transparent`
        : `transparent transparent ${themeProp(
            ['usermenu', 'color'],
            theme
          )} transparent`};
  }
`

const OptionContainer = styled('div')`
  position: absolute;
  width: calc(100% + 125px);
  margin: 10px 0 0;
  top: 100%;
  right: ${({ rtl }) => (rtl ? 'unset' : '0')};
  left: ${({ rtl }) => (rtl ? '0' : 'unset')};
  padding: 10px 15px;
  color: black;
  background-color: white;
  border-radius: 3px;
  cursor: pointer;
  font-size: 15px;
  line-height: 15px;
  z-index: 999;
  border: 1px solid #f0f0f0;
`

const Option = styled('div')`
  display: flex;
  align-items: center;
  margin: 0 0 15px;
  color: ${({ theme }) =>
    themeProp(['usermenu', 'popOverColor'], theme)} !important;
  gap: 10px;

  > a {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &:hover {
    background-color: rgba (161 162 166 0.12);
  }

  &:last-child {
    margin: 0;
  }
`
const Icon = css`
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
`

const onClickOutside = (domRef, setMenuDropped) => ev => {
  const dr = domRef.current
  const evTarget = ev.target
  const clickedOut = dr && !dr.contains(evTarget) && dr !== evTarget
  if (clickedOut) {
    setMenuDropped(false)
  }
}

const AccountToggle = ({ location, theme, auth }) => {
  const {
    i18n: { rtl, t },
  } = useContext(I18nContext)
  const { meta } = useContext(ConfigurationContext)
  const [menuDropped, setMenuDropped] = useState(false)
  const domRef = createRef()
  const { onLogout, user: { enableContent } = {} } = auth
  const { logoutEnabled } = meta

  useEffect(() => {
    const clickOutside = onClickOutside(domRef, setMenuDropped)
    document.addEventListener('click', clickOutside, false)
    return () => document.removeEventListener('click', clickOutside, false)
  }, [domRef])

  return (
    <SelectContainer innerRef={domRef} theme={theme}>
      <DefaultOption
        theme={theme}
        menuDropped={menuDropped}
        onClick={_ => setMenuDropped(!menuDropped)}
      >
        {t('my.account.default')}
      </DefaultOption>
      {menuDropped && (
        <OptionContainer theme={theme} rtl={rtl}>
          <Option theme={theme}>
            <NavLink to={{ pathname: '/account', search: location.search }}>
              <BackgroundImageWrapper
                className={Icon}
                imgsrc={`/images/user_icon.svg`}
              />
              <span>{t('my.account.option.1')}</span>
            </NavLink>
          </Option>

          {enableContent ? (
            <Option theme={theme}>
              <NavLink
                to={{ pathname: '/daily-content', search: location.search }}
              >
                <BackgroundImageWrapper
                  className={Icon}
                  imgsrc={`/images/daily_content_icon.svg`}
                />
                <span>{t('my.account.dailyContent')}</span>
              </NavLink>
            </Option>
          ) : null}
          {logoutEnabled ? (
            <Option theme={theme} onClick={onLogout}>
              <BackgroundImageWrapper
                className={Icon}
                imgsrc={`/images/logout_icon.svg`}
              />
              <span>{t('my.account.option.2')}</span>
            </Option>
          ) : null}
        </OptionContainer>
      )}
    </SelectContainer>
  )
}

export default withRouter(AccountToggle)
