import * as R from 'ramda'
import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import Loader from 'components/Loader'
import AnswerSection from './AnswerSection'
import useMobileQuery from 'containers/MobileQuery'
import { css, cx } from 'react-emotion'
import { Spring } from 'react-spring'
import { I18nContext } from 'containers/I18nProvider'
import { ConfigurationContext } from 'containers/ConfigurationProvider'

const theme = {
  azercell: css`
    --backdrop-background-shadow: initial;
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #fff;
    --question-header-background-color: #b084fc;
    --question-body-color: #3a3a3a;
  `,
  beeline: css`
    --backdrop-background-shadow: initial;
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #fff;
    --question-header-background-color: #000;
    --question-body-color: #3a3a3a;
  `,
  celcinwin: css`
    --backdrop-background-shadow: initial;
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #fff;
    --question-header-background-color: #000;
    --question-body-color: #3a3a3a;
  `,
  djezzy: css`
    --backdrop-background-shadow: initial;
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #fff;
    --question-header-background-color: #015bb9;
    --question-body-color: #3a3a3a;
  `,
  flashwin: css`
    --backdrop-background-shadow: initial;
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #fff;
    --question-header-background-color: #ffc632;
    --question-body-color: #3a3a3a;
  `,
  indosat: css`
    --backdrop-background-shadow: initial;
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #fff;
    --question-header-background-color: #f7941d;
    --question-body-color: #3a3a3a;
  `,
  libyana: css`
    --backdrop-background-shadow: initial;
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #fff;
    --question-header-background-color: rgb(123, 56, 128);
    --question-body-color: #3a3a3a;
  `,
  lifecell: css`
    --backdrop-background-shadow: initial;
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #fff;
    --question-header-background-color: #f9b011;
    --question-body-color: #3a3a3a;
  `,
  mtnNga: css`
    --backdrop-background-shadow: initial;
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #fff;
    --question-header-background-color: rgb(15, 105, 135);
    --question-body-color: #3a3a3a;
  `,
  mtnZaf: css`
    --backdrop-background-shadow: initial;
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #fff;
    --question-header-background-color: rgb(15, 105, 135);
    --question-body-color: #3a3a3a;
  `,
  syriatel: css`
    --backdrop-background-shadow: initial;
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #fff;
    --question-header-background-color: #dc0000;
    --question-body-color: #3a3a3a;
  `,
  ucell: css`
    --backdrop-background-shadow: initial;
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #fff;
    --question-header-background-color: #3d0e57;
    --question-body-color: #3a3a3a;
  `,
  mtnSAMusic: css`
    --backdrop-background-shadow: initial;
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #fff;
    --question-header-background-color: #ffcb06;
    --question-body-color: #3a3a3a;
  `,
  stcbh: css`
    --backdrop-background-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #fff;
    --question-header-background-color: #ff375e;
    --question-body-color: #4f008c;
  `,
  gloNga: css`
    --backdrop-background-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
    --backdrop-background-color: linear-gradient(
      to right,
      #4bb44e,
      #265a27 103%
    );
    --main-background-color: #fff;
    --question-header-color: #000;
    --question-header-background-color: #f1f1f1;
    --question-body-color: #000;
  `,
  kcell: css`
    --backdrop-background-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
    --backdrop-background-color: rgba(255, 255, 255, 0.7);
    --main-background-color: #fff;
    --question-header-color: #652d86;
    --question-header-background-color: #f1f1f1;
    --question-body-color: #4f008c;
  `,
}

function getHeightByAnswersNum(numOfAnswers = 0) {
  switch (numOfAnswers) {
    case 2:
      return '180px'
    case 3:
      return '155px'
    case 4:
      return '105px'
    case 5:
      return '80px'
    default:
      return '80px'
  }
}

export const styles = {
  gameSection: css`
    width: calc(100vw - 54px);
    max-width: 580px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: -20px -12px;
    > div {
      max-width: 580px;
      height: inherit;
      width: inherit;
      background-color: var(--backdrop-background-color);
      box-shadow: var(--backdrop-background-shadow);
      border-radius: 8px;
      position: absolute;
      z-index: 1;
      margin-top: -30px;
      transform: scale(0.92);
      > div {
        height: 100%;
        width: 100%;
        background-color: var(--backdrop-background-color);
        border-radius: 8px;
        position: absolute;
        z-index: 1;
        margin-top: 10px;
        transform: scale(1.02);
        > div {
          width: 100%;
          height: calc(100% - 4px);
          position: absolute;
          overflow: hidden;
          border-radius: 8px;
          margin-top: 14px;
          background-color: var(--main-background-color);
        }
      }
    }
  `,
  questionHeader: css`
    width: 65%;
    padding: 10px;
    margin: 15px auto;
    text-align: center;
    font-size: 13px;
    font-weight: normal;
    background-color: var(--question-header-background-color);
    color: var(--question-header-color);
    border-radius: 0 0 10px 10px;
  `,
  questionBody: (rtl, isMobile, numOfAnswers) => css`
    margin: ${isMobile ? '15px 45px' : '60px 80px'};
    max-height: ${isMobile ? getHeightByAnswersNum(numOfAnswers) : 'unset'};
    overflow: ${isMobile ? 'hidden' : 'auto'};
    font-size: ${isMobile ? '14px' : '18px'};
    direction: ${rtl ? 'rtl' : 'ltr'};
    color: var(--question-body-color);
  `,
}

const _gameStatus = data =>
  R.compose(
    R.unless(R.isNil, R.toLower),
    R.pathOr(null, ['game', 'gameStatus'])
  )(data)

const _question = data =>
  R.pathOr(null, ['game', 'currentStep', 'question'], data)

const _qtype = question => R.propOr(null, 'questionType', question)

const _qBody = (question, lang) =>
  R.pathOr(null, ['body', 'strings', lang], question)
const _answers = question => R.propOr([], 'answers', question)

const Game = ({
  gameData,
  animation,
  tick,
  location,
  user,
  actions: { answer: onNext },
}) => {
  const {
    i18n: { t, rtl, lang },
  } = useContext(I18nContext)
  const { meta } = useContext(ConfigurationContext)
  const { isMobile } = useMobileQuery()
  const status = _gameStatus(gameData)
  const inProgress = status === 'in_progress'
  const question = _question(gameData)
  const key = R.propOr(null, 'id', question)
  const qtype = _qtype(question)
  const qBody = _qBody(question, lang)
  const answers = _answers(question)
  const appliedTheme = theme[meta.installation]
  const numOfAnswers = answers.length

  if (!status) {
    return (
      <div className={cx(appliedTheme, styles.gameSection)}>
        {gameData.loading ? <Loader /> : null}
      </div>
    )
  }

  return (
    <div className={cx(appliedTheme, styles.gameSection)}>
      <div>
        <div>
          <Spring
            key={key}
            from={animation['atEnter']}
            to={animation['atActive']}
          >
            {props =>
              inProgress ? (
                <div style={props}>
                  <h2 className={styles.questionHeader}>
                    {t(qtype).toUpperCase()}
                  </h2>
                  {isMobile ? (
                    <React.Fragment>
                      <div
                        className={styles.questionBody(
                          rtl,
                          isMobile,
                          numOfAnswers
                        )}
                      >
                        {qBody}
                      </div>
                      <AnswerSection
                        user={user}
                        meta={meta}
                        qtype={qtype}
                        answers={answers}
                        t={t}
                        theme={theme}
                        onNext={onNext}
                        tick={tick}
                        lang={lang}
                        rtl={rtl}
                        mobile
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div
                        className={styles.questionBody(
                          rtl,
                          isMobile,
                          numOfAnswers
                        )}
                      >
                        {qBody}
                      </div>
                      <AnswerSection
                        user={user}
                        meta={meta}
                        qtype={qtype}
                        answers={answers}
                        t={t}
                        theme={theme}
                        onNext={onNext}
                        tick={tick}
                        lang={lang}
                        rtl={rtl}
                      />
                    </React.Fragment>
                  )}
                </div>
              ) : (
                <Redirect key={status} to={`/result${location.search}`} />
              )
            }
          </Spring>
        </div>
      </div>
    </div>
  )
}

export default Game
