import React, { useContext } from 'react'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { Redirect } from 'react-router-dom'

const GameIntro = ({ location }) => {
  const {
    meta: { gameCountdown, gamePrizes },
  } = useContext(ConfigurationContext)

  if (!!gamePrizes)
    return (
      <Redirect to={{ pathname: '/game-prize', search: location.search }} />
    )
  if (!!gameCountdown)
    return <Redirect to={{ pathname: '/pregame', search: location.search }} />

  return <Redirect to="/game" />
}

export default GameIntro
