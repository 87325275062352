import * as R from 'ramda'
import React, { Fragment, useContext, useState, useEffect } from 'react'
import Button from 'components/Button'
import Loader from 'components/Loader'
import DrawSlider from 'components/DrawSlider'
import styled, { css, cx } from 'react-emotion'
import useInterval from 'containers/IntervalProvider'
import * as Sound from 'react-howler'
import { themeProp } from 'utils/theming'
import { Link, withRouter } from 'react-router-dom'
import { backHome } from 'components/Result/layouts/Card'

import path from 'ramda/src/path'
import { I18nContext } from 'containers/I18nProvider'
import { useDataLayerEvents } from 'containers/dataLayerEvents'

const SLOT_HEIGHTS = {
  normal: mobile => (mobile ? 30 : 60),
  active: mobile => (mobile ? 50 : 80),
}

const ResultWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${({ mobile }) => (mobile ? ' 0 15px 15px' : '0 ')};
  box-sizing: border-box;
`
const ButtonContainer = styled('div')`
  width: ${({ mobile }) => (mobile ? '100%' : '235px')};
  margin: ${({ mobile }) => (mobile ? '0' : '0 0 20px')};
`
const homeButtonClass = css`
  color: white;
`
const cantBuyClass = theme => css`
  margin: 0 0 30px;
  color: ${themeProp(['content', 'scoreColor'], theme)};
`

const getInitialSlotPosition = (mobile, questions) => {
  const normal = SLOT_HEIGHTS.normal(mobile)
  const active = SLOT_HEIGHTS.active(mobile)
  return (questions - 2) * normal + active * 3
}

const getFinalSlotPosition = (mobile, correct) => {
  const normal = SLOT_HEIGHTS.normal(mobile)
  const active = SLOT_HEIGHTS.active(mobile)
  const correction = mobile ? 5 : -20
  return (correct + 2) * normal + active + correction
}

const cls = {
  won: (mobile, theme) => css`
    font-size: ${mobile ? 16 : 24}px;
    margin: 0;
    color: ${R.path(['page', 'announcementColor'], theme)};
  `,
  pointsContainer: (mobile, rtl) => css`
    direction: ${rtl ? 'rtl' : 'ltr'};
    width: ${mobile ? 100 : 50}%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  `,
  slotMachine: mobile => css`
    position: relative;
    height: ${mobile ? 100 : 200}px;
    overflow: hidden;
    width: 100%;
  `,
  slots: (mobile, questions, correct, start) => css`
    position: absolute;
    top: -${getInitialSlotPosition(mobile, questions)}px;
    left: 0;
    width: 100%;
    transform: translateY(
      ${start ? getFinalSlotPosition(mobile, correct) : 0}px
    );
    transition: all 0.5s linear;
  `,
  pointsRow: (mobile, theme) => css`
    opacity: 0.5;
    width: 100%;
    height: ${SLOT_HEIGHTS.normal(mobile)}px;
    font-size: ${mobile ? 14 : 28}px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    &.active {
      font-size: ${mobile ? 20 : 38}px;
      height: ${SLOT_HEIGHTS.active(mobile)}px;
      opacity: 1;
    }
    > span {
      width: 40%;
    }
    > div {
      position: relative;
      > img {
        height: ${SLOT_HEIGHTS.normal(mobile) - 20}px;
        margin: 6px 0;
      }
      > div {
        color: ${R.path(['totalPoints', 'pointsColor'], theme)};
        position: absolute;
        top: ${SLOT_HEIGHTS.active(mobile) - 5}px;
        font-size: ${mobile ? 14 : 20}px;
        white-space: nowrap;
      }
    }
    &.active {
      div > img {
        height: ${SLOT_HEIGHTS.active(mobile)}px;
      }
    }
  `,
  totalPointsRow: (theme, mobile) => css`
    width: calc(100% + 30px);
    margin: 8px 0;
    font-size: ${mobile ? 24 : 34}px;
    background-color: ${R.path(['totalPoints', 'background'], theme)};
    color: ${R.path(['totalPoints', 'color'], theme)};
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
      margin: 0 4px;
    }
    > img {
      height: ${mobile ? 24 : 34}px;
    }
  `,
  totalPointsText: theme => css`
    color: ${R.path(['totalPoints', 'textColor'], theme)};
  `,
  totalPointsValue: theme => css`
    color: ${R.path(['totalPoints', 'pointsColor'], theme)};
  `,
  wonRow: mobile => css`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    font-size: ${mobile ? 16 : 24}px;
    font-weight: 600;
    > div {
      width: 33%;
      white-space: nowrap;
      > img {
        width: 100%;
      }
    }
  `,
  wonBanner: mobile => css`
    height: ${mobile ? 50 : 100}px;
    > img {
      height: 100%;
    }
  `,
  guaranteed: (mobile, theme, rtl) => css`
    direction: ${rtl ? 'rtl' : 'ltr'};
    font-size: ${mobile ? '14px' : '20px'};
    color: ${R.path(['totalPoints', 'pointsColor'], theme)};
    &:before {
      content: '+';
    }
  `,
  staticImageContainer: mobile => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: -webkit-fill-available;
    margin: ${mobile ? '15px 0 0' : '25px 0 50px'};
  `,

  staticImage: css`
    width: 100%;
    max-width: 390px;
    height: fit-content;
  `,
}

const AnswerPoints = ({
  active = false,
  assets,
  answers,
  questions,
  mobile,
  theme,
  guaranteed,
  lang,
  rtl,
  t,
}) => (
  <div className={cx([cls.pointsRow(mobile, theme), active ? 'active' : ''])}>
    <span>
      {answers} / {questions}
    </span>
    <div>
      <img
        alt=""
        src={active ? assets['image'] : 'images/step.png'}
        onError={ev =>
          (ev.target.src =
            'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
        }
      />
    </div>
    <span>
      <>
        {t(assets['text'])}
        {active && guaranteed?.result === 'WON' ? (
          <div className={cls.guaranteed(mobile, theme, rtl)}>
            {guaranteed?.description?.strings[lang]}
          </div>
        ) : null}
      </>
    </span>
  </div>
)

const PrizeWon = ({
  theme,
  questions,
  correct,
  prizes,
  mobile,
  status,
  lang,
  rtl,
  t,
}) => {
  const [startSlot, setStartSlot] = useState(false)
  const [setIntervalTime] = useInterval(() => setStartSlot(_ss => true))
  const allPoints = R.propOr([], ['points'], theme)
  const hasTimeout = status === 'TIMEOUT'
  const hasWon = status === 'WON'
  const hasWonPrize = prizes?.regular?.result === 'WON'

  useEffect(() => {
    !startSlot && setIntervalTime(100)
    return () => setIntervalTime(null)
  }, [setIntervalTime, startSlot])

  return (
    <div className={cls.pointsContainer(mobile, rtl)}>
      {hasWonPrize || hasWon ? (
        <div className={cls.won(mobile, theme)}>{t('result.you.won')}</div>
      ) : null}
      {hasTimeout ? (
        <div className={cls.won(mobile, theme)}>
          {t('result.timeout.motivation')}
        </div>
      ) : null}
      {!hasWon ? (
        <div className={cls.slotMachine(mobile)}>
          <div className={cls.slots(mobile, questions, correct, startSlot)}>
            {R.reverse(allPoints).map((point, idx) => (
              <AnswerPoints
                key={idx}
                assets={point}
                active={idx === questions - correct}
                answers={questions - idx}
                questions={questions}
                mobile={mobile}
                theme={theme}
                guaranteed={prizes?.guaranteed}
                lang={lang}
                t={t}
              />
            ))}
          </div>
        </div>
      ) : (
        <Fragment>
          <div className={cls.wonRow(mobile)}>
            <div>
              {questions} / {questions}
            </div>
            <div>
              <img
                alt=""
                src={allPoints[questions]?.bonusImage}
                onError={ev =>
                  (ev.target.src =
                    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
                }
              />
            </div>
            <div>
              {t(allPoints[questions]?.text)}
              {prizes?.guaranteed?.result === 'WON' ? (
                <div className={cls.guaranteed(mobile, theme, rtl)}>
                  {prizes?.guaranteed?.description?.strings[lang]}
                </div>
              ) : null}
            </div>
          </div>
          <div className={cls.wonBanner(mobile)}>
            <img
              alt=""
              src={allPoints[questions]?.image}
              onError={ev =>
                (ev.target.src =
                  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
              }
            />
          </div>
        </Fragment>
      )}
      {hasWonPrize ? (
        <div style={{ width: '100%', height: '150px' }}>
          <img
            height="100%"
            src={prizes?.regular?.imagePath}
            alt="prize"
            onError={ev =>
              (ev.target.src =
                'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
            }
          />
        </div>
      ) : null}
    </div>
  )
}

const PrizeAndDraws = ({
  status,
  theme,
  user = {},
  onChange,
  onPlayAgain,
  onResubscribe,
  resultData = {},
  history,
  location,
  mobile,
  questions,
  answers,
}) => {
  const {
    i18n: { t, rtl, lang },
  } = useContext(I18nContext)
  const { canBuy, userStatus, credits: crds, username, id } = user
  const {
    result: { numOfErrors, currentStep, gameStatus, selectedPrizes } = {},
  } = resultData
  let prizes = {}
  if (selectedPrizes !== undefined && selectedPrizes.length > 0) {
    const g = selectedPrizes.find(p => p.prize.category === 'GUARANTEED')
    const guaranteed =
      g !== undefined ? { ...g.prize, result: g.result } : undefined
    const r = selectedPrizes.find(p => p.prize.category === 'REGULAR')
    const regular =
      r !== undefined ? { ...r.prize, result: r.result } : undefined
    prizes = { regular, guaranteed }
  }

  const position = currentStep ? currentStep.position : 0
  const credits = crds ? crds.length : undefined
  const canPlay = credits && credits > 0
  const played =
    status === 'timeout' || status === 'abandoned'
      ? parseInt(position, 10) - 1
      : 10
  const correctOnes = played - parseInt(numOfErrors, 10)
  const hasWon = status === 'won'

  const timeUp = status === 'timeout' ? 'yes' : 'no'
  const { dataLayerPush } = useDataLayerEvents()
  const userId = !!id ? id : ''
  const userType =
    user?.userStatus === 'SUBSCRIBED' ? 'subscribed' : 'anonymous'
  const userTypeAdvanced = !!username
    ? user?.userStatus === 'SUBSCRIBED'
      ? 'subscribed'
      : 'registered'
    : 'anonymous'
  const getPathName = window.location.pathname.split('&')
  const pathName = getPathName[0]
  const rewardedPoints = correctOnes * 20

  const dataLayerObject = {
    event: 'completeAnswers',
    screenName: window.location.pathname,
    UILanguage: lang,
    selectedMode: 'score',
    correctAnswers: correctOnes,
    rewardsPoints: rewardedPoints,
    timesUp: timeUp,
    userId: userId,
    userType: userTypeAdvanced,
  }
  const dataLayerObject2 = {
    event: 'buyCredit',
    screenName: pathName,
    UILanguage: lang,
    userId: userId,
    userType: userType,
  }
  const dataLayerObject3 = {
    event: 'gamePlay',
    screenName: pathName,
    selectedMode: 'score',
    playType: 'repeated',
    UILanguage: lang,
    userId: userId,
    userType: userTypeAdvanced,
  }
  const dataLayerObject4 = {
    event: 'SubscriptionStart_click',
    screenName: pathName,
    UILanguage: lang,
    userId: userId,
    userType: userType,
  }
  useEffect(() => {
    if (!isNaN(correctOnes)) dataLayerPush(dataLayerObject)
  }, [user])
  return (
    <ResultWrapper mobile={mobile}>
      {!status ? (
        path(['loading'], resultData) ? (
          <Loader />
        ) : null
      ) : (
        <Fragment>
          <PrizeWon
            status={gameStatus}
            theme={theme}
            questions={parseInt(questions || 10, 10)}
            correct={correctOnes}
            prizes={prizes}
            mobile={mobile}
            lang={lang}
            rtl={rtl}
            t={t}
          />
          {hasWon && !!theme?.staticImage ? (
            <div className={cls.staticImageContainer(mobile)}>
              <img
                className={cls.staticImage}
                alt="winner prize"
                src={`${theme?.staticImage}`}
                onError={ev =>
                  (ev.target.src =
                    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
                }
              />
            </div>
          ) : (
            <DrawSlider theme={theme} rtl={rtl} lang={lang} t={t} />
          )}
          <ButtonContainer mobile={mobile} theme={theme}>
            {canPlay ? (
              <div onClick={() => dataLayerPush(dataLayerObject3)}>
                <Button
                  onClick={onPlayAgain(history, location)}
                  text={t('play.again')}
                  theme={theme}
                />
              </div>
            ) : canBuy ? (
              <div onClick={() => dataLayerPush(dataLayerObject2)}>
                <Button
                  to={{ pathname: '/credits', search: location.search }}
                  text={t('buy.button')}
                  theme={theme}
                />
              </div>
            ) : userStatus === 'UNSUBSCRIBED' || userStatus === 'REGISTERED' ? (
              <div onClick={() => dataLayerPush(dataLayerObject4)}>
                <Button
                  text={t('login.button.start')}
                  theme={theme}
                  onClick={onResubscribe(user, onChange, history, location)}
                />
              </div>
            ) : (
              <div className={cantBuyClass(theme)}>
                {t('result.cantbuy.subheader.a')}
              </div>
            )}
          </ButtonContainer>
          <Link
            className={(backHome, homeButtonClass)}
            to={{ pathname: '/home', search: location.search }}
          >
            {t('result.back.home')}
          </Link>
          {status === 'won' ? (
            <Sound
              src={themeProp(['won', 'sound'], theme)}
              playing={status === 'won'}
            />
          ) : null}
        </Fragment>
      )}
    </ResultWrapper>
  )
}

export default withRouter(PrizeAndDraws)
