import React, { useContext } from 'react'
import Content from 'components/Content'
import LandingContent from 'components/LandingContent'
import { I18nContext } from 'containers/I18nProvider'
import Pixel from 'containers/Pixel'

const Home = () => {
  const {
    i18n: { t },
  } = useContext(I18nContext)

  return (
    <Content>
      <LandingContent t={t} />
      <Pixel />
    </Content>
  )
}

export default Home
