import * as Sound from 'react-howler'
import React, { useContext } from 'react'
import Loader from 'components/Loader'
import Button from 'components/Button'
import Conditional from 'containers/Conditional'
import styled, { css } from 'react-emotion'
import { themeProp } from 'utils/theming'
import { Link, withRouter } from 'react-router-dom'
import { I18nContext } from 'containers/I18nProvider'
import FbShare from 'components/FbShare'

const ResultWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${({ mobile }) => (mobile ? '15px' : '0')};
  box-sizing: border-box;
`

const ResultSection = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 580px;
  height: 100%;
  margin: ${({ mobile }) => (mobile ? ' 0' : '20px 0')};

  > div {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: ${({ theme }) =>
      themeProp(['content', 'backgroundColor'], theme)};
  }
`

const ResultContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
`

const FinalSmallHeader = styled('h4')`
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  margin: 0 10px 10px;
  font-weight: bold;
  color: ${({ theme, status }) =>
    themeProp([status, 'smallHeaderColor'], theme)};
`

const FinalHeader = styled('h2')`
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  margin: 10px;
  font-weight: normal;
  color: ${({ theme, status }) => themeProp([status, 'headerColor'], theme)};
`

const CantBuyHeader = styled('p')`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  font-weight: bold;
  margin: 10px;
  color: ${({ theme, status }) =>
    themeProp([status, 'smallHeaderColor'], theme)};
`

const imageContainerClass = mobile => css`
  margin: ${mobile ? '20px auto 40px' : '30px auto 50px'};
  max-width: 100%;
`

const FinalSubHeader = styled('p')`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin: 15px 10px;
  color: ${({ theme, status }) => themeProp([status, 'subheaderColor'], theme)};
`

const buttonContainer = mobile => css`
  width: ${mobile ? '100%' : '75%'};
  margin: auto auto 0;
`

export const backHome = css`
  text-decoration: underline;
  color: rgb(43, 30, 135) !important;
  padding: 12px 0 0;
`
const fbStylingClass = css`
  padding: 0;
  border: none;
  margin: 12px auto 0;
  &:hover {
    border: none;
  }
`

const Card = ({
  status,
  theme,
  user = {},
  onChange,
  onPlayAgain,
  onResubscribe,
  resultData = {},
  history,
  location,
  mobile,
}) => {
  const {
    i18n: { t },
  } = useContext(I18nContext)
  const { canBuy, userStatus, credits: crds, points } = user
  const { result: { numOfErrors, currentStep } = {} } = resultData
  const position = currentStep ? currentStep.position : 0
  const credits = crds ? crds.length : undefined
  const canPlay = credits && credits > 0
  const played =
    status === 'timeout' || status === 'abandoned'
      ? parseInt(position, 10) - 1
      : 10
  const correctOnes = played - parseInt(numOfErrors, 10)

  return (
    <ResultWrapper mobile={mobile}>
      {!status ? (
        <ResultSection theme={theme} mobile={mobile}>
          {resultData.loading ? <Loader /> : null}
        </ResultSection>
      ) : (
        <ResultSection theme={theme} mobile={mobile}>
          <div>
            <ResultContainer>
              <FinalSmallHeader theme={theme} status={status}>
                {t(`result.${status}.smallheader`)}
              </FinalSmallHeader>
              <FinalHeader theme={theme} status={status}>
                {t(`result.${status}.header`).toUpperCase()}
              </FinalHeader>

              <img
                alt=""
                className={imageContainerClass(mobile)}
                src={themeProp([status, 'backgroundImage'], theme)}
                onError={ev =>
                  (ev.target.src =
                    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
                }
              />
              {status !== 'won' ? (
                <FinalSubHeader theme={theme} status={status}>
                  <span>{t(`result.${status}.subheader.a`)}</span>
                  <span>{correctOnes || 0}</span>
                  <span>{t(`result.${status}.subheader.b`)}</span>
                </FinalSubHeader>
              ) : null}
              {points && status !== 'won' ? (
                <Conditional condition="pointsEnabled">
                  <FinalSmallHeader theme={theme} status={status}>
                    <span>{t('account.pointsearned')} </span>
                    <span>{points}</span>
                    <span>{t('account.pointsmsg')}</span>
                  </FinalSmallHeader>
                </Conditional>
              ) : null}
              {!credits && !canBuy ? (
                <CantBuyHeader theme={theme} status={status}>
                  <div>{t('result.cantbuy.subheader.a')} </div>
                  <div>{t('result.cantbuy.subheader.b')}</div>
                </CantBuyHeader>
              ) : null}

              <div className={buttonContainer(mobile)}>
                {canPlay ? (
                  <Button
                    onClick={onPlayAgain(history, location)}
                    text={t('play.again')}
                    theme={theme}
                  />
                ) : canBuy ? (
                  <Button
                    to={{ pathname: '/credits', search: location.search }}
                    text={t('buy.button')}
                    theme={theme}
                  />
                ) : userStatus === 'UNSUBSCRIBED' ||
                  userStatus === 'REGISTERED' ? (
                  <Button
                    text={t('login.button.start')}
                    theme={theme}
                    onClick={onResubscribe(user, onChange, history, location)}
                  />
                ) : null}
                <Link
                  className={backHome}
                  to={{ pathname: '/home', search: location.search }}
                >
                  {t('result.back.home')}
                </Link>
                <Conditional condition="fbShareEnabled">
                  <FbShare btnStyleClass={fbStylingClass} />
                </Conditional>
              </div>
            </ResultContainer>
          </div>
        </ResultSection>
      )}
      {status === 'won' ? (
        <Sound
          src={themeProp(['won', 'sound'], theme)}
          playing={status === 'won'}
        />
      ) : null}
    </ResultWrapper>
  )
}

export default withRouter(Card)
