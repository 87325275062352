import React from 'react'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import { css } from 'react-emotion'
import { themeProp } from 'utils/theming'
import { HeaderLogo } from 'layouts/index'

const cls = {
  container: (mobile, theme) => css`
    font-family: ${themeProp(['page', 'fontFamily'], theme)};
    position: absolute;
    z-index: 20;
    width: 100vw;
    height: 100dvh;
    overflow: ${mobile ? 'auto' : 'initial'};
    color: ${themeProp(['modal', 'color'], theme)};
    background-color: ${themeProp(['page', 'backgroundColor'], theme)};
    background: url(${themeProp(['page', 'backgroundImage'], theme)});
    background-size: cover;
  `,
  header: (mobile, theme, rtl) => css`
    display: flex;
    flex-direction: ${rtl ? 'row-reverse' : 'row'};
    justify-content: space-between;
    align-items: center;
    min-height: ${mobile ? '56px' : 'unset'};
    max-height: ${mobile ? '103px' : 'unset'};
    padding: ${mobile ? '1px 15px' : '10px 25px'};
    background: ${themeProp(['page', 'backgroundColor'], theme)};
  `,
  contentWrapper: mobile => css`
    display: flex;
    width: 100%;
    height: ${mobile ? '91vh' : '100%'};
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  `,
  content: (mobile, theme) => css`
    position: relative;
    max-width: ${mobile ? '65%' : '260px'};
    padding: ${mobile ? '120px 30px' : '70px'};
    text-align: center;
    border-radius: 10px;
    color: ${themeProp(['content', 'contentColor'], theme)};
    background: ${themeProp(['content', 'contentBackgroundColor'], theme)};
    background-image: ${mobile
      ? `url(${themeProp(['content', 'mobilebackgroundImage'], theme)})`
      : `url(${themeProp(['content', 'backgroundImage'], theme)})`};
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  `,
  title: theme => css`
    position: absolute;
    width: fit-content;
    height: 38px;
    top: -18px;
    left: 50%;
    transform: translate(-50%);
    line-height: 38px;
    font-size: 18px;
    border-radius: 10px;
    padding: 0 45px;
    color: ${themeProp(['content', 'titleColor'], theme)};
    background: ${themeProp(['content', 'titleBackgroundColor'], theme)};
  `,
}

const Default = ({ location, rtl, t, mobile, theme }) => {
  return (
    <BackgroundImageWrapper
      className={cls.container(mobile, theme)}
      imgsrc={themeProp(['modal', 'backgroundImage'], theme)}
    >
      <div className={cls.header(mobile, theme, rtl)}>
        <HeaderLogo
          theme={theme}
          mobile={mobile}
          className={'logo1'}
          to={{ pathname: '/home', search: location.search }}
        />
        <HeaderLogo
          theme={theme}
          mobile={mobile}
          className={'logo2'}
          to={{ pathname: '/home', search: location.search }}
        />
      </div>
      <div className={cls.contentWrapper(mobile)} theme={theme}>
        <div className={cls.content(mobile, theme)} theme={theme}>
          <div className={cls.title(theme)} theme={theme}>
            {t('thankyou.title')}
          </div>
          {t('thankyou.message')}
        </div>
      </div>
    </BackgroundImageWrapper>
  )
}

export default Default
