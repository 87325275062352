import * as R from 'ramda'
import React, { useContext, useEffect, useCallback, useState } from 'react'
import Overlay from 'components/Overlay'
import Button from 'components/Button'
import InputText from 'components/InputText'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import styled, { css } from 'react-emotion'
import { Spring } from 'react-spring'
import { themeProp } from 'utils/theming'
import { handleChange, ResendLink } from 'components/ActionForm'
import { zoomIn as animation } from 'animations'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { AuthenticationContext } from 'containers/AuthProvider'
import { __RouterContext } from 'react-router'
import { I18nContext } from 'containers/I18nProvider'
import { useDataLayerEvents } from 'containers/dataLayerEvents'

const containerClass = (mobile, theme) => css`
  position: absolute;
  z-index: 20;
  width: 100vw;
  height: 100dvh;
  overflow: ${mobile ? 'auto' : 'initial'};
  color: ${themeProp(['modal', 'color'], theme)};
  background: ${themeProp(['modal', 'backgroundColor'], theme)};
  background-size: cover;

  > div:nth-child(1) {
    box-sizing: border-box;
    padding: ${mobile ? '10px' : '0'};
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`

const content = css`
  height: 100%;
  width: 100%;
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
`

const Header = styled('h5')`
  margin: ${({ mobile }) => (mobile ? '30px 0' : '40px 0')};
  font-size: ${({ mobile }) => (mobile ? '16px' : '18px')};
  font-weight: normal;
`

const ButtonContainer = styled('div')`
  margin: 20px 0;
  width: 210px;
`

const onClose = (user, onChange) => ev => {
  ev && ev.preventDefault()
  onChange(R.assoc('pinPrompted', true, user))
}

const PinOverlay = ({ mobile }) => {
  const {
    i18n: { t, rtl },
  } = useContext(I18nContext)
  const { auth } = useContext(AuthenticationContext)
  const { theme, meta } = useContext(ConfigurationContext)
  const { defaultLang } = meta
  const { location } = useContext(__RouterContext)
  const [waitForPin, setWaitForPin] = useState(false)
  const user = auth ? auth.user : {}
  const { phone, token } = user
  const { authenticating } = auth
  const { errors, onAuth, onChange } = auth
  const markPinPrompted = useCallback(() => {
    onChange(R.assoc('pinPrompted', true, user))
  }, [user, onChange])
  const { dataLayerPush } = useDataLayerEvents()
  const preAuthUserId = !!user?.phone ? user?.phone : ''
  const userType =
    user?.userStatus === 'SUBSCRIBED' ? 'subscribed' : 'anonymous'
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const dataLayerObject = {
    event: 'login_OTP_Entry_click',
    screenName: '/enterOtp',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: preAuthUserId,
    userType: userType,
  }
  const handleAuth = (onAuth, options = {}) => ev => {
    ev && ev.preventDefault()
    dataLayerPush(dataLayerObject)
    onAuth(options)
  }
  const pinOnTheWay = useCallback(() => {
    setWaitForPin(true)
    setTimeout(() => setWaitForPin(false), 15000)
  }, [setWaitForPin])

  const handleResend = useCallback(
    evt => {
      evt && evt.preventDefault()
      onAuth({ resend: true })
      pinOnTheWay()
    },
    [onAuth, pinOnTheWay]
  )

  useEffect(() => {
    if (token) {
      markPinPrompted()
    }
  }, [token, markPinPrompted])

  const checkShortCodeLength = user?.shortCode?.length < 4

  return (
    <BackgroundImageWrapper
      className={containerClass(mobile, theme)}
      imgsrc={themeProp(['modal', 'backgroundImage'], theme)}
    >
      <Overlay rtl={rtl} location={location} onClose={onClose(user, onChange)}>
        <Spring from={animation['atEnter']} to={animation['atActive']}>
          {props => (
            <form
              data-e2e="login-form"
              className={content}
              key="content"
              onSubmit={handleAuth(onAuth)}
              style={props}
            >
              <Header mobile={mobile}>
                {t('account.type.verification.code')}
                <span dir="ltr"> {phone}</span>
              </Header>
              <InputText
                theme={theme}
                errors={errors}
                name="shortCode"
                type="number"
                placeholder={t('login.pin.placeholder')}
                value={R.propOr('', 'shortCode', user)}
                onChange={handleChange('shortCode', user, onChange)}
                t={t}
              />
              {waitForPin ? (
                <small
                  className={css`
                    margin: 10px 0 0;
                    cursor: pointer;
                    text-align: center;
                  `}
                >
                  {t('subscription.wait.pin')}
                </small>
              ) : (
                <ResendLink rtl={rtl} onClick={handleResend}>
                  {t('login.resend.pin')}
                </ResendLink>
              )}
              <ButtonContainer>
                <Button
                  disabled={
                    authenticating || !user.shortCode || checkShortCodeLength
                  }
                  text={t('login.button.login')}
                  theme={theme}
                  onClick={handleAuth(onAuth)}
                />
              </ButtonContainer>
            </form>
          )}
        </Spring>
      </Overlay>
    </BackgroundImageWrapper>
  )
}

export default PinOverlay
