import React from 'react'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import { Spring } from 'react-spring'
import { zoomIn as animation } from 'animations'
import { css } from 'react-emotion'
import { themeProp } from 'utils/theming'
import { HeaderLogo } from 'layouts/index'

const cls = {
  container: (mobile, theme) => css`
    font-family: ${themeProp(['page', 'fontFamily'], theme)};
    position: absolute;
    z-index: 20;
    width: 100vw;
    height: 100dvh;
    max-height: -webkit-fill-available;
    overflow: ${mobile ? 'auto' : 'initial'};
    color: ${themeProp(['blockPage', 'color'], theme)};
    background-color: ${themeProp(['blockPage', 'backgroundColor'], theme)};
    background: url(${themeProp(['blockPage', 'backgroundImage'], theme)});
    background-size: cover;
  `,
  header: (mobile, theme, rtl) => css`
    display: flex;
    flex-direction: ${rtl ? 'row-reverse' : 'row'};
    justify-content: space-between;
    align-items: center;
    min-height: ${mobile ? '56px' : 'unset'};
    max-height: ${mobile ? '103px' : 'unset'};
    padding: ${mobile ? '1px 15px' : '10px 25px'};
    background: ${themeProp(['blockPage', 'headerBackground'], theme)};
  `,
  wrapper: mobile => css`
    display: flex;
    width: 100%;
    height: ${mobile ? '91vh' : '100%'};
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  `,
  content: (theme, mobile) => css`
    position: relative;
    max-width: ${mobile ? '65%' : '260px'};
    padding: ${mobile ? '120px 30px' : '70px'};
    text-align: center;
    border-radius: 10px;
    color: ${themeProp(['blockPage', 'contentColor'], theme)};
    background-color: ${themeProp(
      ['blockPage', 'contentBackgroundColor'],
      theme
    )};
    box-shadow: ${themeProp(['blockPage', 'boxShadow'], theme)};
  `,
}

const Default = ({ location, rtl, t, mobile, theme }) => {
  const showHeader = theme?.page?.showHeader
  return (
    <BackgroundImageWrapper
      className={cls.container(mobile, theme)}
      imgsrc={themeProp(['modal', 'backgroundImage'], theme)}
    >
      {showHeader ? (
        <div className={cls.header(mobile, theme, rtl)}>
          <HeaderLogo
            theme={theme}
            mobile={mobile}
            className={'logo1'}
            to={{ pathname: '', search: location.search }}
          />
          <HeaderLogo
            theme={theme}
            mobile={mobile}
            className={'logo2'}
            to={{ pathname: '', search: location.search }}
          />
        </div>
      ) : null}
      <Spring from={animation['atEnter']} to={animation['atActive']}>
        {props => (
          <div className={cls.wrapper(mobile)} key="content" style={props}>
            <div className={cls.content(theme, mobile)}>
              {t('blocking.text')}
            </div>
          </div>
        )}
      </Spring>
    </BackgroundImageWrapper>
  )
}

export default Default
