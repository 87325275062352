import { useContext } from 'react'
import { ConfigurationContext } from 'containers/ConfigurationProvider'

export const useDataLayerEvents = () => {
  const {
    meta: { gtagId },
  } = useContext(ConfigurationContext)

  const dataLayerPush = dataLayerObject =>
    gtagId ? window?.dataLayer?.push({ ...dataLayerObject }) : null
  return { dataLayerPush }
}
