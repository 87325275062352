import * as R from 'ramda'

import React, { useContext, useMemo, useState } from 'react'
import styled, { css } from 'react-emotion'

import { AuthenticationContext } from 'containers/AuthProvider'
import Button from 'components/Button'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import ErrorSection from 'components/ErrorSection'
import { I18nContext } from 'containers/I18nProvider'
import InputText from 'components/InputText'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import ReactPhoneInput from 'react-phone-input-2'
import TextFit from 'containers/TextFit'
import { __RouterContext } from 'react-router'
import { isAuthenticated } from 'lib/auth-utils'
import qs from 'query-string'
import { themeProp } from 'utils/theming'
import { useExternalTnc } from 'containers/ExternalTnc'
import { useDataLayerEvents } from 'containers/dataLayerEvents'
import TermsCheckBox from './TermsCheckBox'
import Conditional from 'containers/Conditional'

const Form = styled('form')`
  width: ${({ mobile }) => (mobile ? '100vw' : '370px')};
  height: ${({ mobile }) => (mobile ? '100dvh' : 'auto')};
  text-align: center;
  font-weight: 300;
  margin-top: ${({ mobile }) => (mobile ? '0' : '0')};
`

const ActionSection = styled('div')`
  display: flex;
  flex-flow: column;
  justify-content: ${({ mobile }) => (mobile ? 'flex-start' : 'space-around')};
  height: 100%;
  width: 100%;
`

const Header = styled('h1')`
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
  font-weight: 300;
  margin: ${({ mobile }) => (mobile ? '0' : '0 0 40px')};
  padding: ${({ mobile }) => (mobile ? '0 15px 5px' : '0')};
  > div:nth-child(1) {
    font-size: ${({ mobile }) => (mobile ? '18px' : '25px')};
    line-height: ${({ mobile }) => (mobile ? '22px' : '25px')};
    margin: ${({ mobile }) => (mobile ? '10px 0 10px' : '0 0 13px')};
    font-weight: 400;
  }
  > div:nth-child(2) {
    font-size: ${({ mobile }) => (mobile ? '15px' : '20px')};
    line-height: ${({ mobile }) => (mobile ? '15px' : '20px')};
    margin: ${({ mobile }) => (mobile ? '0 0 8px' : '0 0 12px')};
  }
  > div:nth-child(3) {
    font-size: ${({ mobile }) => (mobile ? '18px' : '25px')};
    line-height: ${({ mobile }) => (mobile ? '22px' : '25px')};
    font-weight: 400;
  }
`

const SubHeader = styled('h2')`
  font-size: ${({ mobile }) => (mobile ? '12px' : '15px')};
  line-height: 15px;
  font-weight: 300;
  margin: ${({ mobile }) => (mobile ? '0 16px 10px' : '0')};
`

const IO = styled('div')`
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: normal;
  padding: 0;
  color: inherit;
  background-color: inherit;
`

const MobileIO = styled('div')`
  width: 100%;
  z-index: 3;
  position: absolute;
  bottom: 0;
  left: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 15px;
  color: ${({ theme }) => themeProp(['footer', 'colorActionForm'], theme)};
  background-color: ${({ theme }) =>
    themeProp(['footer', 'mobileBackgroundColor'], theme)};
  box-sizing: border-box;
  :focus-within {
    background-color: ${({ theme }) =>
      themeProp(['footer', 'actionForm'], theme)};
  }
`
const PriceNote = styled('div')`
  font-size: 13px;
`

const Note = styled('small')`
  position: relative;
  font-size: 11px;
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
`

const LinkNote = styled(Note)`
  font-size: 15px;
  font-weight: bold;
  color: ${({ theme }) => themeProp(['footer', 'resendLink'], theme)};
  cursor: pointer;
`

export const ResendLink = styled('small')`
  margin: 10px 0 0;
  cursor: pointer;
  text-align: center;
  color: ${({ theme }) => themeProp(['footer', 'backgroundColor'], theme)};
`

const checkBoxContainer = css`
  margin: 5px 10px 15px;
`
const SelectGameWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SelectGameImgContainer = css`
  width: 30px;
  height: 30px;
  padding: 10px;
  margin: 15px 0;
  border-radius: 50%;
  box-shadow: 5px 5px 28px 0 #f88174;
  background-image: linear-gradient(136deg, #d53af5 3%, #ff8f5b 94%);
`

const SelectGameButton = rtl => css`
  width: 100%;
  height: 100%;
  transform: ${rtl ? 'rotate(180deg)' : 'rotate(0deg)'};
`
const SelectGameMessage = css`
  font-size: 12px;
  text-decoration: underline;
`
export const DesktopLogo = styled(NavLink)`
  display: inline-block;
  cursor: ${({ nodetail }) => (!nodetail ? 'pointer' : 'unset')}!important;
  width: ${({ theme }) => {
    const width = themeProp(['page', 'actionformLogoWidth'], theme)
    return width ? width : '100px'
  }};
  height: ${({ theme }) => {
    const height = themeProp(['page', 'actionformLogoHeight'], theme)
    return height ? height : '100px'
  }};
  background: ${({ theme }) =>
    `url(${themeProp(['page', 'actionformLogo'], theme)}) no-repeat`};
  background-size: contain;
`
const DesktopLogoWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 20px;
`
const TncButton = css`
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`

const disableTncButton = css`
  pointer-events: none;
`

const hasPin = user => R.prop('sms', user)
const isActionDisabled = (
  authenticating,
  user,
  shouldHideMsisdn,
  termsCheckEnabledActionForm = false,
  termsStatus,
  displayLogin
) => {
  if (shouldHideMsisdn) return false
  const hasInput = !user.sms ? user.phone : user.shortCode
  if (termsCheckEnabledActionForm && !displayLogin)
    return authenticating || !hasInput || !termsStatus
  return authenticating || !hasInput
}

export const handleAuth = (onAuth, options = {}) => ev => {
  ev && ev.preventDefault()
  onAuth(options)
}

export const handleChange = (prop, user, onChange) => ev =>
  onChange(R.assoc(prop, ev.target.value, user))

const onResubscribe = (user, onChange, onAuth, options = {}) => () => {
  return user.singleStepSubscription || user.externalSub
    ? onAuth(options)
    : onChange({
        ...user,
        userStatus: undefined,
        pinPrompted: false,
        sms: true,
      })
}

const onPhoneChange = (user, onChange) => (val, data) => {
  onChange({ ...user, phone: val, countryCode: data.countryCode })
}

const handleResendLogin = onResendLogin => ev => {
  ev && ev.preventDefault()
  onResendLogin()
}

const getCountryCode = location => {
  const s = qs.parse(location.search)
  const cc = R.prop('country', s)
  return cc
}

const onWantToLogin = setAction => () => {
  setAction('login')
}

const onWantToSubscribe = setAction => () => {
  setAction('start')
}

const LoginAction = ({
  location,
  authenticating,
  user,
  onChange,
  onAuth,
  onResendLogin,
  errors,
  t,
  rtl,
  lang,
  mobile,
  children,
  theme,
  meta,
  tncData,
  returnTo,
  handleTerms,
  termsStatus,
}) => {
  const [action, setAction] = useState('start')
  const {
    countries,
    withoutMsisdn,
    actionFormTncEnabled,
    defaultCountry,
    preferredCountries,
    defaultLang,
    termsCheckEnabledActionForm,
  } = meta

  const { suggestedCountryCode } = user
  const shouldHideMsisdn =
    withoutMsisdn === 'always' ||
    (withoutMsisdn === 'enriched' && !!user.msisdn)
  const Interaction = mobile ? MobileIO : IO
  const phoneDropDisabled =
    !!user.countryCode && R.keys(user.operators).length === 1
  const displayLogin = action === 'login' || hasPin(user)
  const availableCountries = useMemo(() => {
    return R.compose(
      R.map(k => k.toLowerCase()),
      R.keys
    )(user.operators)
  }, [user.operators])
  const handleSuggestedCountryCode = availableCountries.includes(
    suggestedCountryCode?.toLowerCase()
  )
    ? suggestedCountryCode?.toLowerCase()
    : defaultCountry
  const cc =
    getCountryCode(location) ||
    user.countryCode ||
    handleSuggestedCountryCode ||
    defaultCountry
  const logoSrcExists = themeProp(['page', 'actionformLogo'], theme)
  const tncPath = useExternalTnc()
  const { dataLayerPush } = useDataLayerEvents()
  const userId = !!user?.id ? user?.id : ''
  const userType =
    user?.userStatus === 'SUBSCRIBED' ? 'subscribed' : 'anonymous'
  const queryString = window.location.search
  const getPathName = window.location.pathname.split('&')
  const pathName = getPathName[0]

  const urlParams = new URLSearchParams(queryString)
  const dataLayerObject = {
    event: 'loginStart_click',
    screenName: pathName,
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userType,
  }
  const dataLayerObject2 = {
    event: 'SubscriptionStart_click',
    screenName: pathName,
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userType,
  }
  const handleDataLayerLogin = displayLogin ? dataLayerObject : dataLayerObject2

  return (
    <ActionSection mobile={mobile}>
      {!mobile && !!logoSrcExists ? (
        <div className={DesktopLogoWrapper}>
          <DesktopLogo
            theme={theme}
            to={{ pathname: '/home', search: location.search }}
          />
        </div>
      ) : null}
      {mobile ? (
        <Header mobile={mobile} rtl={rtl}>
          <TextFit minSize={12} maxSize={18}>
            {t('login.header.a')}
          </TextFit>
          <TextFit minSize={12} maxSize={18}>
            {t('login.header.b')}
          </TextFit>
          <TextFit minSize={12} maxSize={18}>
            {t('login.header.c')}
          </TextFit>
        </Header>
      ) : (
        <Header mobile={mobile} rtl={rtl}>
          <div>{t('login.header.a')}</div>
          <div>{t('login.header.b')}</div>
          <div>{t('login.header.c')}</div>
        </Header>
      )}
      {children}
      <Interaction theme={theme}>
        {/* the following button element is used to prevent the onSubmit function of the Form from triggering when pressing ENTER on keyboard*/}
        <button
          type="submit"
          disabled={isActionDisabled(
            authenticating,
            user,
            shouldHideMsisdn,
            termsCheckEnabledActionForm,
            termsStatus,
            displayLogin
          )}
          style={{ display: 'none' }}
          aria-hidden="true"
        ></button>
        {hasPin(user) ? (
          <React.Fragment>
            <InputText
              theme={theme}
              errors={errors}
              name="shortCode"
              placeholder={t('login.pin.placeholder')}
              value={R.propOr('', 'shortCode', user)}
              onChange={handleChange('shortCode', user, onChange)}
              t={t}
            />
            <ResendLink rtl={rtl} onClick={handleResendLogin(onResendLogin)}>
              {t('login.resend.pin')}
            </ResendLink>
          </React.Fragment>
        ) : !shouldHideMsisdn ? (
          <React.Fragment>
            <ReactPhoneInput
              key={(t('login.phone.placeholder') || '').concat(
                availableCountries ? availableCountries.join('') : ''
              )}
              localization={countries[lang]}
              placeholder={t('login.phone.placeholder')}
              preferredCountries={preferredCountries}
              onlyCountries={availableCountries}
              value={R.propOr('', 'phone', user)}
              onChange={onPhoneChange(user, onChange)}
              defaultCountry={cc}
              autoFormat={false}
              disableDropdown={phoneDropDisabled}
              countryCodeEditable={false}
              inputStyle={{
                paddingLeft: phoneDropDisabled ? '10px' : '48px',
              }}
              buttonStyle={{ display: phoneDropDisabled ? 'none' : 'block' }}
              dropdownStyle={{
                textAlign: rtl ? 'right' : 'left',
                bottom: '40px',
              }}
            />
            {R.path(['error', 'key'], errors) ? (
              <ErrorSection msg={t(R.path(['error', 'key'], errors))} />
            ) : null}
          </React.Fragment>
        ) : null}
        <div onClick={() => dataLayerPush(handleDataLayerLogin)}>
          <Button
            disabled={isActionDisabled(
              authenticating,
              user,
              shouldHideMsisdn,
              termsCheckEnabledActionForm,
              termsStatus,
              displayLogin
            )}
            text={
              !displayLogin ? t('login.button.start') : t('login.button.login')
            }
            theme={theme}
            onClick={handleAuth(onAuth)}
            type="button"
          />
        </div>
        {!displayLogin && !shouldHideMsisdn ? (
          <Conditional condition="termsCheckEnabledActionForm">
            <div className={checkBoxContainer}>
              <TermsCheckBox mobile={mobile} onChangeEvent={handleTerms} />
            </div>
          </Conditional>
        ) : null}
        {!displayLogin && !shouldHideMsisdn ? (
          <LinkNote theme={theme} onClick={onWantToLogin(setAction)}>
            <div>{t('login.clickLogin')}</div>
          </LinkNote>
        ) : (
          <LinkNote theme={theme} onClick={onWantToSubscribe(setAction)}>
            <div>{t('login.clickSubscribe')}</div>
          </LinkNote>
        )}
        {!displayLogin && !shouldHideMsisdn && !termsCheckEnabledActionForm ? (
          <Note rtl={rtl}>
            <div>{t('login.note.a')}</div>
          </Note>
        ) : null}
        {shouldHideMsisdn ? (
          <Note>
            <div>{t('actionform.header.enriched.login.note')}</div>
          </Note>
        ) : null}
        {actionFormTncEnabled ? (
          tncData?.external ? (
            <Note rtl={rtl}>
              <span>{t('actionform.tnc.note.a')} </span>
              <a
                className={(TncButton, !tncPath ? disableTncButton : '')}
                target="_blank"
                rel="noopener noreferrer"
                href={tncPath ? tncPath : undefined}
              >
                {t('actionform.tnc.note.b')}
              </a>
              <span> {t('actionform.tnc.note.c')}</span>
            </Note>
          ) : (
            <Note rtl={rtl}>
              <span>{t('actionform.tnc.note.a')}</span>
              <NavLink
                className={TncButton}
                to={{
                  pathname: tncData?.url,
                  state: { returnTo },
                  search: location.search,
                }}
              >
                {t('actionform.tnc.note.b')}
              </NavLink>
              <span>{t('actionform.tnc.note.c')}</span>
            </Note>
          )
        ) : null}
      </Interaction>
    </ActionSection>
  )
}

const PlayBuyAction = ({
  location,
  theme,
  mobile,
  t,
  lang,
  rtl,
  user,
  onChange,
  onAuth,
  children,
  tncData,
  returnTo,
  meta,
}) => {
  const Interaction = mobile ? MobileIO : IO
  const isLanding = location.pathname === '/' || location.pathname === '/home'
  const { canBuy, userStatus, credits: crds, username } = user
  const credits = crds ? crds.length : undefined
  const canPlay = credits && credits > 0
  const { canSelectGame } = user
  const logoSrcExists = themeProp(['page', 'actionformLogo'], theme)
  const { actionFormTncEnabled, defaultLang } = meta
  const tncPath = useExternalTnc()
  const { dataLayerPush } = useDataLayerEvents()
  const userId = !!username ? username : ''
  const userType =
    user?.userStatus === 'SUBSCRIBED' ? 'subscribed' : 'anonymous'
  const userTypeAdvanced = !!username
    ? user?.userStatus === 'SUBSCRIBED'
      ? 'subscribed'
      : 'registered'
    : 'anonymous'
  const queryString = window.location.search
  const getPathName = window.location.pathname.split('&')
  const pathName = getPathName[0]
  const urlParams = new URLSearchParams(queryString)
  const dataLayerObject = {
    event: 'selectGameMode',
    screenName: '/selectMode',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userTypeAdvanced,
  }
  const dataLayerObjectStartPlay = {
    event: 'gamePlay',
    screenName: pathName,
    selectedMode: 'score',
    playType: 'new',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userTypeAdvanced,
  }
  const dataLayerObjectBuy = {
    event: 'buyCredit',
    screenName: pathName,
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userType,
  }
  const dataLayerSub = {
    event: 'SubscriptionStart_click',
    screenName: pathName,
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userType,
  }
  return (
    <ActionSection mobile={mobile}>
      {!mobile && !!logoSrcExists ? (
        <div className={DesktopLogoWrapper}>
          <DesktopLogo
            theme={theme}
            to={{ pathname: '/home', search: location.search }}
          />
        </div>
      ) : null}
      {mobile ? (
        <Header mobile={mobile} rtl={rtl}>
          <TextFit minSize={12} maxSize={18}>
            {t('play.header.a')}
          </TextFit>
          <TextFit minSize={12} maxSize={18}>
            {t('play.header.b')}
          </TextFit>
          <TextFit minSize={12} maxSize={18}>
            {t('play.header.c')}
          </TextFit>
        </Header>
      ) : (
        <Header mobile={mobile} rtl={rtl}>
          <div>{t('play.header.a')}</div>
          <div>{t('play.header.b')}</div>
          <div>{t('play.header.c')}</div>
        </Header>
      )}
      {!isLanding ? <SubHeader mobile={mobile} /> : null}
      {children}
      <Interaction theme={theme}>
        {canPlay ? (
          <div onClick={() => dataLayerPush(dataLayerObjectStartPlay)}>
            <Button
              to={{ pathname: '/game-intro', search: location.search }}
              text={t('play.button')}
              theme={theme}
            />
          </div>
        ) : canBuy ? (
          <>
            <div onClick={() => dataLayerPush(dataLayerObjectBuy)}>
              <Button
                to={{ pathname: '/credits', search: location.search }}
                text={t('buy.button')}
                theme={theme}
              />
            </div>
            <PriceNote>{t('footer.ondemand')}</PriceNote>
          </>
        ) : userStatus === 'UNSUBSCRIBED' || userStatus === 'REGISTERED' ? (
          <div onClick={() => dataLayerPush(dataLayerSub)}>
            <Button
              text={t('login.button.start')}
              onClick={onResubscribe(user, onChange, onAuth)}
              theme={theme}
            />
          </div>
        ) : (
          <Button text={t('play.button')} theme={theme} disabled={true} />
        )}
        <Note rtl={rtl}>
          <div>{t('play.note')}</div>
        </Note>
        {!mobile && canSelectGame ? (
          <Link
            className={SelectGameWrapper}
            to={`/select-game?lang=${lang}`}
            onClick={() => dataLayerPush(dataLayerObject)}
          >
            <div className={SelectGameImgContainer}>
              <img
                alt=""
                className={SelectGameButton(rtl)}
                src={themeProp(['page', 'selectGameImage'], theme)}
                onError={ev =>
                  (ev.target.src =
                    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
                }
              />
            </div>
            <span className={SelectGameMessage}>
              {t('home.gameselection.button')}
            </span>
          </Link>
        ) : null}
        {actionFormTncEnabled ? (
          tncData?.external ? (
            <Note rtl={rtl}>
              <a
                className={(TncButton, !tncPath ? disableTncButton : '')}
                target="_blank"
                rel="noopener noreferrer"
                href={tncPath ? tncPath : undefined}
              >
                {t('actionform.tnc.note')}
              </a>
            </Note>
          ) : (
            <NavLink
              to={{
                pathname: tncData?.url,
                state: { returnTo },
                search: location.search,
              }}
            >
              {t('actionform.tnc.note')}
            </NavLink>
          )
        ) : null}
      </Interaction>
    </ActionSection>
  )
}

const ActionForm = ({ children, mobile }) => {
  const {
    i18n: { t, rtl, lang },
  } = useContext(I18nContext)
  const {
    auth: {
      user,
      onAuth,
      onResendLogin,
      onChange,
      errors,
      authenticating,
      handleTerms,
      termsStatus,
    },
  } = useContext(AuthenticationContext)
  const {
    theme,
    meta,
    links: { footerlinks },
  } = useContext(ConfigurationContext)
  const { location } = useContext(__RouterContext)

  const tncData = R.filter(R.propEq('iconName', 'terms'), footerlinks || [])[0]
  const returnTo = location.state ? location.state.returnTo : location.pathname

  return (
    <Form
      data-e2e="get-started-form"
      mobile={mobile}
      rtl={rtl}
      onSubmit={handleAuth(onAuth)}
    >
      {isAuthenticated(user) ? (
        <PlayBuyAction
          location={location}
          theme={theme}
          t={t}
          lang={lang}
          rtl={rtl}
          mobile={mobile}
          user={user}
          onChange={onChange}
          onAuth={onAuth}
          meta={meta}
          tncData={tncData}
          returnTo={returnTo}
        >
          {children}
        </PlayBuyAction>
      ) : (
        <LoginAction
          authenticating={authenticating}
          errors={errors}
          user={user}
          onChange={onChange}
          onAuth={onAuth}
          onResendLogin={onResendLogin}
          theme={theme}
          mobile={mobile}
          t={t}
          rtl={rtl}
          lang={lang}
          location={location}
          meta={meta}
          footerlinks={footerlinks}
          tncData={tncData}
          returnTo={returnTo}
          handleTerms={handleTerms}
          termsStatus={termsStatus}
        >
          {children}
        </LoginAction>
      )}
    </Form>
  )
}

export default ActionForm
