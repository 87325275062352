import * as R from 'ramda'
import React, { useState, useEffect } from 'react'
import useTeaser from 'containers/TeaserProvider'
import useInterval from 'containers/IntervalProvider'
import useMobileQuery from 'containers/MobileQuery'
import ReactSwipe from 'react-swipe'
import dateFns from 'date-fns'
import styled, { css } from 'react-emotion'
import { useAvailableHeight } from 'containers/AvailableHeight'
import { themeProp, hexToRgbA } from 'utils/theming'

const StyledReactSwipe = styled(ReactSwipe)`
  max-width: 640px;
  height: inherit;
  width: inherit;
  > div {
    height: 100%;
    display: flex;
    flex-direction: row;
  }
`

const cls = {
  tileClass: css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    float: left;
    max-height: inherit;
  `,
  mobileTileClass: imgUrl => css`
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-image: url(${imgUrl});
  `,
  desktopTileClass: imgUrl => css`
    width: 100%;
    height: 490px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-image: url(${imgUrl});
  `,
  timerWrapper: mobile => css`
    width: ${mobile ? 200 : 300}px;
    margin-top: 10px;
  `,
  timerHeader: (theme, mobile) => css`
    padding: 4px;
    border-radius: 6px 6px 0 0;
    margin-bottom: 2px;
    font-size: ${mobile ? 10 : 14}px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${themeProp(['page', 'color'], theme)};
    background-color: ${hexToRgbA(
      themeProp(['page', 'backgroundBase'], theme),
      0.5
    )};
    > div {
      width: 100px;
      text-align: center;
    }
  `,
  timerValues: (theme, mobile) => css`
    padding: 2px;
    border-radius: 0 0 6px 6px;
    font-size: ${mobile ? 14 : 36}px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${themeProp(['page', 'color'], theme)};
    background-color: ${hexToRgbA(
      themeProp(['page', 'backgroundBase'], theme),
      0.8
    )};
    > div {
      width: 100px;
      text-align: center;
    }
  `,
  container: theme => css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: -webkit-fill-available;
    color: ${themeProp(['page', 'color'], theme)};
    font-size: 22px;
    line-height: 22px;
  `,
}

const CountDown = ({ theme, endDate, time, mobile, t }) => {
  const days = dateFns.differenceInDays(endDate, time)
  const hours = dateFns.differenceInHours(dateFns.subDays(endDate, days), time)
  const minutes = dateFns.differenceInMinutes(
    dateFns.subHours(dateFns.subDays(endDate, days), hours),
    time
  )
  return (
    <div className={cls.timerWrapper(mobile)}>
      <div className={cls.timerHeader(theme, mobile)}>
        <div>{t('drawslider.days')}</div>
        <div>{t('drawslider.hours')}</div>
        <div>{t('drawslider.minutes')}</div>
      </div>
      <div className={cls.timerValues(theme, mobile)}>
        <div>{days}</div>
        <div>{hours}</div>
        <div>{minutes}</div>
      </div>
    </div>
  )
}

const SlideTile = ({ theme, tile, time, lang, t, isMobile }) => {
  const { title, imagePaths, endDate: end, displayCountdown } = tile
  const endDate = parseInt(end, 10)
  const imgKeys = Object.keys(imagePaths?.strings)
  const imgKeysLength = Object.keys(imagePaths?.strings).length
  const imgUrl =
    imgKeysLength > 1
      ? imagePaths?.strings[lang]
      : imagePaths?.strings[imgKeys[0]] //this check makes sure that if only 1 img returns it will be shown in both langs

  return (
    <div className={cls.tileClass} theme={theme}>
      {isMobile ? (
        <div className={cls.mobileTileClass(imgUrl)} />
      ) : (
        <div className={cls.desktopTileClass(imgUrl)} />
      )}
      <div>
        {!!displayCountdown ? (
          <CountDown
            theme={theme}
            endDate={endDate}
            time={time}
            mobile={isMobile}
            t={t}
          />
        ) : title && title.strings ? (
          title.strings[lang] || ''
        ) : null}
      </div>
    </div>
  )
}

const DrawSlider = ({ theme, lang, rtl, t }) => {
  const [teaserData] = useTeaser()
  const { isMobile } = useMobileQuery()
  const [availableHeight] = useAvailableHeight()
  const [time, setTime] = useState(new Date().getTime())
  const [setIntervalTime] = useInterval(() => setTime(new Date().getTime()))
  const items = R.path(['teasers', 'data'], teaserData)

  useEffect(() => {
    setIntervalTime(60 * 1000)
    return () => setIntervalTime(null)
  }, [setIntervalTime, time])

  return items && items.length ? (
    <StyledReactSwipe
      availableHeight={availableHeight}
      key={items.length}
      mobile={isMobile}
      swipeOptions={{
        continuous: true,
        startSlide: 0,
        auto: 5000,
        speed: 500,
      }}
    >
      {items.length === 2
        ? items
            .concat(items)
            .map((item, idx) => (
              <SlideTile
                key={idx}
                tile={item}
                time={time}
                theme={theme}
                lang={lang}
                rtl={rtl}
                t={t}
                isMobile={isMobile}
              />
            ))
        : items.map((item, idx) => (
            <SlideTile
              key={idx}
              tile={item}
              time={time}
              theme={theme}
              lang={lang}
              rtl={rtl}
              t={t}
              isMobile={isMobile}
            />
          ))}
    </StyledReactSwipe>
  ) : (
    <div className={cls.container(theme)}>
      {themeProp(['page', 'message'], theme)
        ? t(themeProp(['page', 'message'], theme))
        : null}
    </div>
  )
}

export default DrawSlider
