import React from 'react'
import Helmet from 'react-helmet'
import { ConfigurationContext } from 'containers/ConfigurationProvider'

const Tags = React.memo(({ title, description }) => (
  <Helmet>
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
  </Helmet>
))

const MetaTags = () => (
  <ConfigurationContext.Consumer>
    {({ meta: { title, description } }) => (
      <Tags title={title} description={description} />
    )}
  </ConfigurationContext.Consumer>
)

export default MetaTags
