import * as R from 'ramda'
import React, { useContext, useEffect } from 'react'
import Helmet from 'react-helmet'
import { ConfigurationContext } from 'containers/ConfigurationProvider'

const getHEProperty = property =>
  window.TPay.HeaderEnrichment[property] &&
  window.TPay.HeaderEnrichment[property]()

const headerEnrich = () => {
  window.setTimeout(() => {
    if (window.TPay) {
      const isEnriched = getHEProperty('enriched')
      if (isEnriched) {
        const phone = getHEProperty('msisdn')
        const operatorId = getHEProperty('operatorCode')
        // const subscribed = getHEProperty('hasSubscription')
        // operatorId && getHEProperty('confirm')
        console.log('>>> header enrichment subsciption ', {
          phone,
          operatorId,
          // subscribed,
        })
        // const { user, onChange } = this.props.auth
        // onChange({ ...user, phone, operatorId, headerEnrichment: true })
      }
    }
  }, 800)
}

const TPayImpl = ({ auth }) => {
  const {
    meta: {
      title,
      operator: { key },
    },
  } = useContext(ConfigurationContext)
  const digest = R.path(['user', 'digest'], auth)
  const digestDate = R.path(['user', 'seed'], auth)
  const isTPayLoaded = window.TPay
  const src = digest
    ? `http://lookup.tpay.me/idxml.ashx/js-staging?date=${digestDate}&digest=${key}:${digest}&simulate=true&operatorcode=60201&msisdn=20122080513`
    : undefined

  useEffect(() => {
    return function cleanup() {
      // nothing
    }
  }, [digest, isTPayLoaded])

  return (
    <Helmet>
      <title>{title}</title>
      {src ? <script src={src} onLoad={headerEnrich()} /> : null}
    </Helmet>
  )
}

export default TPayImpl
