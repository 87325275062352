import * as R from 'ramda'
import React, { useContext } from 'react'
import useMobileQuery from 'containers/MobileQuery'
import * as Layouts from './layouts'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { AuthenticationContext } from 'containers/AuthProvider'
import useGame from 'containers/GameProvider'
import { I18nContext } from 'containers/I18nProvider'

const getGameStatus = data =>
  R.compose(
    R.unless(R.isNil, R.toLower),
    R.pathOr(null, ['game', 'gameStatus'])
  )(data)

const Game = ({ location }) => {
  const {
    i18n: { rtl },
  } = useContext(I18nContext)
  const [gameData, actions] = useGame()
  const { tick } = gameData
  const { theme, layout, meta } = useContext(ConfigurationContext)
  const {
    auth: { user },
  } = useContext(AuthenticationContext)
  const { isMobile } = useMobileQuery()

  const status = getGameStatus(gameData)
  const position = R.pathOr(null, ['game', 'currentStep', 'position'], gameData)
  const inProgress = status === 'in_progress'
  const props = {
    user,
    gameData,
    actions,
    tick,
    location,
    rtl,
    theme,
    position,
    inProgress,
    meta,
  }

  const device = isMobile ? layout.phone : layout.default
  const Layout = Layouts[device] || Layouts['DEFAULT']

  return <Layout {...props} />
}

export default Game
