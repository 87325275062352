import * as R from 'ramda'
import memoize from 'memoize-immutable'
import qs from 'query-string'

const lastSlashIdx = str => str.lastIndexOf('/')
export const themeKey = memoize(
  location => {
    const path = location.pathname
    if (path === '/') return 'land'
    return path.substring(lastSlashIdx(path) + 1, path.length)
  },
  { limit: 2 }
)

export const metaProps = appConfig => R.propOr({}, 'meta', appConfig)

export const routeProp = memoize(
  (route, prop, appConfig) =>
    R.pathOr(
      R.path(['routes', 'default', prop], appConfig),
      ['routes', route, prop],
      appConfig
    ),
  { limit: 2 }
)

export const themeProps = memoize(
  (loc, appConfig) => {
    const def = R.path(['routes', 'default', 'theme'], appConfig)
    const ext = R.path(['routes', loc, 'theme'], appConfig)

    return ext ? R.mergeDeepRight(def, ext) : def
  },
  { limit: 2 }
)

const getLocationParams = location => qs.parse(location.search)

export const getDefaultLang = (configurationLang, location) =>
  R.propOr(configurationLang || 'ar', 'lang', getLocationParams(location))

export const themeProp = (p, theme) => R.pathOr(null, p, theme)

export const mediaBreakpoints = {
  phoneLimit: 767,
}

export const hexToRgbA = (raw, alpha) => {
  if (!raw || raw === 'transparent') {
    return 'rgba(255, 255, 255, 0)'
  }
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(raw)) {
    c = raw.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    const rgb = [(c >> 16) & 255, (c >> 8) & 255, c & 255]
    return `rgba(${rgb.join(',')}, ${alpha})`
  } else if (/^rgb/.test(raw)) {
    const cols = raw.substring(raw.indexOf('(') + 1, raw.length - 1)
    return `rgba(${cols}, ${alpha})`
  }
  throw new Error('Bad Hex or Rgb')
}
