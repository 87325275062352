import React, { useContext } from 'react'
import { Private } from 'lib/auth-utils'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import { cx, css } from 'emotion'
import { themeProp } from 'utils/theming'
import { motion } from 'framer-motion'
import { HeaderLogo } from 'layouts/index'
import { useDataLayerEvents } from 'containers/dataLayerEvents'
import { ConfigurationContext } from 'containers/ConfigurationProvider'

const cls = {
  pageClass: ({ theme }) => css`
    font-family: ${themeProp(['page', 'fontFamily'], theme)};
    color: ${themeProp(['page', 'color'], theme)};
    background-color: ${themeProp(['page', 'backgroundColor'], theme)};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 100vw;
    height: 100dvh;
    display: flex;
    margin: 0 auto;
    flex-flow: column;
    transition: background-color 0.2s ease-in;
  `,
  logo: ({ mobile, rtl }) => css`
    width: 100%;
    display: flex;
    justify-content: ${rtl ? 'flex-start' : 'flex-end'};
    > a {
      margin: ${mobile ? '15px' : '24px'};
      width: ${mobile ? '53px' : '100px'};
      height: ${mobile ? '56px' : '100px'};
    }
  `,
  content: css`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  `,
  header: ({ theme, mobile, rtl }) => css`
    max-width: ${mobile ? '205px' : '350px'};
    direction: ${rtl ? 'rtl' : 'ltr'};
    text-align: center;
    font-size: ${mobile ? '17px' : '25px'};
    margin: ${mobile ? '0 0 20px' : '0 0 40px'};
    color: ${themeProp(['content', 'headerColor'], theme)};
  `,
  slots: ({ mobile }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: ${mobile ? '255px' : '390px'};
    max-height: ${mobile ? '200px' : '350px'};
    margin: 0;

    > li:nth-child(1) {
      margin: 0 24px 24px 0;
    }
    > li:nth-child(2) {
      margin: 0 0 24px 0;
    }
    > li:nth-child(3) {
      margin: 0 24px 0 0;
    }
  `,
  slot: ({ n, mobile }) => css`
    position: relative;
    display: flex !important;
    flex-direction: column;
    height: ${mobile ? '100px' : '170px'};
    width: ${mobile ? '100px' : '170px'};
    cursor: pointer;
    border-radius: ${mobile ? '5px' : '15px'};
    justify-content: flex-end;
    align-items: center;

    > img {
      width: 100%;
      transition: opacity 0.3s ease-in-out;
      transition-delay: 1s;
      border-radius: ${mobile ? '5px' : '15px'};
    }
    > img:first-child {
      position: absolute;
      z-index: 2;
    }

    > img:nth-child(2) {
      position: absolute;
      max-width: 65%;
      max-height: 55%;
      transform: translateX(-50%) translateY(-50%) translateZ(0px);
      z-index: 1;
      top: 50%;
      left: 50%;
    }

    &.disabled {
      cursor: auto;
    }
  `,
  slotTextContainer: ({ theme }) => css`
    position: relative;
    margin: 20px 0;
    box-sizing: border-box;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 19px;
    background-image: ${themeProp(['content', 'prizeTextBackground'], theme)};
    background-origin: border-box;
    background-clip: content-box, border-box;
    transition: opacity 0.3s ease-in-out;
    transition-delay: 1.6s;
  `,

  slotTextContainerPadding: ({ mobile }) => css`
    padding: ${mobile ? '30px' : '40px'};
  `,

  slotText: ({ theme, mobile, rtl }) => css`
    color: ${themeProp(['content', 'prizeColor'], theme)};
    text-align: center;
    direction: ${rtl ? 'rtl' : 'ltr'};
    font-size: ${mobile ? '18px' : '24px'};
    transition: opacity 0.3s ease-in-out;
    transition-delay: 1.8s;

    &.guaranteed {
      transition: opacity 0.3s ease-in-out;
      transition-delay: 2s;
      opacity: 0;
      color: ${themeProp(['content', 'guaranteedColor'], theme)};
      white-space: nowrap;
      &::before {
        content: '+';
      }
    }
  `,
  bottom: ({ rtl }) => css`
    padding: 20px 0 0;
    display: flex;
    direction: ${rtl ? 'rtl' : 'ltr'};
    justify-content: center;
    align-items: center;
    > div {
      white-space: nowrap;
      max-width: fit-content;
    }
  `,
}

const Simple = ({
  state,
  choosePrize,
  counter,
  gamePrizes = 0,
  auth,
  theme,
  mobile,
  location,
  rtl,
  t,
  lang,
}) => {
  const { selectedPrize, pickedSlot } = state

  const newPositions = [
    { top: 50, left: 50 },
    { top: 50, left: -50 },
    { top: -50, left: 50 },
    { top: -50, left: -50 },
  ]

  const [top, setTop] = React.useState({ 0: 0, 1: 0, 2: 0, 3: 0 })
  const [left, setLeft] = React.useState({ 0: 0, 1: 0, 2: 0, 3: 0 })
  const [scale, setScale] = React.useState({ 0: 1, 1: 1, 2: 1, 3: 1 })
  const [zIndex, setZindex] = React.useState({ 0: 1, 1: 1, 2: 1, 3: 1 })

  const setPosition = (item, { top, left }) => {
    setTop(pos => ({ ...pos, [item]: top }))
    setLeft(pos => ({ ...pos, [item]: left }))
    setScale(pos => ({ ...{ 0: 0, 1: 0, 2: 0, 3: 0 }, [item]: 1.5 }))
    setZindex(pos => ({ ...{ 0: 1, 1: 1, 2: 1, 3: 1 }, [item]: 2 }))
  }
  const { dataLayerPush } = useDataLayerEvents()
  const userId = !!auth?.user?.id ? auth?.user?.id : ''

  const userTypeAdvanced = !!auth?.user?.username
    ? auth?.user?.userStatus === 'SUBSCRIBED'
      ? 'subscribed'
      : 'registered'
    : 'anonymous'
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const {
    meta: { defaultLang },
  } = useContext(ConfigurationContext)
  const dataLayerObject = {
    event: 'startAnswers',
    screenName: '/startAnswers',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userTypeAdvanced,
  }
  return (
    <Private user={auth.user}>
      <BackgroundImageWrapper
        imgsrc={themeProp(['page', 'backgroundImage'], theme)}
        className={cls.pageClass({ theme })}
      >
        <div className={cls.logo({ mobile, rtl })}>
          <HeaderLogo
            theme={theme}
            mobile={mobile}
            className={'logo1'}
            to={{ pathname: '/home', search: location.search }}
          />
        </div>
        <div className={cls.content}>
          <h1 className={cls.header({ theme, mobile, rtl })}>
            {!pickedSlot ? (
              <div>{t('gameprize.header.1')}</div>
            ) : (
              <div>{t('gameprize.header.2')}</div>
            )}
          </h1>
          <div
            className={cls.slots({ mobile })}
            style={{
              justifyContent: `${selectedPrize ? 'space-between' : 'center'}`,
            }}
          >
            {[...Array(gamePrizes)].map((_, n) => (
              <motion.li
                className={cls.slot({ n, mobile })}
                key={n}
                animate={{
                  scale: scale[n],
                  top: `${top[n]}%`,
                  left: `${left[n]}%`,
                  translateY: `${-top[n]}%`,
                  translateX: `${-left[n]}%`,
                  zIndex: zIndex[n],
                }}
                transition={{ ease: 'backOut' }}
                style={{
                  margin: `${selectedPrize ? 0 : null}`,
                }}
                onClick={evt => {
                  const newPos = newPositions[n]
                  setPosition(n, newPos)
                  if (!pickedSlot) {
                    choosePrize(n + 1)(evt)
                  }
                  dataLayerPush(dataLayerObject)
                }}
              >
                <img
                  style={{ opacity: `${selectedPrize ? 0 : 1}` }}
                  alt=""
                  src={`/images/choose_prize_${n + 1}.png`}
                  onError={ev =>
                    (ev.target.src =
                      'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
                  }
                />
                {selectedPrize && pickedSlot === n + 1 ? (
                  <img
                    alt=""
                    src={selectedPrize?.regular?.imagePath}
                    onError={ev =>
                      (ev.target.src =
                        'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
                    }
                  />
                ) : null}
              </motion.li>
            ))}
          </div>
          <div
            className={cls.slotTextContainer({ theme })}
            style={{ opacity: `${selectedPrize ? 1 : 0}` }}
          >
            <div className={cls.slotTextContainerPadding({ mobile })}>
              <div
                className={cls.slotText({ theme, mobile, rtl })}
                style={{ opacity: `${selectedPrize ? 1 : 0}` }}
              >
                {selectedPrize?.regular?.description?.strings[lang]}
              </div>
              <div
                className={cx([
                  cls.slotText({ theme, mobile, rtl }),
                  `${selectedPrize?.guaranteed ? 'guaranteed' : ''}`,
                ])}
                style={{ opacity: `${!!selectedPrize?.guaranteed ? 1 : 0}` }}
              >
                {selectedPrize?.guaranteed?.description?.strings[lang]}
              </div>
            </div>
          </div>
          <div className={cls.bottom(rtl)}>
            {!!pickedSlot ? (
              <div>{`${t('selectPrize.gameStartsIn')} ${counter}`}</div>
            ) : null}
          </div>
        </div>
      </BackgroundImageWrapper>
    </Private>
  )
}

export default Simple
