import * as R from 'ramda'
import React from 'react'
import HTML5Backend from 'react-dnd-html5-backend'
import DragAnswer from 'components/Game/DragAnswer'
import DragAnswerPreview from 'components/Game/DragAnswerPreview'
import DragDropContext from 'react-dnd/lib/DragDropContext'
import Button, {
  styles as buttonStyles,
  theme as buttonTheme,
} from 'components/Button'
import { keyframes, css, cx } from 'react-emotion'
import TextFit from 'containers/TextFit'
import { default as TouchBackend } from 'react-dnd-touch-backend'
import { hexToRgbA } from 'utils/theming'

const mapIndexed = R.addIndex(R.map)

const theme = {
  azercell: css`
    --text-color: #580f36;
    --background-color: #580f36;
    --active-text-color: #fff;
    --active-background-color: #02b0db;
    --button-base-color-full: ${hexToRgbA(`#e8ba3f`, 0)};
    --button-base-color-half: ${hexToRgbA(`#e8ba3f`, 0.4)};
    --answer-border-radius: 100px;
  `,
  beeline: css`
    --text-color: #629ec5;
    --background-color: #629ec5;
    --active-text-color: #fff;
    --active-background-color: #629ec5;
    --button-base-color-full: ${hexToRgbA(`#cacaca`, 0)};
    --button-base-color-half: ${hexToRgbA(`#cacaca`, 0.4)};
    --answer-border-radius: 100px;
  `,
  celcinwin: css`
    --text-color: rgb(43, 30, 135);
    --background-color: rgb(43, 30, 135);
    --active-text-color: #fff;
    --active-background-color: #ff8000;
    --button-base-color-full: ${hexToRgbA(`#e8ba3f`, 0)};
    --button-base-color-half: ${hexToRgbA(`#e8ba3f`, 0.4)};
    --answer-border-radius: 100px;
  `,
  djezzy: css`
    --text-color: #4c4c4c;
    --background-color: #009ce3;
    --active-text-color: #ff3c66;
    --active-background-color: #fff000;
    --button-base-color-full: ${hexToRgbA(`#e8ba3f`, 0)};
    --button-base-color-half: ${hexToRgbA(`#e8ba3f`, 0.4)};
    --answer-border-radius: 100px;
  `,
  flashwin: css`
    --text-color: rgb(43, 30, 135);
    --background-color: rgb(43, 30, 135);
    --active-text-color: #fff;
    --active-background-color: rgb(43, 30, 135);
    --button-base-color-full: ${hexToRgbA(`#e8ba3f`, 0)};
    --button-base-color-half: ${hexToRgbA(`#e8ba3f`, 0.4)};
    --answer-border-radius: 100px;
  `,
  libyana: css`
    --text-color: rgb(43, 30, 135);
    --background-color: rgb(43, 30, 135);
    --active-text-color: #fff;
    --active-background-color: rgb(43, 30, 135);
    --button-base-color-full: ${hexToRgbA(`#e8ba3f`, 0)};
    --button-base-color-half: ${hexToRgbA(`#e8ba3f`, 0.4)};
    --answer-border-radius: 100px;
  `,
  lifecell: css`
    --text-color: #22489a;
    --background-color: #22489a;
    --active-text-color: #fff;
    --active-background-color: #163f8c;
    --button-base-color-full: ${hexToRgbA(`#e8ba3f`, 0)};
    --button-base-color-half: ${hexToRgbA(`#e8ba3f`, 0.4)};
    --answer-border-radius: 100px;
  `,
  indosat: css`
    --text-color: #000;
    --background-color: #000;
    --active-text-color: #fff;
    --active-background-color: #ec1c24;
    --button-base-color-full: ${hexToRgbA(`#e8ba3f`, 0)};
    --button-base-color-half: ${hexToRgbA(`#e8ba3f`, 0.4)};
    --answer-border-radius: 100px;
  `,
  mtnNga: css`
    --text-color: #000;
    --background-color: #000;
    --active-text-color: #fff;
    --active-background-color: rgb(255, 195, 15);
    --button-base-color-full: ${hexToRgbA(`#cacaca`, 0)};
    --button-base-color-half: ${hexToRgbA(`#cacaca`, 0.4)};
    --answer-border-radius: 100px;
  `,
  mtnZaf: css`
    --text-color: #000;
    --background-color: #000;
    --active-text-color: #fff;
    --active-background-color: rgb(255, 195, 15);
    --button-base-color-full: ${hexToRgbA(`#cacaca`, 0)};
    --button-base-color-half: ${hexToRgbA(`#cacaca`, 0.4)};
    --answer-border-radius: 100px;
  `,
  syriatel: css`
    --text-color: #dc0000;
    --background-color: #dc0000;
    --active-text-color: #fff;
    --active-background-color: #dc0000;
    --button-base-color-full: ${hexToRgbA(`#e8ba3f`, 0)};
    --button-base-color-half: ${hexToRgbA(`#e8ba3f`, 0.4)};
    --answer-border-radius: 100px;
  `,
  ucell: css`
    --text-color: #642887;
    --background-color: #642887;
    --active-text-color: #fff;
    --active-background-color: #642887;
    --button-base-color-full: ${hexToRgbA(`#39ba95`, 0)};
    --button-base-color-half: ${hexToRgbA(`#39ba95`, 0.4)};
    --answer-border-radius: 100px;
  `,
  mtnSAMusic: css`
    --text-color: #000;
    --background-color: #000;
    --active-text-color: #fff;
    --active-background-color: rgb(255, 195, 15);
    --button-base-color-full: ${hexToRgbA(`#cacaca`, 0)};
    --button-base-color-half: ${hexToRgbA(`#cacaca`, 0.4)};
    --answer-border-radius: 100px;
  `,
  stcbh: css`
    --text-color: #4f008c;
    --background-color: rgba(128, 128, 128, 0.21);
    --active-text-color: #fff;
    --active-background-color: #ff375e;
    --button-base-color-full: ${hexToRgbA(`#4f008c`, 0)};
    --button-base-color-half: ${hexToRgbA(`#4f008c`, 0.4)};
    --answer-border-radius: 0;
  `,
  gloNga: css`
    --text-color: #000;
    --background-color: rgba(128, 128, 128, 0.21);
    --active-text-color: #fff;
    --active-background-color: #4bb44e;
    --button-base-color-full: ${hexToRgbA(`#265a27`, 0)};
    --button-base-color-half: ${hexToRgbA(`#265a27`, 0.4)};
    --answer-border-radius: 100px;
  `,
  kcell: css`
    --text-color: #000;
    --background-color: #f1f1f1;
    --active-text-color: #fff;
    --active-background-color: #652d86;
    --button-base-color-full: ${hexToRgbA(`#652d86`, 0)};
    --button-base-color-half: ${hexToRgbA(`#652d86`, 0.4)};
    --answer-border-radius: 14px;
  `,
}

export const styles = {
  bottom: highlight => css`
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    > button {
      width: 75%;
      margin: 12px auto;
      animation: ${highlight
        ? `1s linear infinite ${highlightAnimation}`
        : 'none'};
    }
  `,
  answerList: css`
    list-style: none;
    margin: 0;
    padding: 0;
  `,
  dataLayerContainer: css`
    width: inherit;
    height: inherit;
  `,
  answer: (rtl, mobile) =>
    css`
      padding: 5px;
      outline: none;
      list-style: none;
      text-overflow: ellipsis;
      overflow: hidden;
      direction: ${rtl ? 'rtl' : 'ltr'};
      height: ${mobile ? '25px' : '35px'};
      text-align: center;
      width: 75%;
      margin: 5px auto;
      border-radius: var(--answer-border-radius);
      border: 1px solid;
      cursor: pointer;
      color: var(--text-color);
      border-color: var(--background-color);
      &:active,
      &:focus {
        outline: none;
      }
      &.active {
        color: var(--active-text-color);
        border-color: var(--active-background-color);
        background-color: var(--active-background-color);
      }
      transition: background-color 0.5s ease;
    `,
  buttonContainer: css`
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  `,
  nextButton: highlight => css`
    width: 75%;
    margin: 12 auto;
    animation: ${highlight
      ? `1s linear infinite ${highlightAnimation}`
      : 'none'};
  `,
  dragIndicator: css`
    background-color: #111;
    mask-image: url('/images/point-touch.svg');
    mask-repeat: no-repeat;
    mask-position: 50%;
    height: 200px;
    width: 100%;
    display: block;
  `,
}

const highlightAnimation = keyframes`
  0% {
    box-shadow: 0px 0px 0px 0px var(--button-base-color-full);
  }
  50% {
    box-shadow: 0px 0px 5px 10px var(--button-base-color-half);
  }
  100% {
    box-shadow: 0px 0px 0px 0px var(--button-base-color-full);
  }
`
const removeSelected = (id, selected) =>
  R.remove(R.indexOf(id, selected), 1, selected)

const getAnswerId = answer => R.propOr(null, 'id', answer)

const getAnswerBody = (answer, lang) =>
  R.pathOr(null, ['body', 'strings', lang], answer)

const isActive = (answer, state) =>
  R.contains(getAnswerId(answer), R.prop('selected', state))

const handleSelect = (onSelect, aid, qtype) => _ => onSelect(aid, qtype)
const handleNext = (action, state) => _ => action(state.selected)

const OrderedAnswers = ({ onMove, answers, mobile, lang, rtl }) => (
  <div>
    <DragAnswerPreview num={answers.length} mobile={mobile} />
    {mapIndexed(
      (answer, i) => (
        <DragAnswer
          key={getAnswerId(answer)}
          index={i}
          id={getAnswerId(answer)}
          onMove={onMove}
        >
          <li className={cx(styles.answer(rtl, mobile))}>
            <TextFit minSize={10} maxSize={16}>
              {getAnswerBody(answer, lang)}
            </TextFit>
          </li>
        </DragAnswer>
      ),
      answers
    )}
  </div>
)

const Backend = window.isMobileOrTablet() ? TouchBackend : HTML5Backend

class AnswerSection extends React.Component {
  constructor(props) {
    super(props)
    const initOrderedAnswersIds = R.pluck('id')(props?.answers)
    const initSelectedArray =
      props?.qtype === 'ORDERED' ? initOrderedAnswersIds : []
    this.state = { selected: initSelectedArray, orderedAnswers: props.answers }
  }

  onSelect = (aid, type) => {
    const selected = this.state.selected
    const updated = R.contains(aid, selected)
      ? removeSelected(aid, selected)
      : type === 'MC_UNIQUE'
      ? [aid]
      : R.append(aid, selected)
    this.setState(state => ({ ...state, selected: updated }))
  }

  onMove = (dragIndex, hoverIndex) => {
    const { orderedAnswers } = this.state
    const dragAnswer = orderedAnswers[dragIndex]
    const reOrdered = R.compose(
      R.insert(hoverIndex, dragAnswer),
      R.remove(dragIndex, 1)
    )(orderedAnswers)

    this.setState(state => ({
      ...state,
      orderedAnswers: reOrdered,
      selected: R.pluck('id')(reOrdered),
    }))
  }

  render() {
    const {
      meta,
      answers,
      t,
      onNext,
      qtype,
      tick,
      mobile,
      lang,
      rtl,
      user,
    } = this.props
    const { orderedAnswers } = this.state
    const isOrder = qtype === 'ORDERED'
    const highlight = tick.value > tick.max - 5
    const answerBoxes = R.map(answer => {
      const answerId = getAnswerId(answer)
      const answerText = getAnswerBody(answer, lang) || ''
      const activeClass = isActive(answer, this.state) ? 'active' : ''

      return (
        <li
          className={cx(styles.answer(rtl, mobile), activeClass)}
          key={answerId}
          onClick={
            !isOrder ? handleSelect(this.onSelect, answerId, qtype) : null
          }
        >
          <TextFit minSize={10} maxSize={16}>
            {answerText}
          </TextFit>
        </li>
      )
    }, answers)

    if (tick.value < 0) {
      return isOrder ? <div className={styles.dragIndicator} /> : null
    }
    const appliedTheme = theme[meta.installation]
    const appliedButtonTheme = buttonTheme[meta.installation]
    const handleDataLayerPush = () => {
      const userId = !!user?.id ? user?.id : ''
      const userTypeAdvanced = !!user?.username
        ? user?.userStatus === 'SUBSCRIBED'
          ? 'subscribed'
          : 'registered'
        : 'anonymous'
      const dataLayerObject = {
        event: 'answerAttempt',
        screenName: '/startAnswers',
        selectedMode: 'score',
        answer_attempts: 1,
        userId: userId,
        userType: userTypeAdvanced,
      }
      return window?.dataLayer?.push(dataLayerObject)
    }

    return (
      <section className={appliedTheme}>
        {isOrder ? (
          <OrderedAnswers
            onMove={this.onMove}
            answers={orderedAnswers}
            theme={theme}
            t={t}
            mobile={mobile}
            lang={lang}
          />
        ) : (
          <ul className={styles.answerList}>{answerBoxes}</ul>
        )}
        <div className={styles.buttonContainer}>
          <div
            className={styles.dataLayerContainer}
            onClick={handleDataLayerPush}
          >
            <Button
              className={cx(
                appliedButtonTheme,
                buttonStyles.button(mobile),
                styles.nextButton(highlight)
              )}
              text={t('answers.next')}
              onClick={handleNext(onNext, this.state)}
            />
          </div>
        </div>
      </section>
    )
  }
}

export default DragDropContext(Backend)(AnswerSection)
