import * as R from 'ramda'

import { create, read, tokenXhr, update } from '@logicea/xhr-helpers/lib'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import {
  getSessionTokens,
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from './utils'
import { getOperator, getTokenValues, getUtm } from './utils/authProvider'

import useExternals from 'components/Externals'
import LoadingOverlay from 'components/LoadingOverlay'
import { I18nContext } from 'containers/I18nProvider'
import { useFbe } from 'containers/WithFbe'
import { useGtag } from 'containers/WithGtag'
import { useSnapChat } from 'containers/WithSnapChat'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'
import { ConfigurationContext } from './ConfigurationProvider'
import { useDataLayerEvents } from './dataLayerEvents'
import useInterval from './IntervalProvider'

export const AuthenticationContext = React.createContext()

const refreshThreshold = 5000
const secondsToMillis = R.multiply(1000)

const hasCookie = name => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  return parts.length === 2 // Returns true if the cookie is found, otherwise false
}

const initialState = {
  initializing: false,
  authenticating: false,
  errors: null,
  user: { canBuy: false, validationRequired: true },
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'PREAUTH_PENDING':
      return { ...state, initializing: true }
    case 'PREAUTH_FAILURE':
      return { ...state, initializing: false, errors: payload }
    case 'PREAUTH_SUCCESS':
      return {
        ...state,
        initializing: false,
        user: { ...state.user, ...payload },
      }
    case 'AUTH_PENDING':
      return { ...state, authenticating: true }
    case 'AUTH_FAILURE':
      return {
        ...state,
        subscriptionPending: false,
        initializing: false,
        authenticating: false,
        errors: payload,
        userError: R.pathEq(['cause'], 'cannot.fetch.user')(payload || {}),
        user: R.pick(
          [
            'msisdn',
            'phone',
            'countryCode',
            'operatorId',
            'shortCode',
            'sms',
            'pinPrompted',
            'subscription',
            'userStatus',
            'operators',
            'validationRequired',
            'headerEnrichment',
            'availableOperators',
          ],
          state.user
        ),
      }
    case 'AUTH_SUCCESS':
      return {
        ...state,
        subscriptionPending: false,
        initializing: false,
        authenticating: false,
        errors: null,
        user: R.merge(state.user, payload),
      }
    case 'BUY_PENDING':
      return {
        ...state,
        pendingBuy: true,
      }
    case 'BUY_FAILURE':
      return {
        ...state,
        pendingBuy: false,
        errors: payload,
        user: R.assoc('canBuy', true, state.user),
      }
    case 'BUY_SUCCESS':
      return {
        ...state,
        pendingBuy: false,
        user: { ...R.omit(['buyShortCode'], state.user), ...payload },
      }
    case 'CHANGE':
      return { ...state, user: payload, errors: null }
    case 'CLEAR_ERRORS':
      return { ...state, errors: null, userError: null }
    case 'GA_EVENT': {
      const {
        gaEvent: eventAction,
        userStatus,
        dataLayerEvent,
        eventCategory,
        eventLabel,
        utm,
      } = payload
      const isSubscribed =
        eventAction === 'Subscription' && userStatus === 'SUBSCRIBED'
      isSubscribed &&
        !!dataLayerEvent &&
        !!window.dataLayer &&
        window.dataLayer.push({ event: dataLayerEvent })

      window.ga &&
        window.ga('send', {
          hitType: 'event',
          eventCategory,
          eventAction,
          eventLabel,
          utm,
        })
      return state
    }
    case 'UPDATE_CREDITS':
      return { ...state, user: { ...state.user, credits: payload } }
    case 'SUBSCRIPTION_PENDING':
      return { ...state, pendingSubscription: true }
    case 'SUBSCRIPTION_ERROR':
      return { ...state, pendingSubscription: false, errors: payload }
    case 'ANTIFRAUD_LOADED':
      return { ...state, user: { ...state.user, antiFraudLoaded: true } }
    default:
      throw new Error()
  }
}

const preAuthPending = () => ({ type: 'PREAUTH_PENDING' })
const authPending = () => ({ type: 'AUTH_PENDING' })
const clearErrors = () => ({ type: 'CLEAR_ERRORS' })
const authSuccess = payload => ({ type: 'AUTH_SUCCESS', payload })
const authFailure = payload => ({ type: 'AUTH_FAILURE', payload })
const buyPending = () => ({ type: 'BUY_PENDING' })
const buyFailure = payload => ({ type: 'BUY_FAILURE', payload })
const buySuccess = payload => ({ type: 'BUY_SUCCESS', payload })
const change = payload => ({ type: 'CHANGE', payload })
const preAuthFailure = payload => ({ type: 'PREAUTH_FAILURE', payload })
const preAuthSuccess = payload => ({ type: 'PREAUTH_SUCCESS', payload })
const gaEvent = payload => ({ type: 'GA_EVENT', payload })
const updateCredits = payload => ({ type: 'UPDATE_CREDITS', payload })
const subscriptionPending = () => ({ type: 'SUBSCRIPTION_PENDING' })
const subscriptionError = payload => ({ type: 'SUBSCRIPTION_ERROR', payload })
const antifraudLoaded = () => ({ type: 'ANTIFRAUD_LOADED' })

const AuthContainer = ({ location, history, children }) => {
  const urlParams = new URLSearchParams(location.search)
  const getClickId =
    urlParams.get('clickId') ||
    urlParams.get('click_id') ||
    urlParams.get('clickid') // todo create param for every installation
  const {
    meta: {
      hideWelcomePage,
      dataLayerEvent,
      alternativeLoginFlow,
      defaultLang,
      termsCheckEnabledSubOverlay,
    },
  } = React.useContext(ConfigurationContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const { fbeSend } = useFbe()
  const { gtagSend } = useGtag()
  const { snapSend } = useSnapChat()

  const [resumePopup, setResumePopup] = useState(false)
  const [termsStatus, setTerms] = useState(true)
  const handleTerms = () => setTerms(!termsStatus)
  const canSelectGame = state?.user?.canSelectGame
  const countryCode = state?.user?.countryCode?.toUpperCase()
  const operators =
    state?.user?.operators && countryCode
      ? state?.user?.operators[countryCode]
      : undefined
  const operatorId = state?.user?.operatorId
  const aggregator =
    operators?.length && operatorId
      ? operators.find(o => o.id === operatorId)?.aggregator
      : undefined
  const externals = useExternals(aggregator)

  const search = useMemo(() => qs.parse(location.search) || {}, [
    location.search,
  ])

  const {
    i18n: { lang: language },
  } = useContext(I18nContext)
  const { refresh_token: urlToken } = search

  const fireAnalyticsEvent = eventName => {
    if (eventName === 'subscription') {
      gtagSend()
      fbeSend('completeRegistration')
    } else if (eventName === 'signup') {
      snapSend('SIGN_UP')
    }
  }
  const hasWelcomeBeenDisplayed = getStorageItem('welcomevisited')

  const { dataLayerPush } = useDataLayerEvents()
  const { credits: userCredits, cost: userCreditsCost } =
    state?.user?.availableBundles?.[0] || {}
  const preAuthUserId = !!state?.user?.phone ? state?.user?.phone : ''
  const userId = !!state?.user?.id ? state?.user?.id : ''
  const userType =
    state?.user?.userStatus === 'SUBSCRIBED' ? 'subscribed' : 'anonymous'

  const dataLayerObjectNewSubExternal = {
    event: 'NewSubExternal',
    screenName: '/newSubExternalSuccess',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: preAuthUserId,
    userType: 'subscribed',
  }

  const dataLayerObjectLogin = {
    event: 'NewLogin',
    screenName: '/loginSuccess',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: preAuthUserId,
    userType: userType,
  }
  const dataLayerObjectLogout = {
    event: 'logOut',
    screenName: '/logOutSuccess',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userType,
  }
  const dataLayerObjectSub = {
    event: 'NewSubscription',
    screenName: '/subscriptionSuccess',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: preAuthUserId,
    userType: 'subscribed',
  }
  const dataLayerObjectPurchaseStart = {
    event: 'startBuy',
    screenName: '/creditStore',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userType,
  }
  const dataLayerObjectPurchaseSuc = {
    event: 'purchaseSuccess',
    screenName: '/creditStore',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    trialsPurchased: userCredits,
    amount: userCreditsCost,
    userId: userId,
    userType: userType,
  }
  const dataLayerObjectPurchaseFail = {
    event: 'purchaseWrongOtp',
    screenName: '/creditStore',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userType,
  }
  const logout = () => {
    sessionStorage.removeItem('login_popup')
    removeStorageItem('auth')
    window.location.replace(`/home?lang=${language}`)
    dataLayerPush(dataLayerObjectLogout)
  }

  const fetchUser = useCallback(async () => {
    const storageTokens = getSessionTokens()
    const token = storageTokens ? storageTokens.access_token : state.user.token
    try {
      const fetchedUser = await read('/p10/user', token)
      if (fetchedUser && fetchedUser.username) {
        fetchedUser.phone = fetchedUser.username
      }
      if (
        fetchedUser.status === 'UNSUBSCRIBED' ||
        fetchedUser.status === 'REGISTERED' ||
        fetchedUser.status === 'SUBSCRIBED'
      ) {
        fetchedUser.userStatus = fetchedUser.status
      }
      dispatch(authSuccess(fetchedUser))
    } catch (e) {
      removeStorageItem('auth')
      dispatch(authFailure({ cause: 'cannot.fetch.user' }))
    }
  }, [state.user.token])

  const fetchCredits = useCallback(async () => {
    const storageTokens = getSessionTokens()
    const token = storageTokens ? storageTokens.access_token : state.user.token
    try {
      const { credits } = await read('/p10/user/credits', token)
      if (credits && credits.length) {
        if (
          !state.user.credits ||
          !state.user.credits.length < credits.length
        ) {
          await fetchUser()
        } else {
          dispatch(updateCredits(credits))
        }
      }
    } catch (e) {
      // ignore
    }
  }, [state.user.token, state.user.credits, fetchUser])

  const authenticate = async ({ resend = false }) => {
    dispatch(authPending())
    const clickId = getClickId
    const { user } = state
    const { shortCode, phone, countryCode, operators, utm: userUtm } = user
    const urlUtm = getUtm(search)

    if (!shortCode) {
      const url = `/p10/public/get_started${resend ? '?reset=true' : ''}`
      try {
        const res = await create(url, {
          phone,
          countryCode,
          language,
          utm: userUtm || urlUtm,
          clickId,
        })
        if (res.externalConsentLink) {
          window.location.href = res.externalConsentLink
        } else {
          dispatch(
            authSuccess({
              phone,
              countryCode,
              sms: true,
              operators,
              ...res,
            })
          )
        }
        dispatch(
          gaEvent({
            gaEvent: 'Enter_MSISDN',
            eventCategory: 'Sub_Flow',
            eventLabel: 'Correct_MSISDN',
            utm: userUtm || urlUtm,
          })
        )
      } catch (e) {
        dispatch(authFailure(e.response || e))
        dispatch(
          gaEvent({
            gaEvent: 'Attempt_MSISDN',
            eventCategory: 'Sub_Flow',
            eventLabel: 'Wrong_MSISDN',
          })
        )
      }
    } else {
      try {
        const url = alternativeLoginFlow
          ? '/p10/public/login'
          : '/auth/public/signup/sms/validate'

        const body = alternativeLoginFlow
          ? {
              phone,
              countryCode,
              validationCode: shortCode,
            }
          : {
              phone,
              countryCode,
              shortCode,
            }
        const tokens = await create(url, body)
        setStorageItem('auth', JSON.stringify(tokens))
        const authUser = getTokenValues(tokens)
        dispatch(authSuccess(authUser))
        dispatch(gaEvent({ gaEvent: 'LOGIN', eventLabel: '' }))
        dataLayerPush(dataLayerObjectLogin)
      } catch (e) {
        removeStorageItem('auth')
        const err = { error: { key: 'invalid.pin' } }
        dispatch(authFailure(err))
      }
    }
  }

  const offlineSubscribe = async () => {
    const { user } = state
    const { phone, utm: userUtm } = user
    let operatorId, urlUtm

    if (user.operators) {
      const countryCode = Object.keys(user.operators)[0]
      const operator = user.operators[countryCode]
      operatorId = operator.length ? operator[0].id : undefined
      urlUtm = getUtm(search)
    }

    try {
      dispatch(subscriptionPending())
      const start = await create('/p10/public/get_started', {
        phone,
        countryCode,
        language,
        utm: userUtm || urlUtm,
      })
      if (start.userStatus === 'SUBSCRIBED') {
        dispatch(
          authSuccess({
            phone,
            countryCode,
            sms: true,
            operators,
            ...start,
          })
        )
      } else {
        window.location.href = start.externalConsentLink
      }
    } catch (e) {
      dispatch(subscriptionError(e.response || e))
    }
  }

  const subscribe = async () => {
    const clickId = getClickId
    const { user } = state
    const {
      phone,
      countryCode,
      operatorId,
      validationCode,
      utm: userUtm,
      antifraudUrl,
    } = user
    const urlUtm = getUtm(search)
    const filteredAntifraudUrl = antifraudUrl?.find(
      au => au.operatorId === operatorId
    )?.url
    const antiFraudParams = new URLSearchParams(filteredAntifraudUrl)
    const ti = antiFraudParams.get('ti')
    const ts = Number(antiFraudParams.get('ts'))

    if (!validationCode) {
      try {
        dispatch(subscriptionPending())
        const res = await create('/p10/public/subscription', {
          phone,
          countryCode,
          operatorId,
          language,
          sessionToken: externals?.token,
          utm: userUtm || urlUtm,
        })
        const subscriptionUser = R.merge({ subscription: true }, res)
        dispatch(
          authSuccess({
            ...subscriptionUser,
            userStatus: undefined,
          })
        )
        dispatch(
          gaEvent({
            gaEvent: 'Request_PIN',
            eventCategory: 'Sub_Flow',
            eventLabel: 'Request_PIN',
          })
        )
      } catch (e) {
        dispatch(subscriptionError(e.response || e))
        // dispatch(authFailure(e.response || e))
      }
    } else {
      try {
        const { token, validated } = await create(
          '/p10/public/subscription/validate',
          !termsCheckEnabledSubOverlay
            ? {
                phone,
                countryCode,
                validationCode,
                utm: userUtm || urlUtm,
                clickId,
                antifraud: { ti, ts },
                acceptedTC: termsStatus,
              }
            : {
                phone,
                countryCode,
                validationCode,
                utm: userUtm || urlUtm,
                clickId,
                antifraud: { ti, ts },
              }
        )
        let authSubscriptionUser = {}
        if (token) {
          setStorageItem('auth', JSON.stringify(token))
          authSubscriptionUser = {
            ...getTokenValues(token),
            userStatus: 'SUBSCRIBED',
            pinPrompted: true,
          }
        } else {
          authSubscriptionUser = validated
            ? { pinPrompted: true, userStatus: 'SUBSCRIBED' }
            : {}
        }
        fireAnalyticsEvent && fireAnalyticsEvent('subscription')
        fireAnalyticsEvent && fireAnalyticsEvent('signup')
        dispatch(authSuccess(authSubscriptionUser))
        dispatch(
          gaEvent({
            gaEvent: 'Subscription',
            eventCategory: 'Sub_Flow',
            eventLabel: 'Correct_Pin_Subscribe',
            userStatus: 'SUBSCRIBED',
            dataLayerEvent: dataLayerEvent,
          })
        )
        dataLayerPush(dataLayerObjectSub)
        if (!hasWelcomeBeenDisplayed && !hideWelcomePage) {
          setStorageItem('welcomevisited', true)
          history.push({ pathname: '/welcome', search: location.search })
        }
      } catch (e) {
        removeStorageItem('auth')
        dispatch(authFailure({ error: { key: 'invalid.pin' } }))
        dispatch(
          gaEvent({
            gaEvent: 'Attempt_PIN',
            eventCategory: 'Sub_Flow',
            eventLabel: 'Wrong_Pin',
          })
        )
      }
    }
  }

  const refreshToken = useCallback(
    async ({ urlToken, fromUnique = false } = {}) => {
      try {
        const storageTokens = getSessionTokens()
        const tokens = await tokenXhr('/auth/oauth/token', null, {
          scope: 'ui',
          grant_type: 'refresh_token',
          refresh_token:
            urlToken || state.user.refreshToken || storageTokens.refresh_token,
        })
        setStorageItem('auth', JSON.stringify(tokens))
        const authUser = getTokenValues(tokens)

        dispatch(authSuccess(authUser))
        urlToken &&
          dispatch(
            gaEvent({ gaEvent: 'LOGIN', eventCategory: 'USER', eventLabel: '' })
          )

        const getNewSub = hasCookie('x-new-sub')
        // Lifecell NEWSUB event
        if (fromUnique && urlToken && getNewSub === true) {
          // adds dataLayer event when new Subscriber logs in
          // removes cookie responsible for initiating the new Subscribe event
          dataLayerPush(dataLayerObjectNewSubExternal)
          document.cookie =
            'x-new-sub=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
        }
      } catch (e) {
        removeStorageItem('auth')
        if (fromUnique && urlToken) {
          const search = qs.parse(location.search)
          // eslint-disable-next-line no-unused-vars
          const { refresh_token, ...clearedSearch } = search ?? {}
          history.push({
            pathname: '/home',
            search: qs.stringify(clearedSearch),
          })
        } else {
          dispatch(authFailure(e.response || e))
        }
      }
    },
    [state.user.refreshToken, location.search, history]
  )
  //
  const buy = async credits => {
    const {
      user: { token, buyShortCode, skipCreditsValidationStep },
    } = state
    const isFinal = buyShortCode

    const url = isFinal ? '/p10/user/credits/validate' : '/p10/user/credits'
    try {
      dataLayerPush(dataLayerObjectPurchaseStart)
      dispatch(buyPending())
      const res = await update(url, { credits, buyShortCode, language }, token)

      if (!!res.externalConsentBuyLink) {
        window.location.href = res.externalConsentBuyLink
      } else {
        dispatch(buySuccess(res))
        dispatch(
          gaEvent({
            gaEvent:
              isFinal || skipCreditsValidationStep
                ? 'PURCHASE_CONFIRMED'
                : 'PURCHASE_PIN',
            eventCategory: 'USER',
            eventLabel: '',
          })
        )
      }
      if (isFinal || skipCreditsValidationStep) {
        dataLayerPush(dataLayerObjectPurchaseSuc)
        const returnTo = R.pathOr('/home', ['state', 'returnTo'], location)
        history.replace({ pathname: returnTo, search: location.search })
      }
    } catch (e) {
      dispatch(buyFailure(e.response || e))
      dataLayerPush(dataLayerObjectPurchaseFail)
    }
  }

  const resend = async action => {
    const {
      user: { phone, countryCode, operatorId, token },
    } = state

    let body
    let url
    let t = token

    switch (action) {
      case 'subscribe':
        body = { phone, countryCode, operatorId, language }
        url = '/p10/public/subscription/resend-pin'
        t = undefined
        break
      case 'login':
        body = { phone, countryCode, language }
        url = '/p10/public/get_started?reset=true'
        t = undefined
        break
      case 'buy':
        body = undefined
        url = '/p10/user/credits/resend-pin'
        break
      default:
        break
    }

    try {
      await create(url, body, t, { responseBody: false })
    } catch (e) {
      throw new Error()
    }
  }

  const cashout = amount => {
    const {
      user: { token },
    } = state
    return create('/p10/user/cashout', { amount }, token).then(fetchedUser => {
      !!fetchedUser && dispatch(authSuccess(fetchedUser))
    })
  }

  const changeId = nickname => {
    const {
      user: { id, token },
    } = state
    return update(`/p10/user/${id}`, { nickname: nickname }, token).then(
      fetchedUser => {
        !!fetchedUser && dispatch(authSuccess(fetchedUser), refreshToken())
      }
    )
  }

  const [setIntervalTime] = useInterval(refreshToken)

  useEffect(() => {
    const preAuth = async () => {
      const utm = getUtm(search)
      dispatch(preAuthPending())
      try {
        const { msisdn, ...rest } = await read('/p10/public/preauth')
        const operator = getOperator(rest)
        dispatch(
          preAuthSuccess({
            ...rest,
            phone: msisdn !== null ? msisdn : undefined,
            msisdn,
            countryCode: operator,
            utm,
          })
        )
      } catch (e) {
        dispatch(preAuthFailure(e.responce || e))
      }
    }
    const storageTokens = getSessionTokens()
    if (!storageTokens) {
      preAuth()
    }
  }, [search])

  useEffect(() => {
    const preAuth = async () => {
      const utm = getUtm(search)
      dispatch(preAuthPending())
      try {
        const res = await read('/p10/public/preauth')
        const operator = getOperator(res)
        dispatch(preAuthSuccess({ ...res, countryCode: operator, utm }))
      } catch (e) {
        dispatch(preAuthFailure(e.responce || e))
      }
    }
    if (state.user.token && !state.user.operators) {
      preAuth()
    }
  }, [state.user.token, state.user.operators, search])

  useEffect(() => {
    if (!state.user.token) {
      refreshToken({ urlToken, fromUnique: true })
    }
  }, [urlToken, state.user.token, refreshToken])

  useEffect(() => {
    if (!!state.user.token && !state.userError) {
      fetchUser()
      const t = secondsToMillis(state.user.tokenExpiresIn) - refreshThreshold
      setIntervalTime(t)
    } else {
      setIntervalTime(null)
    }
  }, [
    state.userError,
    state.user.token,
    state.user.tokenExpiresIn,
    fetchUser,
    setIntervalTime,
  ])

  useEffect(() => {
    if (!!state.user.id && canSelectGame && !!hasWelcomeBeenDisplayed) {
      history.push(`/select-game?lang=${language}`)
    }
    // eslint-disable-next-line
  }, [state.user.id, canSelectGame, history])

  const resumePopupParam = urlParams.get('resumePopup')
  useEffect(() => {
    if (!!resumePopupParam) {
      setResumePopup(true)
    }
  }, [resumePopupParam])
  const loginCookie = sessionStorage.getItem('login_popup')

  const auth = {
    ...state,
    onLogout: logout,
    fetchUser: fetchUser,
    fetchCredits: fetchCredits,
    onAuth:
      state?.user.singleStepSubscription && !state?.user.sms
        ? offlineSubscribe
        : authenticate,
    onSubscribe: subscribe,
    onBuy: buy,
    onCashout: cashout,
    onChangeUsername: changeId,
    onResendLogin: () => resend('login'),
    onResendBuy: () => resend('buy'),
    onResendSubscribe: () => resend('subscribe'),
    onChange: changedUser => dispatch(change(changedUser)),
    clearErrors: () => dispatch(clearErrors()),
    onHEConsent: user => dispatch(authSuccess(user)),
    onAntifraudLoaded: () => dispatch(antifraudLoaded()),
    pendingExternals: externals?.pending,
    resumePopup,
    loginCookie,
    handleTerms,
    termsStatus,
  }

  return (
    <AuthenticationContext.Provider value={{ auth }}>
      {state.initializing ? <LoadingOverlay /> : null}
      {children}
    </AuthenticationContext.Provider>
  )
}

export default withRouter(AuthContainer)
