import React, { useContext, useCallback, useState } from 'react'
import { css, cx } from 'emotion'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { I18nContext } from 'containers/I18nProvider'
import { getSessionTokens } from 'containers/utils'
import { create } from '@logicea/xhr-helpers/lib'
import { themeProp } from 'utils/theming'
import useMobileQuery from 'containers/MobileQuery'

const WonPrizeSelection = ({ gameId }) => {
  const {
    i18n: { t },
  } = useContext(I18nContext)
  const {
    theme,
    meta: { wonSelectionPrizes },
  } = useContext(ConfigurationContext)
  const { isMobile } = useMobileQuery()
  const [selected, setSelected] = useState(undefined)
  const sessionTokens = getSessionTokens()
  const token = sessionTokens ? sessionTokens.access_token : undefined

  const informPrizeSelection = useCallback(
    async num => {
      if (!num || !gameId) {
        return
      }
      try {
        await create(
          '/p10/game/winner/static-prize',
          { gameId, staticPrize: t(`won.selection.prizes.${num}`) },
          token
        )
        setSelected(num)
      } catch (e) {
        // TODO: show error?
      }
    },
    [gameId, token, t]
  )

  const handleSelection = useCallback(
    num => {
      informPrizeSelection(num)
    },
    [informPrizeSelection]
  )

  return (
    <div className={cls.container(isMobile)}>
      <h3 className={cls.header(theme)}>
        {selected
          ? t('won.selection.prizes.selected')
          : t('won.selection.prizes.select')}
      </h3>
      {!selected ? (
        new Array(wonSelectionPrizes).fill(1).map((_, idx) => (
          <div
            key={idx}
            className={cls.prize(theme, isMobile)}
            onClick={_ => handleSelection(idx + 1)}
          >
            <img
              alt={t(`won.selection.prizes.${idx + 1}`)}
              src={`/images/won-prizes/${idx + 1}.png`}
              onError={ev =>
                (ev.target.src =
                  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
              }
            />
            <div>{t(`won.selection.prizes.${idx + 1}`)}</div>
          </div>
        ))
      ) : (
        <div className={cx(cls.prize(theme), 'active')}>
          <img
            alt={t(`won.selection.prizes.${selected}`)}
            src={`/images/won-prizes/${selected}.png`}
            onError={ev =>
              (ev.target.src =
                'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
            }
          />
          <div>{t(`won.selection.prizes.${selected}`)}</div>
        </div>
      )}
    </div>
  )
}

const cls = {
  container: isMobile => css`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
  `,
  header: theme => css`
    font-size: 24px;
    color: ${themeProp(['wonSelection', 'headerColor'], theme)};
    margin: 0.5rem;
  `,
  prize: (theme, isMobile) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: -moz-available;
    width: -webkit-fill-available;
    border-radius: 6px;
    margin-top: 1rem;
    padding: 8px;
    background-color: ${themeProp(
      ['wonSelection', 'prizeBackgroundColor'],
      theme
    )};
    &:hover {
      cursor: pointer;
    }
    &.active {
      background-color: ${themeProp(
        ['wonSelection', 'activePrizeBackgroundColor'],
        theme
      )};
    }
    > img {
      height: ${isMobile ? '60px' : '100px'};
      width: auto;
    }
    > div {
      color: ${themeProp(['wonSelection', 'prizeColor'], theme)};
    }
  `,
}

export default WonPrizeSelection
