import * as R from 'ramda'
import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'

export const isAuthenticated = user => R.prop('token', user)
export const IsAuthenticated = user => Component =>
  R.prop('token', user) ? Component : null

const _Private = ({ location, match, history, staticContext, ...props }) => {
  return R.path(['user', 'token'], props) ? (
    <div {...props} />
  ) : (
    <Redirect to={{ pathname: '/home', search: location.search }} />
  )
}

export const Private = withRouter(_Private)
