import * as Layouts from './Layouts'
import React, { useContext } from 'react'
import useMobileQuery from 'containers/MobileQuery'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { I18nContext } from 'containers/I18nProvider'

const ThankYou = ({ location, history }) => {
  const {
    i18n: { t, rtl },
  } = useContext(I18nContext)
  const { layout, theme } = useContext(ConfigurationContext)
  const { isMobile } = useMobileQuery()
  const device = isMobile ? layout.phone : layout.default
  const Layout = Layouts[device] || Layouts['DEFAULT']

  return (
    <Layout
      history={history}
      location={location}
      rtl={rtl}
      t={t}
      mobile={isMobile}
      theme={theme}
    />
  )
}

export default ThankYou
