import React, { useState, useEffect } from 'react'
import styled from 'react-emotion'

// const percentage = (value, max) =>
//   Number(value) > 0 ? (Number(value) / Number(max)) * 100 : 0
// const progressValue = (area, actual) => area * (actual / 100)

// max-height: ${({ area, actual }) => progressValue(area, actual)}px;
// background-color: ${({ theme, value, max }) => {
//   const lastSecs = value <= max - 5
//   return lastSecs
//     ? themeProp(['timer', 'fillColor'], theme)
//     : themeProp(['timer', 'lastSecsColor'], theme)
// }};
// transition: ${({ actual }) => (actual < 10 ? 'none' : 'all 1s linear')};
const Progress = styled('div')`
  width: 10px;
  height: 100%;
  max-height: ${({ beginning, area }) => (beginning ? '0' : area)}px;
  border-radius: 30px;
  margin: auto;
  background-color: ${({ lastSecs }) => {
    return lastSecs ? 'var(--last-secs-color)' : 'var(--fill-color)'
  }};
  transition: ${({ beginning, max }) =>
    beginning ? 'none' : `all ${max}s linear`};
`

const ProgressBar = ({
  // tick: { value, max, onReset, serverValue },
  tick: { value, onReset, max },
  position,
}) => {
  const [area, setArea] = useState(0)
  const lastSecs = value >= max - 5
  const beginning = value < 1
  // const correct = max - Number(serverValue)

  useEffect(() => {
    if (position) {
      // onReset(correct)
      onReset(0)
    }
  }, [position, onReset])
  // }, [position, correct, onReset])

  // useEffect(() => {
  //   value < correct - 1 && onReset(correct)
  // }, [value, max, correct, onReset])

  return (
    <div
      style={{ height: '100%' }}
      ref={elm => elm && setArea(elm.clientHeight)}
    >
      <Progress
        // actual={percentage(value, max)}
        // value={value}
        max={max}
        beginning={beginning}
        lastSecs={lastSecs}
        area={area || 0}
      />
    </div>
  )
}

export default ProgressBar
