import * as R from 'ramda'
import qs from 'query-string'
import React, { useContext, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { AuthenticationContext } from 'containers/AuthProvider'
import { css } from 'emotion'

const pixelStyles = css`
  display: none;
  position: fixed;
`

const getPage = R.replace('/', '')

const getSearchProp = prop => R.compose(R.toLower, R.propOr('', prop), qs.parse)

const applyDynamicProps = (phone, operator, userId, search, pixel) => {
  const { src, clickId } = pixel
  const withParams = R.replace(/{MSISDN}/, phone, src)
    ?.replace(/{OPERATOR}/, operator, src)
    .replace(/{USERID}/, userId, src)

  const dynamicValue = clickId ? getSearchProp(clickId)(search) : undefined
  const replaceValue =
    clickId && dynamicValue ? `${clickId}=${dynamicValue}` : ''
  return R.replace(/{CLICKID}/, replaceValue, withParams)
}

const renderPixels = (pixels, phone, operator, userId, search) =>
  pixels.map((p, idx) =>
    p?.pixelImg ? (
      <img
        key={idx}
        className={pixelStyles}
        alt={`pixel_${idx}`}
        height="1"
        width="1"
        src={applyDynamicProps(phone, operator, userId, search, p)}
      />
    ) : (
      <iframe
        className={pixelStyles}
        title={`pixel_${idx}`}
        key={idx}
        src={applyDynamicProps(phone, operator, userId, search, p)}
        scrolling="no"
        frameBorder="no"
        width={1}
        height={1}
        id={p?.iFrameId ? p?.iFrameId : ''}
      />
    )
  )

const Pixel = ({ location: { pathname, search } }) => {
  const {
    meta: { pixels = {} },
  } = useContext(ConfigurationContext)
  const {
    auth: {
      user: { phone, operatorId, id },
    },
  } = useContext(AuthenticationContext)
  const page = getPage(pathname)
  const country = getSearchProp('country')(search)
  const genericPixels = pixels[page] ? pixels[page].all : []
  const countryPixels =
    pixels[page] && country ? pixels[page][country] || [] : []
  const operator = operatorId
  const userId = id

  return (
    <Fragment>
      {!!genericPixels
        ? renderPixels(genericPixels, phone, operator, userId, search)
        : null}
      {!!countryPixels
        ? renderPixels(countryPixels, phone, operator, userId, search)
        : null}
    </Fragment>
  )
}

export default R.compose(withRouter)(Pixel)
