import * as Sound from 'react-howler'
import React, { useContext } from 'react'
import { css, cx } from 'react-emotion'
import ProgressBar from 'components/ProgressBar'
import FontAwesome from 'react-fontawesome'
import { ConfigurationContext } from 'containers/ConfigurationProvider'

const theme = {
  azercell: css`
    --timer-background-color: #fff;
    --last-secs-color: #f60000;
    --fill-color: #27279d;
  `,
  beeline: css`
    --timer-background-color: #ffc632;
    --last-secs-color: #f60000;
    --fill-color: #000;
  `,
  celcinwin: css`
    --timer-background-color: #fff;
    --last-secs-color: #f60000;
    --fill-color: #ff1acc;
  `,
  djezzy: css`
    --timer-background-color: #fff;
    --last-secs-color: #f60000;
    --fill-color: #ff3c66;
  `,
  flashwin: css`
    --timer-background-color: #fff;
    --last-secs-color: #f60000;
    --fill-color: rgb(43, 30, 135);
  `,
  indosat: css`
    --timer-background-color: #fff;
    --last-secs-color: #f60000;
    --fill-color: #f7941d;
  `,
  libyana: css`
    --timer-background-color: #fff;
    --last-secs-color: #f60000;
    --fill-color: rgb(43, 30, 135);
  `,
  lifecell: css`
    --timer-background-color: #fff;
    --last-secs-color: #f60000;
    --fill-color: #27279d;
  `,
  mtnNga: css`
    --timer-background-color: #fff;
    --last-secs-color: #f60000;
    --fill-color: rgb(15, 105, 135);
  `,
  mtnZaf: css`
    --timer-background-color: #fff;
    --last-secs-color: #f60000;
    --fill-color: rgb(15, 105, 135);
  `,
  syriatel: css`
    --timer-background-color: #fff;
    --last-secs-color: #f60000;
    --fill-color: #dc0000;
  `,
  ucell: css`
    --timer-background-color: #fff;
    --last-secs-color: #f60000;
    --fill-color: #ee3e80;
  `,
  mtnSAMusic: css`
    --timer-background-color: #fff;
    --last-secs-color: #f60000;
    --fill-color: #ffcb06;
  `,
  stcbh: css`
    --timer-background-color: #fff;
    --last-secs-color: #a54ee1;
    --fill-color: #4f008c;
  `,
  gloNga: css`
    --timer-background-color: #fff;
    --last-secs-color: #f60000;
    --fill-color: #4bb44e;
  `,
  kcell: css`
    --timer-background-color: #fff;
    --last-secs-color: #a54ee1;
    --fill-color: #652d86;
  `,
}

const styles = {
  container: css`
    z-index: 2;
    width: 40px;
    margin-top: -20px;
    height: 90%;
    border-radius: 30px;
    padding: 40px 0 10px 0;
    transform: rotate(180deg);
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--timer-background-color);
  `,
}

const Timer = ({ tick, position }) => {
  const { value, max } = tick
  const started = value >= max - 5
  const { meta } = useContext(ConfigurationContext)
  const appliedTheme = theme[meta.installation]

  return (
    <div className={cx(appliedTheme, styles.container)}>
      <FontAwesome
        name="clock-o"
        style={{
          position: 'absolute',
          top: '6px',
          left: '6px',
          transform: 'rotate(180deg)',
          transition: 'color 1s linear',
          fontSize: '32px',
          color: started ? 'var(--last-secs-color)' : 'var(--fill-color)',
        }}
      />
      <ProgressBar tick={tick} position={position} />
      {!!meta.gameSound ? (
        <Sound src={meta.gameSound} playing={started} loop={true} />
      ) : null}
    </div>
  )
}

export default Timer
