import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { useContext } from 'react'

const Conditional = ({ condition, children }) => {
  const { meta } = useContext(ConfigurationContext)
  const hide = meta[condition] === false
  return hide ? null : children
}

export default Conditional
