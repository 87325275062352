import * as R from 'ramda'
import React, { useContext } from 'react'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'
import styled from 'react-emotion'
import { themeProp } from 'utils/theming'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { I18nContext } from 'containers/I18nProvider'

const LangDropDown = styled('select')`
  line-height: 45px;
  padding: 2px;
  margin: 0;
  background-color: ${({ theme }) =>
    themeProp(['langToggle', 'backgroundColor'], theme)};
  color: ${({ theme }) => themeProp(['langToggle', 'color'], theme)};
  border-radius: ${({ theme }) =>
    themeProp(['langToggle', 'borderRadius'], theme)};
  outline: none;
  border: ${({ theme }) =>
    `1px solid ${themeProp(['langToggle', 'color'], theme)}`};
`

const Lang = styled('option')`
  color: ${({ theme }) => themeProp(['langToggle', 'langColor'], theme)};
  border-radius: ${({ theme }) =>
    themeProp(['langToggle', 'borderRadius'], theme)};
  background-color: ${({ theme }) =>
    themeProp(['langToggle', 'backgroundColor'], theme)};
`

const onToggle = (location, history) => ev => {
  const search = qs.parse(location.search)
  const paramObj = R.omit(['lang'], search)
  const params = !R.isEmpty(paramObj) ? `&${qs.stringify(paramObj)}` : ''
  history.replace(`${location.pathname}?lang=${ev.target.value}${params}`)
}

const LangToggle = ({ location, history }) => {
  const {
    i18n: { t, lang },
  } = useContext(I18nContext)
  const { theme, meta } = useContext(ConfigurationContext)
  const { languages } = meta

  return (
    <div>
      {languages.length > 1 ? (
        <LangDropDown
          theme={theme}
          value={lang}
          onChange={onToggle(location, history)}
        >
          {R.map(
            l => (
              <Lang key={l.lang} theme={theme} value={l.lang}>
                {t(`lang.${l.lang}`)}
              </Lang>
            ),
            languages || []
          )}
        </LangDropDown>
      ) : null}
    </div>
  )
}

export default withRouter(LangToggle)
