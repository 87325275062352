import React, { useContext } from 'react'
import { css, cx } from 'react-emotion'
import { NavLink } from 'react-router-dom'
import useMobileQuery from 'containers/MobileQuery'

import { ConfigurationContext } from 'containers/ConfigurationProvider'

export const theme = {
  azercell: css`
    --button-background-color: #f35b0d;
    --button-color: #fff;
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Los_Andes, Roboto_Numbers, GE, Roboto;
  `,
  beeline: css`
    --button-background-color: #000;
    --button-color: #fff;
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Roboto_Numbers, GE, Roboto;
  `,
  celcinwin: css`
    --button-background-color: #00d9ff;
    --button-color: #fff;
    --button-disabled-opacity: 1;
    --button-disabled-background-color: #ccc;
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Lato;
  `,
  djezzy: css`
    --button-background-color: #ff3c66;
    --button-color: #fff;
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: 'Roihu-Regular', sans-serif;
  `,
  flashwin: css`
    --button-background-color: #ffc632;
    --button-color: rgb(43, 30, 135);
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Roboto_Numbers, GE, Roboto;
  `,
  indosat: css`
    --button-background-color: #ec1c24;
    --button-color: #fff;
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Roboto_Numbers, GE, Roboto;
  `,
  libyana: css`
    --button-background-color: rgb(123, 56, 128);
    --button-color: #fff;
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Roboto_Numbers, GE, Roboto;
  `,
  lifecell: css`
    --button-background-color: #0d4ba0;
    --button-color: #fff;
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Roboto_Numbers, GE, Roboto;
  `,
  mtnNga: css`
    --button-background-color: #000;
    --button-color: #fff;
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Roboto_Numbers, GE, Roboto;
  `,
  mtnZaf1: css`
    --button-background-color: #000;
    --button-color: #fff;
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Roboto_Numbers, GE, Roboto;
  `,
  syriatel: css`
    --button-background-color: #dc0000;
    --button-color: #fff;
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Roboto_Numbers, GE, Roboto;
  `,
  ucell: css`
    --button-background-color: #39ba95;
    --button-color: #fff;
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Roboto_Numbers, GE, Roboto;
  `,
  mtnSAMusic: css`
    --button-background-color: #000;
    --button-color: #fff;
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Roboto_Numbers, GE, Roboto;
  `,
  mtnZaf: css`
    --button-background-color: #ff375e;
    --button-color: #fff;
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Roboto_Numbers, GE, Roboto;
  `,
  stcbh: css`
    --button-background-color: #ff375e;
    --button-color: #fff;
    --button-border-radius: 0;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Roboto_Numbers, GE, Roboto;
  `,
  gloNga: css`
    --button-background-color: #4bb44e;
    --button-color: #fff;
    --button-border-radius: 100px;
    --button-border-color: none;
    --button-shadow: none;
    --button-font-size: 22px;
    --button-font-family: Roboto_Numbers, GE, Roboto;
  `,
  kcell: css`
    --button-background-color: #fff;
    --button-color: #652d86;
    --button-border-radius: 14px;
    --button-border-color: 1px solid #f1f1f1;
    --button-shadow: none;
    --button-font-size: 16px;
    --button-font-family: Roboto_Numbers, GE, Roboto;
  `,
}

export const styles = {
  button: (mobile, disabled) => css`
    font-family: var(--button-font-family);
    text-align: center;
    display: block;
    height: ${mobile ? '50px' : '65px'};
    line-height: ${mobile ? '50px' : '65px'};
    width: 100%;
    box-shadow: var(--button-shadow);
    border-radius: var(--button-border-radius);
    border: var(--button-border-color);
    margin: 12px auto;
    cursor: pointer;
    outline: none;
    font-size: var(--button-font-size);
    font-weight: bold;
    background: ${disabled
      ? `var(--button-disabled-background-color, var(--button-background-color))`
      : `var(--button-background-color)`};
    color: var(--button-color);
    &:hover,
    &:visited,
    &:active {
      color: var(--button-color);
    }
    opacity: ${disabled ? `var(--button-disabled-opacity, 0.4)` : 1};
    pointer-events: ${disabled ? 'none' : 'auto'};
    -webkit-appearance: none;
  `,
}

const _onClick = onClick => ev => {
  ev.preventDefault()
  onClick && onClick()
}

const Content = ({
  appliedTheme,
  text,
  to,
  clickHandler,
  disabled,
  mobile,
  ...rest
}) =>
  to ? (
    <NavLink
      to={to}
      className={cx(appliedTheme, styles.button(mobile, disabled))}
      disabled={disabled}
    >
      {text}
    </NavLink>
  ) : (
    <button
      className={cx(appliedTheme, styles.button(mobile, disabled))}
      onClick={_onClick(clickHandler)}
      {...rest}
    >
      {text}
    </button>
  )

const Button = ({ onClick, text, to, disabled, mobile, ...rest }) => {
  const { isMobile } = useMobileQuery()
  const { meta } = useContext(ConfigurationContext)
  const appliedTheme = theme[meta.installation]

  return (
    <Content
      appliedTheme={appliedTheme}
      text={text}
      to={to}
      disabled={disabled}
      clickHandler={onClick}
      mobile={isMobile}
      {...rest}
    />
  )
}

export default Button
