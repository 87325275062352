import 'react-circular-progressbar/dist/styles.css'
import React, {
  useContext,
  useCallback,
  useReducer,
  useState,
  useRef,
  useEffect,
} from 'react'
import useMobileQuery from 'containers/MobileQuery'
import * as Layouts from './layouts'
import { create } from '@logicea/xhr-helpers/lib'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { I18nContext } from 'containers/I18nProvider'
import useInterval from 'containers/IntervalProvider'
import { AuthenticationContext } from 'containers/AuthProvider'
import { getSessionTokens } from 'containers/utils'

const COUNTDOWN = 5

const INITIAL_STATE = {
  loading: false,
  pickedSlot: undefined,
  selectedPrize: undefined,
  errors: undefined,
}

const prizePending = () => ({ type: 'PRIZE_PENDING' })
const prizeSuccess = payload => ({ type: 'PRIZE_SUCCESS', payload })
const prizeFailure = payload => ({ type: 'PRIZE_FAILURE', payload })

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'PRIZE_PENDING':
      return {
        ...state,
        loading: true,
        selectedPrize: undefined,
        pickedSlot: undefined,
      }
    case 'PRIZE_FAILURE':
      return { ...state, loading: false, errors: payload }
    case 'PRIZE_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: undefined,
        selectedPrize: payload.prize,
        pickedSlot: payload.pickedSlot,
      }
    default:
      throw new Error()
  }
}

const SelectGamePrize = ({ history, location }) => {
  const {
    i18n: { t, rtl, lang },
  } = useContext(I18nContext)
  const { auth } = useContext(AuthenticationContext)
  const {
    theme,
    layout,
    meta: { gamePrizes },
  } = useContext(ConfigurationContext)
  const sessionTokens = getSessionTokens()
  const token = sessionTokens ? sessionTokens.access_token : undefined
  const { isMobile } = useMobileQuery()
  const [counter, setCounter] = useState(COUNTDOWN)
  const [setIntervalTime] = useInterval(() => setCounter(c => c - 1))
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  let valid = useRef()

  const choosePrize = useCallback(
    slot => async _evt => {
      if (state.pickedSlot) {
        return
      }
      try {
        !!valid.current && dispatch(prizePending())
        const prize = await create('/p10/selectPrize', { slot }, token)
        !!valid.current && dispatch(prizeSuccess({ prize, pickedSlot: slot }))
      } catch (e) {
        !!valid.current && dispatch(prizeFailure(e))
      }
    },
    [dispatch, token, state.pickedSlot]
  )

  useEffect(() => {
    if (counter === 0) {
      setIntervalTime(null)
      history.replace({ pathname: '/game', search: location.search })
    } else if (!!state.pickedSlot) {
      setIntervalTime(1000)
    }
    return () => setIntervalTime(null)
  }, [state.pickedSlot, counter, setIntervalTime, history, location.search])

  useEffect(() => {
    valid.current = true
    return () => {
      valid.current = false
    }
  }, [])

  const props = {
    state,
    choosePrize,
    theme,
    t,
    rtl,
    lang,
    auth,
    gamePrizes,
    counter,
    location,
    ...(isMobile ? { mobile: 'true' } : {}),
  }

  const device = isMobile ? layout.phone : layout.default
  const Layout = Layouts[device] || Layouts['DEFAULT']
  return <Layout {...props} />
}

export default SelectGamePrize
