import * as R from 'ramda'
import React, { useContext, useEffect, useState, useCallback } from 'react'
import Overlay from 'components/Overlay'
import Button, {
  styles as buttonStyles,
  theme as buttonTheme,
} from 'components/Button'
import InputText from 'components/InputText'
import SelectText from 'components/SelectText'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import styled, { css, cx } from 'react-emotion'
import { Spring } from 'react-spring'
import { Redirect } from 'react-router-dom'
import { ResendLink } from 'components/ActionForm'
import { themeProp } from 'utils/theming'
import { zoomIn as animation } from 'animations'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { AuthenticationContext } from 'containers/AuthProvider'
import { __RouterContext } from 'react-router'
import { I18nContext } from 'containers/I18nProvider'
import { useDataLayerEvents } from 'containers/dataLayerEvents'
import TermsCheckBox from 'components/TermsCheckBox'
import Conditional from 'containers/Conditional'

const containerClass = (mobile, theme) => css`
  position: absolute;
  z-index: 20;
  width: 100vw;
  height: 100dvh;
  overflow: ${mobile ? 'auto' : 'initial'};
  color: ${themeProp(['modal', 'color'], theme)};
  background: ${themeProp(['modal', 'backgroundColor'], theme)};
  background-size: cover;
  > div:nth-child(1) {
    padding: ${mobile ? '10px' : '0'};
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`

const content = css`
  height: 100%;
  width: 100%;
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
`

const smsContainer = css`
  display: flex;
  flex-direction: row;
  margin: 5px 0 0;
  font-size: smaller;
  font-weight: lighter;

  a {
    margin: 0 0.25em;
    text-decoration: underline !important;
  }
`
const subInfoHeader = css`
  margin: 0 0 14px;
  font-size: 15px;
  font-weight: bold;
`
const checkBoxContainer = css`
  margin: 15px 10px 5px;
`
const Header = styled('h5')`
  margin: ${({ mobile }) => (mobile ? '30px 0' : '40px 0')};
  font-size: ${({ mobile }) => (mobile ? '16px' : '18px')};
  font-weight: normal;
`

const inputs = rtl => css`
  text-align: ${rtl ? 'right' : 'left'};
  display: flex;
  flex-direction: column;
`

const operatorLabel = rtl => css`
  text-align: center;
  margin: 0 0 8px 0;
`

const operatorClass = css`
  text-align: center;
`

const OperatorSubLabel = styled('small')`
  font-size: 12px;
  max-width: 280px;
  text-align: center;
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
`

const ButtonContainer = styled('div')`
  margin: 20px 0;
  width: 210px;
`

const backLink = css`
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`
const handleChange = (prop, user, onChange) => ev =>
  onChange(R.assoc(prop, ev.target.value, user))

const onClose = (user, onChange) => ev => {
  ev && ev.preventDefault()
  onChange(R.assoc('sms', false, user))
}

const getOperatorOptions = (operators, countryCode, t) => {
  const operatorOptions =
    operators && operators[countryCode] && operators[countryCode].length > 1
      ? [{ value: '', label: [t('subscription.placeholder')] }]
      : []
  operators &&
    operators[countryCode] &&
    operators[countryCode].map(o =>
      operatorOptions.push({
        value: o.id,
        label: o.name,
        subscriptionCost: o.subscriptionCost,
      })
    )
  return operatorOptions
}

const getSubscriptionOperator = (user = {}) => {
  const { operators, operatorId, countryCode } = user
  const countryOperators =
    operators && countryCode ? operators[countryCode.toUpperCase()] : []
  const operator = R.find(R.propEq('id', operatorId), countryOperators)
  return operator
}

const getVatMessage = (lang, { countryCode, operatorId, operators }) => {
  const cc = countryCode ? countryCode.toUpperCase() : undefined
  return R.compose(
    R.path(['vatMessage', 'strings', lang]),
    R.find(R.propEq('id', operatorId)),
    R.pathOr([], [cc])
  )(operators)
}

const SubscriptionOverlay = ({ mobile }) => {
  const {
    i18n: { t, rtl, lang },
  } = useContext(I18nContext)

  const [waitForPin, setWaitForPin] = useState(false)
  const { auth } = useContext(AuthenticationContext)
  const { theme, meta } = useContext(ConfigurationContext)
  const { subscribeSmsMessage, defaultLang, termsCheckEnabledSubOverlay } = meta
  const { location } = useContext(__RouterContext)
  const user = auth ? auth.user : {}
  const {
    phone,
    subscription,
    validationRequired,
    operators,
    countryCode,
    operatorId,
    validationCode,
  } = user
  const {
    errors,
    pendingSubscription,
    onSubscribe,
    onResendSubscribe,
    onChange,
    pendingExternals,
    handleTerms,
    termsStatus,
  } = auth
  const appliedButtonTheme = buttonTheme[meta.installation]
  const operatorOptions = countryCode
    ? getOperatorOptions(operators, countryCode.toUpperCase(), t)
    : []

  const pinOnTheWay = useCallback(() => {
    setWaitForPin(true)
    setTimeout(() => setWaitForPin(false), 15000)
  }, [setWaitForPin])

  const handleResendSubscribe = useCallback(
    evt => {
      evt && evt.preventDefault()
      onResendSubscribe()
      pinOnTheWay()
    },
    [onResendSubscribe, pinOnTheWay]
  )
  const { dataLayerPush } = useDataLayerEvents()
  const preAuthUserId = !!user?.phone ? user?.phone : ''
  const userType =
    user?.userStatus === 'SUBSCRIBED' ? 'subscribed' : 'anonymous'
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const dataLayerObject = {
    event: 'getPin_click',
    screenName: '/chooseOperator',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: preAuthUserId,
    userType: userType,
  }

  const dataLayerObject2 = {
    event: 'Subscription_OTP_Entry_click',
    screenName: '/enterOtp',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: preAuthUserId,
    userType: userType,
  }

  useEffect(() => {
    if (!operatorId && operatorOptions && operatorOptions.length === 1) {
      onChange(R.assoc('operatorId', operatorOptions[0].value, user))
    }
  }, [operatorId, operatorOptions, user, onChange])

  if (subscription && !validationRequired) {
    return <Redirect to={{ pathname: '/home', search: location.search }} />
  }

  const subscriptionDisabled =
    !countryCode || countryCode === '' || !operatorId || operatorId === ''

  const subOperator = getSubscriptionOperator(user)
  const onlineSubscription = subOperator ? subOperator.onlineSubscription : true
  const vatMessage = getVatMessage(lang, user)
  const subInfo = R.path(['subInfoHeader', 'strings', lang], subOperator)
  const subKeyword = R.path(['subKeyword', 'strings', lang], subOperator)
  const unsubKeyword = R.path(['unsubKeyword', 'strings', lang], subOperator)
  const subButtonText = R.path(['subButtonText', 'strings', lang], subOperator)
  const subInfoDetails = R.path(
    ['subInfoDetails', 'strings', lang],
    subOperator
  )
  const errorLabel = errors ? t('generic.error') : undefined

  const checkTerms = termsCheckEnabledSubOverlay ? !termsStatus : false
  const checkValidationCodeLength = validationCode?.length < 4

  const mobileDevice = /Mobile/i.test(navigator.userAgent)
  const selectedOperator = R.propOr('', 'operatorId', user)

  const handleSubscribe = () => ev => {
    ev && ev.preventDefault()
    dataLayerPush(!subscription ? dataLayerObject : dataLayerObject2)
    onSubscribe()
  }

  return (
    <BackgroundImageWrapper
      className={containerClass(mobile, theme)}
      imgsrc={themeProp(['modal', 'backgroundImage'], theme)}
    >
      <Overlay rtl={rtl} location={location} onClose={onClose(user, onChange)}>
        <Spring from={animation['atEnter']} to={animation['atActive']}>
          {props =>
            !subscription ? (
              <div className={content} key="content" style={props}>
                {t('subscription.online.message') !== ' ' ? (
                  <Header mobile={mobile}>
                    {t('subscription.online.message')}
                  </Header>
                ) : null}
                {t('subscription.online.note') !== ' ' ? (
                  <p>{t('subscription.online.note')}</p>
                ) : null}
                <div className={inputs(rtl)}>
                  <label className={operatorLabel(rtl)}>
                    {t('subscription.operator.title')}
                  </label>
                  {operatorOptions.length > 1 ? (
                    <SelectText
                      theme={theme}
                      errors={errors}
                      name="operatorId"
                      value={selectedOperator}
                      options={operatorOptions}
                      onChange={handleChange('operatorId', user, onChange)}
                    />
                  ) : (
                    <>
                      <span className={operatorClass}>
                        {R.compose(R.prop('label'), R.head)(operatorOptions)}
                      </span>
                      {errorLabel ? (
                        <div
                          className={css`
                            text-align: center;
                            color: coral;
                            font-weight: 600;
                          `}
                        >
                          {errorLabel}
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
                {!onlineSubscription ? (
                  <div className={content} key="content">
                    <Header mobile={mobile}>
                      {t('subscription.offline.message')}
                    </Header>
                    <span>{t('subscription.offline.note')} </span>
                    <span>
                      <b>{subKeyword ?? ''}</b>{' '}
                      {t('subscription.offline.note.subtext')}{' '}
                      <b>{subOperator?.shortCode ?? ''}</b>
                    </span>
                    {mobileDevice && subscribeSmsMessage ? (
                      <div className={smsContainer}>
                        <div>{t('subscription.online.sms.message.a')}</div>
                        <a
                          href={`sms:${subOperator?.shortCode}?&body=${subKeyword}`}
                        >
                          {t('subscription.online.sms.hyperlink')}
                        </a>
                        <div>
                          {t('subscription.online.sms.message.b')
                            .replace('{k}', subOperator?.shortCode ?? '')
                            .replace('{c}', subKeyword ?? '')}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <ButtonContainer>
                  {onlineSubscription ? (
                    <Button
                      data-e2e="get-pin-btn"
                      disabled={
                        subscriptionDisabled ||
                        pendingSubscription ||
                        pendingExternals
                      }
                      text={t('subscription.button.subscribe')}
                      theme={theme}
                      onClick={handleSubscribe()}
                    />
                  ) : (
                    <>
                      <span>{t('subscription.operator.subtext')}</span>
                      <a
                        className={cx(
                          appliedButtonTheme,
                          buttonStyles.button(mobile)
                        )}
                        href={`sms:${subOperator?.shortCode}?&body=${subKeyword}`}
                      >
                        {t('subscription.online.sms.hyperlink')}
                      </a>
                      <div
                        className={backLink}
                        onClick={onClose(user, onChange)}
                      >
                        {t('subscription.back')}
                      </div>
                    </>
                  )}
                </ButtonContainer>
                {!!operatorId ? (
                  <OperatorSubLabel rtl={rtl}>
                    <div className={subInfoHeader}>{subInfo ?? ''}</div>
                    <bdi>
                      {t('subscription.online.subnote.a').replace(
                        '{c}',
                        subOperator?.subscriptionCost ?? ''
                      )}
                    </bdi>
                    <div>
                      <span>
                        {t('subscription.online.subnote.b').replace(
                          '{k}',
                          unsubKeyword ?? ''
                        )}
                      </span>
                      <span>{subOperator?.shortCode ?? ''}</span>
                    </div>
                    <div>{vatMessage}</div>
                    <div>{subInfoDetails}</div>
                  </OperatorSubLabel>
                ) : null}
              </div>
            ) : (
              <form
                data-e2e="subscribe-form"
                className={content}
                key="content"
                onSubmit={handleSubscribe(onSubscribe)}
                style={props}
              >
                <Header mobile={mobile}>
                  {t('subscription.code')}
                  <span dir="ltr"> {phone}</span>
                </Header>
                <InputText
                  type="number"
                  theme={theme}
                  errors={errors}
                  name="validationCode"
                  placeholder={t('subscription.code.placeholder')}
                  value={R.propOr('', 'validationCode', user)}
                  onChange={handleChange('validationCode', user, onChange)}
                  t={t}
                />
                {waitForPin ? (
                  <small
                    className={css`
                      margin: 10px 0 0;
                      cursor: pointer;
                      text-align: center;
                    `}
                  >
                    {t('subscription.wait.pin')}
                  </small>
                ) : (
                  <Conditional condition="termsCheckEnabledSubOverlay">
                    <div className={checkBoxContainer}>
                      <TermsCheckBox
                        mobile={mobile}
                        onChangeEvent={handleTerms}
                      />
                    </div>
                    <ResendLink rtl={rtl} onClick={handleResendSubscribe}>
                      {t('login.resend.pin')}
                    </ResendLink>
                    {mobileDevice && subscribeSmsMessage ? (
                      <div className={smsContainer}>
                        <div>{t('subscription.online.sms.message.a')}</div>
                        <a
                          href={`sms:${subOperator?.shortCode}?&body=${subKeyword}`}
                        >
                          {t('subscription.online.sms.hyperlink')}
                        </a>
                        <div>
                          {t('subscription.online.sms.message.b')
                            .replace('{k}', subOperator?.shortCode ?? '')
                            .replace('{c}', subKeyword ?? '')}
                        </div>
                      </div>
                    ) : null}
                  </Conditional>
                )}
                <ButtonContainer>
                  <Button
                    id="subBtnId"
                    text={subButtonText ?? t('subscription.button.validate')}
                    theme={theme}
                    disabled={
                      !validationCode || checkValidationCodeLength || checkTerms
                    }
                    onClick={handleSubscribe()}
                  />
                </ButtonContainer>
                {!!operatorId ? (
                  <OperatorSubLabel rtl={rtl}>
                    <div className={subInfoHeader}>{subInfo ?? ''}</div>
                    <bdi>
                      {t('subscription.online.subnote.a').replace(
                        '{c}',
                        subOperator?.subscriptionCost ?? ''
                      )}
                    </bdi>
                    <div>
                      <span>
                        {t('subscription.online.subnote.b').replace(
                          '{k}',
                          unsubKeyword ?? ''
                        )}
                      </span>
                      <span>{subOperator?.shortCode ?? ''}</span>
                    </div>
                    <div>{vatMessage}</div>
                    <div>{subInfoDetails}</div>
                  </OperatorSubLabel>
                ) : null}
              </form>
            )
          }
        </Spring>
      </Overlay>
    </BackgroundImageWrapper>
  )
}

export default SubscriptionOverlay
