import 'react-circular-progressbar/dist/styles.css'
import React, { useContext } from 'react'
import useMobileQuery from 'containers/MobileQuery'
import * as Layouts from './layouts'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { I18nContext } from 'containers/I18nProvider'
import { AuthenticationContext } from 'containers/AuthProvider'

const SelectGame = ({ history, location }) => {
  const {
    i18n: { t, rtl, lang },
  } = useContext(I18nContext)

  const { auth } = useContext(AuthenticationContext)
  const { user } = auth
  const { canSelectGame } = user
  const {
    theme,
    layout,
    links: { footerlinks },
  } = useContext(ConfigurationContext)
  const { isMobile } = useMobileQuery()
  !canSelectGame && history.push('/home')

  const props = {
    location,
    theme,
    t,
    rtl,
    lang,
    auth,
    user,
    footerlinks,
    ...(isMobile ? { mobile: 'true' } : {}),
  }

  const device = isMobile ? layout.phone : layout.default
  const Layout = Layouts[device] || Layouts['DEFAULT']
  return <Layout {...props} />
}

export default SelectGame
