import React, {
  Fragment,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import qs from 'query-string'
import { I18nContext } from 'containers/I18nProvider'
import { setStorageItem } from 'containers/utils'
import { getTokenValues, getUtm } from 'containers/utils/authProvider'
import { create } from '@logicea/xhr-helpers/lib'
import Blocking from 'components/Blocking'

const DjezzyImpl = ({ auth, location }) => {
  const {
    i18n: { lang },
  } = useContext(I18nContext)
  const search = useMemo(() => qs.parse(location.search) || {}, [
    location.search,
  ])
  const utm = getUtm(search)
  const { refresh_token: urlToken } = search
  const { user, onHEConsent } = auth
  const { headerEnrichment, token, subscribed } = user
  const shouldLogin = !token && !urlToken && headerEnrichment && subscribed
  const shouldBlock = !headerEnrichment && !urlToken

  const consent = useCallback(async () => {
    try {
      const tokens = await create('/p10/public/consent/validate', {
        utm,
        language: lang,
      })
      setStorageItem('auth', JSON.stringify(tokens))
      const authUser = getTokenValues(tokens)
      onHEConsent(authUser)
    } catch (e) {
      // do nothing
    }
  }, [onHEConsent, utm, lang])

  useEffect(() => {
    if (shouldLogin) {
      consent()
    }
  }, [shouldLogin, consent])

  return shouldBlock ? <Blocking /> : <Fragment />
}

export default DjezzyImpl
