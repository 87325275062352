import React, { useContext, useState, useMemo } from 'react'
import { css } from 'react-emotion'
import { I18nContext } from 'containers/I18nProvider'
import useMobileQuery from 'containers/MobileQuery'
import { themeProp } from 'utils/theming'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import usePopup from 'containers/PopupProvider'
import { create } from '@logicea/xhr-helpers/lib'
import { AuthenticationContext } from 'containers/AuthProvider'
import { isAuthenticated } from 'lib/auth-utils'
import { __RouterContext } from 'react-router'

const visibleRoutes = ['/home', '/result']

const cls = {
  backdrop: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
  `,
  container: (theme, isMobile) => css`
    position: relative;
    width: ${isMobile ? '100%' : '50%'};
    height: ${isMobile ? 'height: calc(100dvh - 40px)' : 'auto'};
    max-width: 500px;
    min-height: 350px;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    background-size: cover;
    background-color: ${themeProp(['popup', 'backgroundColor'], theme)};
  `,
  image: css`
    max-width: 160px;
    max-height: 120px;
  `,
  title: theme => css`
    font-size: 18px;
    font-weight: bold;
    margin: 30px 0 20px;
    color: ${themeProp(['popup', 'color1'], theme)};
  `,
  description: theme => css`
    font-size: 15px;
    color: ${themeProp(['popup', 'color2'], theme)};
  `,
  closeContainer: rtl => css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 20px;
    right: ${rtl ? 'auto' : '20px'};
    left: ${rtl ? '20px' : 'auto'};
    width: 30px;
    height: 30px;
    font-size: 30px;
    color: black;
    &:hover {
      color: black;
    }
    cursor: pointer;
  `,
  closeImg: (theme, isMobile) => css`
    display: inline-block;
    width: ${isMobile ? '12px' : '20px'};
    height: ${isMobile ? '12px' : '20px'};
    background: url(${themeProp(['popup', 'closeImage'], theme)});
    background-size: contain;
    background-position: center;
  `,
}

const PopupContent = () => {
  const { auth } = useContext(AuthenticationContext)
  const {
    i18n: { rtl, lang },
  } = useContext(I18nContext)
  const {
    resumePopup,
    loginCookie,
    user: { canSelectGame, token = '', enablePopups },
  } = auth
  const user = auth ? auth.user : null
  const { isMobile } = useMobileQuery()
  const { theme } = useContext(ConfigurationContext)
  const { location } = useContext(__RouterContext)
  const userLoggedInHome = Boolean(
    location.pathname === '/home' && isAuthenticated(user)
  )
  const categoryCheck = userLoggedInHome
    ? 'LOGIN'
    : location.pathname === '/result'
    ? 'GAMEPLAY'
    : null
  const [popupData] = usePopup(categoryCheck)
  const [display, setDisplay] = useState(true)
  const dataCheck =
    Boolean(popupData?.title) &&
    Boolean(popupData?.body) &&
    Boolean(popupData?.imagePath)
  const loggedInFirstTimeCheck = canSelectGame
    ? userLoggedInHome && !loginCookie && resumePopup
    : userLoggedInHome && !loginCookie
  const popupId = popupData?.id

  const handleDisplayLimit = async () => {
    if (!!categoryCheck) {
      if (loggedInFirstTimeCheck || location.pathname === '/result') {
        try {
          await create(`/p10/user/popup/${popupId}`, undefined, token)
        } catch (e) {
          //no error message returns at the moment
          console.log('Invalid Popup ID')
        }
      }
    }
  }

  useMemo(() => {
    handleDisplayLimit()
  }, [location.pathname, popupId])

  if (!enablePopups) return null
  if (!dataCheck) return null
  if (!visibleRoutes.includes(location.pathname)) return null
  if (!resumePopup && canSelectGame && userLoggedInHome) return null
  if (loginCookie && userLoggedInHome) return null
  if (!display) return null

  const handleClose = () => {
    setDisplay(false)
    if (loggedInFirstTimeCheck) {
      sessionStorage.setItem('login_popup', true)
    }
  }

  return (
    <div className={cls.backdrop}>
      <div className={cls.container(theme, isMobile)}>
        <div>
          <img className={cls.image} src={popupData?.imagePath} alt="popup" />
          <div className={cls.title(theme)}>
            {popupData?.title?.strings?.[lang]}
          </div>
          <div className={cls.description(theme)}>
            {popupData?.body?.strings?.[lang]}
          </div>
        </div>
        <div className={cls.closeContainer(rtl)} onClick={handleClose}>
          <div className={cls.closeImg(theme, isMobile)}></div>
        </div>
      </div>
    </div>
  )
}

export default PopupContent
