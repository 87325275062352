export const slideFromRight = {
  atEnter: { transform: `translate(500px, 0)` },
  atActive: { transform: `translate(0, 0)` },
}

export const slideFromLeft = {
  atEnter: { transform: `translate(-500px, 0)` },
  atActive: { transform: `translate(0, 0)` },
}

export const slideFromTop = {
  atEnter: {
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    zIndex: 99,
    transform: `translate(0, -100px)`,
  },
  atActive: {
    position: 'absolute',
    opacity: 1,
    top: 0,
    left: 0,
    zIndex: 99,
    transform: `translate(0, 0)`,
  },
}

export const drawerLeft = {
  atEnter: {
    position: 'absolute',
    zIndex: 99,
    transform: `translate(-50px, 0)`,
  },
  atActive: { position: 'absolute', zIndex: 99, transform: `translate(0, 0)` },
}

export const drawerRight = {
  atEnter: {
    position: 'absolute',
    right: 0,
    zIndex: 99,
    transform: `translate(50px, 0)`,
  },
  atActive: {
    position: 'absolute',
    right: 0,
    zIndex: 99,
    transform: `translate(0, 0)`,
  },
}

export const zoomIn = {
  atEnter: { transform: 'scale(0.8)' },
  atActive: { transform: 'scale(1)' },
}

export const pushFromTop = {
  atEnter: { opacity: 0, transform: `translate(0, -80px) scale(0.9)` },
  atActive: { opacity: 1, transform: `translate(0, 0) scale(1.04)` },
}
