import * as Layouts from './Layouts'
import React, { useContext, useState, useCallback } from 'react'
import useMobileQuery from 'containers/MobileQuery'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { I18nContext } from 'containers/I18nProvider'
import { useLocation } from 'react-router-dom'

const getNotificationReadStatus = id =>
  !!localStorage.getItem(`notification_${id}`)
const setNotificationReadStatus = id =>
  localStorage.setItem(`notification_${id}`, true)

const Notification = () => {
  const location = useLocation()
  const {
    i18n: { t, rtl },
  } = useContext(I18nContext)
  const {
    layout,
    theme,
    meta: { notificationId: id },
  } = useContext(ConfigurationContext)
  const { isMobile } = useMobileQuery()
  const readStatus = getNotificationReadStatus(id)
  const [seen, setSeen] = useState(readStatus)

  const markSeen = useCallback(
    _evt => {
      setNotificationReadStatus(id)
      setSeen(true)
    },
    [setSeen, id]
  )
  const device = isMobile ? layout.phone : layout.default
  const Layout = Layouts[device] || Layouts['DEFAULT']

  return !!id && !seen ? (
    <Layout
      location={location}
      rtl={rtl}
      t={t}
      mobile={isMobile}
      theme={theme}
      onClose={markSeen}
    />
  ) : null
}

export default Notification
