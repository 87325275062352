import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import Content from 'components/Content'
import LandingContent from 'components/LandingContent'
import { I18nContext } from 'containers/I18nProvider'

const Land = ({ location }) => {
  const {
    i18n: { t },
  } = useContext(I18nContext)

  return location.pathname === '/' ? (
    <Redirect
      exact
      from="/"
      to={{ pathname: '/home', search: location.search }}
    />
  ) : (
    <Content>
      <LandingContent t={t} />
    </Content>
  )
}

export default Land
