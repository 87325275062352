import { useState, useEffect, useRef } from 'react'

const useInterval = action => {
  const calledAction = useRef()
  const [intervalTime, setIntervalTime] = useState()

  useEffect(() => {
    calledAction.current = action
  }, [action])

  useEffect(() => {
    if (intervalTime) {
      const intervalId = setInterval(calledAction.current, intervalTime)
      return () => clearInterval(intervalId)
    }
  }, [intervalTime])

  return [setIntervalTime]
}

export default useInterval
