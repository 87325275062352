import React from 'react'
import Timer from '../Timer'
import QIndex from '../QIndex'
import GameSection from '../GameSection'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import { pushFromTop } from 'animations'
import { useAvailableHeight } from 'containers/AvailableHeight'
import { css } from 'react-emotion'
import { themeProp } from 'utils/theming'
import { Private } from 'lib/auth-utils'

const pageClass = ({ theme, availableHeight, noMargin }) => css`
  font-family: ${themeProp(['page', 'fontFamily'], theme)};
  color: ${themeProp(['page', 'color'], theme)};
  background-color: ${themeProp(['page', 'backgroundColor'], theme)};
  height: ${noMargin ? availableHeight : availableHeight - 40}px;
  display: flex;
  margin: ${noMargin ? 0 : '20px auto'};
  flex-flow: column;
  transition: background-color 0.2s ease-in;
  background-size: cover;
`

/*
 *                 rtl
 *  __________  __________
 * |_______U_| |_U_______|
 * |         | |         |
 * | T  Q  I | | I  Q  T |
 * |         | |         |
 * |_________| |_________|
 *
 * UserMenu, Timer, Questions, Index
 */
export const gameVerticalClass = ({ theme, availableHeight }) => css`
  ${pageClass({ theme, availableHeight })};
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: center;
  padding: 15px 0;
`

const Layout = ({
  theme,
  user,
  rtl,
  position,
  tick,
  inProgress,
  location,
  gameData,
  actions,
  meta,
}) => {
  const [availableHeight] = useAvailableHeight()
  const animation = pushFromTop

  return (
    <Private user={user}>
      <BackgroundImageWrapper
        imgsrc={themeProp(['page', 'backgroundImage'], theme)}
        className={pageClass({
          theme,
          availableHeight,
          nologo: true,
          noMargin: true,
        })}
      >
        <div
          theme={theme}
          className={gameVerticalClass({
            theme,
            availableHeight,
          })}
        >
          {!rtl && inProgress ? (
            <Timer meta={meta} position={position} tick={tick} theme={theme} />
          ) : null}
          {rtl && inProgress ? (
            <QIndex theme={theme} position={position} />
          ) : null}
          <GameSection
            user={user}
            location={location}
            gameData={gameData}
            actions={actions}
            theme={theme}
            animation={animation}
            tick={tick}
          />
          {rtl && inProgress ? (
            <Timer meta={meta} position={position} tick={tick} theme={theme} />
          ) : null}
          {!rtl && inProgress ? (
            <QIndex theme={theme} position={position} />
          ) : null}
        </div>
      </BackgroundImageWrapper>
    </Private>
  )
}

export default Layout
