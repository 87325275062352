import React, { useReducer, useEffect, useCallback, useRef } from 'react'
import { read } from '@logicea/xhr-helpers/lib'
import LoadingOverlay from 'components/LoadingOverlay'

export const FeedContext = React.createContext()

const initialState = {
  loading: false,
  errors: null,
  feed: [],
}

const feedPending = () => ({ type: 'FEED_PENDING' })
const feedSuccess = payload => ({ type: 'FEED_SUCCESS', payload })
const feedFailure = payload => ({ type: 'FEED_FAILURE', payload })

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'FEED_PENDING':
      return { ...state, loading: true }
    case 'FEED_FAILURE':
      return { ...state, loading: false, errors: payload }
    case 'FEED_SUCCESS':
      return { ...state, loading: false, errors: undefined, feed: payload }
    default:
      throw new Error()
  }
}

const useFeed = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  let valid = useRef()

  const fetchFeed = useCallback(async () => {
    try {
      !!valid.current && dispatch(feedPending())
      const feed = await read('/p10/public/feed')
      !!valid.current && dispatch(feedSuccess(feed))
    } catch (e) {
      !!valid.current && dispatch(feedFailure(e))
    }
  }, [dispatch, valid])

  useEffect(() => {
    valid.current = true
    fetchFeed()
    return () => {
      valid.current = false
    }
  }, [fetchFeed, valid])

  const { feed } = state

  return (
    <FeedContext.Provider value={{ feed }}>
      {state.initializing ? <LoadingOverlay /> : null}
      {children}
    </FeedContext.Provider>
  )
}

export default useFeed
