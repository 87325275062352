import React, { useState } from 'react'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import CircularProgressbar from 'react-circular-progressbar'
import styled, { css, keyframes } from 'react-emotion'
import { useAvailableHeight } from 'containers/AvailableHeight'
import { themeProp, hexToRgbA } from 'utils/theming'
import { Private } from 'lib/auth-utils'
import { HeaderLogo } from 'layouts/index'

const DESKTOP_ANIMATED_OFFSET = 345
const DESKTOP_ITEM_HEIGHT = 42
const MOBILE_ANIMATED_OFFSET = 110
const MOBILE_ITEM_HEIGHT = 24
const DESKTOP_LOGO_HEIGHT = 148
const MOBILE_LOGO_HEIGHT = 16

const pageClass = ({ theme, availableHeight, mobile, nologo }) => css`
  font-family: ${themeProp(['page', 'fontFamily'], theme)};
  color: ${themeProp(['page', 'color'], theme)};
  background: ${themeProp(['page', 'backgroundColor'], theme)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${mobile ? '50%' : '50%'};
  width: 100vw;
  height: ${availableHeight}px;
  display: flex;
  margin: 0 auto;
  flex-flow: column;
  transition: background 0.2s ease-in;
  > div:nth-child(1) {
    min-height: ${nologo ? 0 : '56px'};
    max-height: ${nologo ? 'fit-content' : '103px'};
  }
`
const PregameLogo = styled('div')`
  width: 100%;
  height: ${({ mobile }) =>
    mobile ? MOBILE_LOGO_HEIGHT : DESKTOP_LOGO_HEIGHT}px;
  display: flex;
  justify-content: ${({ rtl }) => (rtl ? 'flex-start' : 'flex-end')};
  > a {
    margin: ${({ mobile }) => (mobile ? '15px' : '24px')};
    width: ${({ theme, mobile }) => {
      const width = themeProp(['header', 'logoWidth'], theme)
      return width && mobile ? width : mobile ? '53px' : '100px'
    }};
    height: ${({ theme, mobile }) => {
      const width = themeProp(['header', 'logoHeight'], theme)
      return width && mobile ? width : mobile ? '56px' : '100px'
    }};
  }
`

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 0 20px;
`

const Container = styled('div')`
  position: relative;
  color: red;
  width: ${({ mobile }) => (mobile ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: ${({ mobile }) => (mobile ? '10px 10px 2px 10px' : '0 10px')};
  font-size: 20px;
  line-height: 20px;
  box-sizing: border-box;
`

const Header = styled('div')`
  font-size: ${({ mobile }) => (mobile ? '20px' : `40px`)};
  line-height: ${({ mobile }) => (mobile ? '20px' : `40px`)};
  font-weight: bold;
  color: ${({ theme }) => themeProp(['page', 'headerColor'], theme)};
  margin: 0 0 10px;
`

const CircleContainer = styled('div')`
  width: ${({ mobile }) => (mobile ? '60px' : `120px`)};
`

const AnimatedRow = styled('div')`
  width: 100%;
  height: ${({ mobile, availableHeight }) =>
    `${availableHeight -
      (mobile
        ? MOBILE_ANIMATED_OFFSET + MOBILE_LOGO_HEIGHT
        : DESKTOP_ANIMATED_OFFSET)}px`};
  position: relative;
`
const StepContainer = styled('div')`
  display: flex;
  flex-direction: ${({ rtl }) => (rtl ? 'row-reverse' : 'row')};
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  &:last-child {
    color: ${({ theme }) =>
      hexToRgbA(themeProp(['animated', 'color'], theme), 1)} !important;
  }
`

const StepLeft = styled('div')`
  flex: 0.7;
  font-size: ${({ mobile }) => (mobile ? 12 : 20)}px;
  line-height: ${({ mobile }) => (mobile ? 12 : 20)}px;
  font-weight: bold;
  text-align: ${({ rtl }) => (rtl ? 'left' : 'right')};
  opacity: ${({ hasStarted }) => (hasStarted ? 1 : 0)};
  transition: ${({ idx, delay }) => `opacity ${idx * delay - 0.19}s step-end`};
`

const StepMiddle = styled('div')`
  flex: 1;
  text-align: center;
  position: relative;
  opacity: ${({ hasStarted }) => (hasStarted ? 1 : 0)};
  transition: ${({ idx, delay }) => `opacity ${idx * delay - 0.25}s step-end`};
  > div {
    position: absolute;
    top: ${({ isLast }) => (isLast ? '40px' : '28px')};
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
  }
`

const StepRight = styled('div')`
  flex: 0.7;
  font-size: ${({ mobile }) => (mobile ? 12 : 20)}px;
  line-height: ${({ mobile }) => (mobile ? 12 : 20)}px;
  font-weight: bold;
  text-align: ${({ rtl }) => (rtl ? 'right' : 'left')};
  opacity: ${({ hasStarted }) => (hasStarted ? 1 : 0)};
  transition: ${({ idx, delay }) => `opacity ${idx * delay - 0.19}s step-end`};
`

const WinText = styled('div')`
  color: ${({ theme }) => themeProp(['animated', 'winColor'], theme)};
  margin: 0;
  line-height: ${({ mobile }) => (mobile ? 12 : 20)}px;
`

const BonusText = styled('div')`
  color: ${({ theme }) => themeProp(['animated', 'bonusColor'], theme)};
  margin: 0;
  line-height: ${({ mobile }) => (mobile ? 12 : 20)}px;
`

const bounce = idx => {
  const finalScale = idx ? idx / 10 + 1 : 1
  return keyframes`
    0% {transform: scale(1)}
    50% {transform: scale(${finalScale / 2})}
    70% {transform: scale(${finalScale / 1.5})}
    75% {transform: scale(${finalScale / 1.25})}
    85% {transform: scale(${finalScale})}
    95% {transform: scale(${finalScale * 1.25})}
    100% {transform: scale(${finalScale}) ${idx === 10 ? 'rotate(35deg)' : ''}}
  `
}

const starImage = (idx, delay) => css`
  flex: 1;
  height: 32px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  animation-name: ${idx ? bounce(idx) : ''};
  animation-delay: ${idx * delay}s;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
`

const stepClass = ({
  hasStarted,
  isLast,
  idx,
  theme,
  delay,
  parentHeight,
  itemHeight,
}) => css`
  height: 32px;
  position: absolute;
  bottom: ${hasStarted && !isLast
    ? idx * (parentHeight / itemHeight + itemHeight * 0.85)
    : isLast
    ? idx * (parentHeight / itemHeight + itemHeight * 0.9)
    : 0}px;
  color: ${hexToRgbA(themeProp(['animated', 'color'], theme), 0.65)};
  transition: bottom ${idx * delay - 0.25}s step-end,
    color ${idx * delay}s step-end;
`

const CircleStylesDesktop = theme => ({
  text: {
    fill: `${themeProp(['countdown', 'textColor'], theme)}`,
    fontSize: '30px',
  },
  path: {
    stroke: `${themeProp(['countdown', 'pathColor'], theme)}`,
    strokeWidth: '4',
  },
  trail: {
    stroke: `${themeProp(['countdown', 'trailColor'], theme)}`,
    strokeWidth: '4',
  },
})

const CircleStylesMobile = theme => ({
  text: {
    fill: `${themeProp(['countdown', 'textColor'], theme)}`,
    fontSize: '35px',
  },
  path: {
    stroke: `${themeProp(['countdown', 'pathColor'], theme)}`,
    strokeWidth: '4',
  },
  trail: {
    stroke: `${themeProp(['countdown', 'trailColor'], theme)}`,
    strokeWidth: '4',
  },
})

const Layout = ({
  location,
  theme,
  t,
  rtl,
  auth,
  mobile,
  counter,
  percent,
  COUNTDOWN,
  questions,
  showHeaderLogo,
}) => {
  const [availableHeight] = useAvailableHeight()
  const items = themeProp(['animated', 'items'], theme)
  const [parentHeight, setParentHeight] = useState()

  return (
    <Private user={auth.user}>
      <BackgroundImageWrapper
        imgsrc={themeProp(['page', 'backgroundImage'], theme)}
        className={pageClass({ theme, availableHeight, nologo: true })}
      >
        <PregameLogo rtl={rtl} mobile={mobile} theme={theme}>
          {showHeaderLogo ? (
            <HeaderLogo
              theme={theme}
              rtl={rtl}
              mobile={mobile}
              className={'logo1'}
              to={{ pathname: '/home', search: location.search }}
            />
          ) : null}
        </PregameLogo>
        <Wrapper>
          <Container theme={theme} mobile={mobile}>
            <Header theme={theme} mobile={mobile}>
              {t('pregame.header')}
            </Header>
            <CircleContainer mobile={mobile}>
              <CircularProgressbar
                theme={theme}
                mobile={mobile}
                percentage={percent}
                text={counter}
                counterClockwise={true}
                styles={
                  mobile
                    ? CircleStylesMobile(theme)
                    : CircleStylesDesktop(theme)
                }
              />
            </CircleContainer>
            {items && items.length === parseInt(questions) + 1 ? (
              <AnimatedRow
                availableHeight={availableHeight}
                mobile={mobile}
                innerRef={el => el && setParentHeight(el.clientHeight)}
              >
                {items.map((item, idx) => (
                  <StepContainer
                    key={idx}
                    rtl={rtl}
                    theme={theme}
                    className={stepClass({
                      idx,
                      isLast: idx === parseInt(questions),
                      hasStarted: COUNTDOWN !== counter,
                      delay: (COUNTDOWN - 3.5) / (parseInt(questions) + 1),
                      parentHeight,
                      theme,
                      itemHeight: mobile
                        ? MOBILE_ITEM_HEIGHT
                        : DESKTOP_ITEM_HEIGHT,
                    })}
                  >
                    <StepLeft
                      mobile={mobile}
                      rtl={rtl}
                      idx={idx}
                      delay={(COUNTDOWN - 3.5) / (parseInt(questions) + 1)}
                      hasStarted={COUNTDOWN !== counter}
                    >
                      <WinText mobile={mobile} theme={theme}>
                        {t(item.winText)}
                      </WinText>
                      <div>
                        {idx}/{questions}
                      </div>
                    </StepLeft>
                    <StepMiddle
                      idx={idx}
                      delay={(COUNTDOWN - 3.5) / (parseInt(questions) + 1)}
                      hasStarted={COUNTDOWN !== counter}
                      isLast={idx === parseInt(questions)}
                    >
                      <img
                        alt=""
                        src={item.imagePregame}
                        className={starImage(
                          idx,
                          (COUNTDOWN - 3.5) / (parseInt(questions) + 1)
                        )}
                        onError={ev =>
                          (ev.target.src =
                            'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
                        }
                      />
                      {idx ? (
                        <div>
                          <img
                            alt=""
                            height="12px"
                            src="images/step.png"
                            onError={ev =>
                              (ev.target.src =
                                'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
                            }
                          />
                        </div>
                      ) : null}
                    </StepMiddle>
                    <StepRight
                      idx={idx}
                      delay={(COUNTDOWN - 3.5) / (parseInt(questions) + 1)}
                      hasStarted={COUNTDOWN !== counter}
                      mobile={mobile}
                      rtl={rtl}
                    >
                      <BonusText mobile={mobile} theme={theme}>
                        {t(item.bonusText)}
                      </BonusText>
                      <div>{t(item.pointsText)}</div>
                    </StepRight>
                  </StepContainer>
                ))}
              </AnimatedRow>
            ) : null}
          </Container>
        </Wrapper>
      </BackgroundImageWrapper>
    </Private>
  )
}

export default Layout
