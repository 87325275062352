import * as R from 'ramda'
import React from 'react'
import styled from 'react-emotion'
import { themeProp, hexToRgbA } from 'utils/theming'

export const Input = styled('input')`
  outline: none;
  height: 40px;
  line-height: 40px;
  background-color: ${({ theme }) =>
    themeProp(['input', 'backgroundColor'], theme)};
  border-radius: 100px;
  font-size: 14px;
  color: ${({ theme }) => themeProp(['input', 'color'], theme)};
  padding: 0 20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, error }) =>
    error
      ? '#e74c3c'
      : `${hexToRgbA(themeProp(['input', 'borderColor'], theme), 0.4)}`};
`

const ErrorText = styled('div')`
  font-size: 12px;
  color: #e74c3c;
`

const checkIfHasError = (name, errors) =>
  R.anyPass([
    R.path(['error']),
    R.compose(
      R.contains(name),
      R.pluck('fieldname'),
      R.propOr([], 'fields')
    ),
  ])(errors || {})

const getError = errors => R.pathOr(null, ['error', 'key'], errors)

const InputText = ({
  theme,
  errors,
  name,
  type,
  placeholder,
  value,
  onChange,
  t,
}) => {
  const hasError = checkIfHasError(name, errors)
  const errorLabel = hasError ? t(getError(errors)) : undefined

  return (
    <React.Fragment>
      <Input
        theme={theme}
        error={hasError}
        type={type ? type : 'text'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {hasError ? <ErrorText>{errorLabel}</ErrorText> : null}
    </React.Fragment>
  )
}

export default InputText
