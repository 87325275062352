import * as R from 'ramda'
import qs from 'query-string'

export const getLang = defaultLang =>
  R.propOr(
    defaultLang || 'ar',
    'lang',
    qs.parse(R.propOr({}, 'search', window.location))
  )

export const getTokenValues = R.applySpec({
  token: R.prop('access_token'),
  tokenExpiresIn: R.prop('expires_in'),
  refreshToken: R.prop('refresh_token'),
})

export const authGaEvent = action => {
  const eventAction = R.path(['payload', 'gaEvent'], action)
  const operatorId = R.path(['payload', 'operatorId'], action)

  return {
    hitType: 'event',
    eventCategory: 'USER',
    eventAction,
    eventLabel: eventAction === 'SUBSCRIBE' ? operatorId : '',
  }
}

export const validateGaEvent = gaEvent => gaEvent.eventAction

const isSubscribed = R.propEq('userStatus', 'SUBSCRIBED')
const notSubscribed = R.complement(isSubscribed)
export const shouldDisplayWelcomePage = R.allPass([
  R.compose(
    isSubscribed,
    R.prop('user')
  ),
  R.compose(
    notSubscribed,
    R.prop('prevUser')
  ),
])

export const shouldUpdateTimer = R.allPass([
  R.compose(
    Boolean,
    R.path(['user', 'token'])
  ),
  R.compose(
    R.isNil,
    R.prop('refreshTimerId')
  ),
])

export const shouldRefreshToken = R.allPass([
  R.compose(
    Boolean,
    R.prop('refreshToken')
  ),
  R.compose(
    R.isNil,
    R.path(['user', 'token'])
  ),
  R.compose(
    R.isNil,
    R.path(['prevUser', 'token'])
  ),
])

export const shouldFetchUser = R.anyPass([
  R.allPass([
    R.compose(
      Boolean,
      R.path(['user', 'token'])
    ),
    R.compose(
      R.isNil,
      R.path(['prevUser', 'token'])
    ),
  ]),
  R.allPass([
    R.compose(
      isSubscribed,
      R.prop('user')
    ),
    R.compose(
      R.isNil,
      R.path(['prevUser', 'userStatus'])
    ),
  ]),
])

export const getUtm = R.applySpec({
  campaign: R.prop('utm_campaign'),
  source: R.prop('utm_source'),
  medium: R.prop('utm_medium'),
  term: R.prop('utm_term'),
  content: R.prop('utm_content'),
  id: R.prop('utm_id'),
})

const hasOnlyOneOperator = R.compose(
  R.gte(1),
  R.length,
  R.keys,
  R.prop('operators')
)
export const getOperator = R.ifElse(
  R.allPass([
    R.compose(
      Boolean,
      R.prop('operators')
    ),
    hasOnlyOneOperator,
  ]),
  R.compose(
    R.head,
    R.map(R.toLower),
    R.keys,
    R.prop('operators')
  ),
  R.always(undefined)
)
