import * as R from 'ramda'
import { useReducer, useEffect, useContext, useCallback, useRef } from 'react'
import { read } from '@logicea/xhr-helpers/lib'
import { getSessionTokens } from './utils'
import { AuthenticationContext } from 'containers/AuthProvider'

const initialState = {
  loading: false,
  errors: null,
  result: {},
}

const getGameStatus = d => R.propOr(null, 'gameStatus', d)
const getCurrentPosition = d => R.pathOr(null, ['currentStep', 'position'], d)
const getNumOfErrors = d => R.propOr(0, 'numOfErrors', d)
const getCorrectAnswers = d => 10 - getNumOfErrors(d)

const resultPending = () => ({ type: 'RESULT_PENDING' })
const resultSuccess = payload => ({ type: 'RESULT_SUCCESS', payload })
const resultFailure = payload => ({ type: 'RESULT_FAILURE', payload })

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'RESULT_PENDING':
      return { ...state, loading: true }
    case 'RESULT_FAILURE':
      return { ...state, loading: false, errors: payload }
    case 'RESULT_SUCCESS':
      window.ga &&
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'GAME',
          eventAction: getGameStatus(payload),
          eventLabel:
            getGameStatus(payload) === 'TIMEOUT'
              ? getCurrentPosition(payload)
              : getCorrectAnswers(payload),
        })
      return { ...state, loading: false, errors: undefined, result: payload }
    default:
      throw new Error()
  }
}

const useResult = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const sessionTokens = getSessionTokens()
  const valid = useRef()
  const token = sessionTokens ? sessionTokens.access_token : undefined
  const {
    auth: { fetchUser },
  } = useContext(AuthenticationContext)

  const fetchResult = useCallback(async () => {
    try {
      !!valid.current && dispatch(resultPending())
      const result = await read('/p10/game/result', token)
      !!valid.current && dispatch(resultSuccess(result))
      fetchUser()
    } catch (e) {
      !!valid.current && dispatch(resultFailure(e))
    }
  }, [token, fetchUser])

  useEffect(() => {
    valid.current = true
    if (R.isEmpty(state.result)) {
      fetchResult()
    }
    return () => {
      valid.current = false
    }
  }, [state.result, fetchResult])

  return [state]
}

export default useResult
