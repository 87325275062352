import 'react-circular-progressbar/dist/styles.css'
import React, { useContext, useState, useEffect } from 'react'
import useMobileQuery from 'containers/MobileQuery'
import * as Layouts from './layouts'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { AuthenticationContext } from 'containers/AuthProvider'
import { I18nContext } from 'containers/I18nProvider'
import useInterval from 'containers/IntervalProvider'

const COUNTDOWN = 5

const PreGame = ({ history, location }) => {
  const {
    i18n: { t, rtl },
  } = useContext(I18nContext)
  const { auth } = useContext(AuthenticationContext)
  const {
    theme,
    layout,
    meta: { questions },
  } = useContext(ConfigurationContext)
  const [counter, setCounter] = useState(COUNTDOWN)
  const [setIntervalTime] = useInterval(() => setCounter(c => c - 1))
  const { isMobile } = useMobileQuery()
  const percent = 100 - Math.abs((counter * 100) / COUNTDOWN)
  const showHeaderLogo = theme?.page?.showHeaderLogo

  useEffect(() => {
    if (counter === 0) {
      setIntervalTime(null)
      history.replace({ pathname: '/game', search: location.search })
    } else {
      setIntervalTime(1000)
    }
    return () => setIntervalTime(null)
  }, [counter, setIntervalTime, history, location.search])

  const props = {
    location,
    theme,
    t,
    rtl,
    auth,
    percent,
    counter,
    questions,
    COUNTDOWN,
    showHeaderLogo,
    ...(isMobile ? { mobile: 'true' } : {}),
  }

  const device = isMobile ? layout.phone : layout.default
  const Layout = Layouts[device] || Layouts['DEFAULT']
  return <Layout {...props} />
}

export default PreGame
