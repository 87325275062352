import * as Layouts from './Layouts'
import React, { useContext } from 'react'
import useMobileQuery from 'containers/MobileQuery'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { I18nContext } from 'containers/I18nProvider'

const Blocking = (location) => {
  const {
    i18n: { t },
  } = useContext(I18nContext)
  const { layout, theme } = useContext(ConfigurationContext)
  const { isMobile } = useMobileQuery()
  const device = isMobile ? layout.phone : layout.default
  const Layout = Layouts[device] || Layouts['DEFAULT']

  return <Layout t={t} mobile={isMobile} theme={theme} location={location} />
}

export default Blocking
