import React from 'react'
import Helmet from 'react-helmet'

const GA = React.memo(({ trackerId }) => {
  return trackerId ? (
    <Helmet>
      <script defer>
        {`
          window.ga =
            window.ga ||
              function () {
                ; (window.ga.q = window.ga.q || []).push(arguments)
              }
              window.ga.l = +new Date()
              ga('create', '${trackerId}', 'auto')
              ga('send', 'pageview')
        `}
      </script>
      <script src="https://www.google-analytics.com/analytics.js" defer />
    </Helmet>
  ) : null
})

export default GA
