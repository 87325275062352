import React from 'react'
import useMobileQuery from 'containers/MobileQuery'
import styled from 'react-emotion'
import { NavLink } from 'react-router-dom'
import trim from 'ramda/src/trim'
import { themeProp } from '../utils/theming'

export const CloseOverlay = styled(NavLink)`
  position: absolute;
  top: ${({ mobile }) => (mobile ? '40px' : '100px')};
  left: ${({ rtl, mobile }) => (rtl ? 'initial' : mobile ? '20px' : '80px')};
  right: ${({ rtl, mobile }) => (rtl ? (mobile ? 'initial' : '80px') : 'auto')};
  z-index: 3;
  width: ${({ mobile }) => (mobile ? '19px' : '38px')};
  height: ${({ mobile }) => (mobile ? '19px' : '38px')};
  &:hover {
    color: inherit;
  }
`

const StyledHeader = styled('div')`
  position: ${({ mobile }) => (mobile ? 'relative' : 'absolute')};
  top: ${({ mobile }) => (mobile ? 'initial' : '170px')};
  left: 50%;
  transform: translateX(-50%);
  width: ${({ mobile }) => (mobile ? '100%' : 'max-content')};
  height: ${({ mobile }) => (mobile ? 'auto' : '36px')};
  font-size: clamp(26px, 4vw, 36px);
  line-height: clamp(26px, 4vw, 36px);
  margin-top: ${({ mobile }) => (mobile ? '14px' : '0')};
  color: ${({ theme }) => themeProp(['overlay', 'headerColor'], theme)};
  text-align: center;
`

const StyledOverlay = styled('div')`
  text-align: ${({ rtl }) => (rtl ? 'right' : 'left')};
  color: ${({ theme }) => themeProp(['modal', 'color'], theme)};
  border-color: ${({ theme }) => themeProp(['modal', 'color'], theme)};
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
  background: ${({ theme }) => themeProp(['footer', 'backgroundColor'], theme)};
  position: relative;
  height: inherit;
  box-sizing: border-box;
`

const StyledContent = styled('div')`
  padding-top: 220px;
`

const MobileStyledContainer = styled('div')`
  padding: 20px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${({ mobile, innerHeight }) =>
    mobile ? `${innerHeight}px` : '100dvh'};
  box-sizing: border-box;
`

const MobileStyledContent = styled('div')`
  padding-top: 24px;
  overflow: auto;
`

const CloseImg = styled('div')`
  display: inline-block;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => {
    const imgurl = themeProp(['overlay', 'backgroundImage'], theme)
    return imgurl ? `url(${imgurl}) no-repeat` : 'none'
  }};
  background-size: contain;
  background-position: center;
`

const Overlay = ({
  header,
  rtl,
  location,
  children,
  theme,
  onClose,
  nonDismissable = false,
}) => {
  const { isMobile } = useMobileQuery()
  const returnTo =
    location && location.state ? location.state.returnTo : '/home'
  const innerHeight = window.innerHeight

  return (
    <StyledOverlay rtl={rtl} theme={theme}>
      {isMobile ? (
        <MobileStyledContainer mobile={isMobile} innerHeight={innerHeight}>
          {!nonDismissable ? (
            <CloseOverlay
              theme={theme}
              rtl={rtl ? 'true' : null}
              to={{
                pathname: returnTo,
                state: { returnTo: returnTo },
                search: location.search,
              }}
              onClick={onClose}
              mobile={1}
            >
              <CloseImg theme={theme} />
            </CloseOverlay>
          ) : null}
          {header && trim(header) !== '' ? (
            <StyledHeader rtl={rtl} mobile>
              {header}
            </StyledHeader>
          ) : null}
          <MobileStyledContent>{children}</MobileStyledContent>
        </MobileStyledContainer>
      ) : (
        <React.Fragment>
          {header ? <StyledHeader rtl={rtl}>{header}</StyledHeader> : null}
          {!nonDismissable ? (
            <CloseOverlay
              rtl={rtl ? 'true' : null}
              to={{
                pathname: returnTo,
                state: { returnTo: returnTo },
                search: location.search,
              }}
              onClick={onClose}
            >
              <CloseImg theme={theme} />
            </CloseOverlay>
          ) : null}
          <StyledContent>{children}</StyledContent>
        </React.Fragment>
      )}
    </StyledOverlay>
  )
}

export default Overlay
