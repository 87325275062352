import React from 'react'
import { css, cx } from 'react-emotion'

const placeholder =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

const loadingImage = css`
  background-image: url(${placeholder});
`

class BackgroundImageWrapper extends React.Component {
  image = null
  el = null
  loadedImage = null

  componentDidMount() {
    this.init()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.imgsrc !== prevProps.imgsrc ||
      this.props.className !== prevProps.className
    ) {
      this.init()
    }
  }

  init = () => {
    const { imgsrc } = this.props
    this.loadedImage = css`
      background-image: url(${imgsrc}) !important;
    `
    imgsrc && imgsrc !== '' && this.loadImage(imgsrc)
  }

  loadImage = src => {
    const image = new Image()
    this.image = image
    image.onload = this.onLoad
    image.src = src
  }

  onLoad = () => {
    this.el &&
      !this.el.classList.contains(this.loadedImage) &&
      this.el.classList.add(this.loadedImage)
  }

  render() {
    const { imgsrc, className, children, ...props } = this.props
    return (
      <div
        className={imgsrc ? cx(className, loadingImage) : className}
        ref={el => (this.el = el)}
        {...props}
      >
        {children}
      </div>
    )
  }
}

export default BackgroundImageWrapper
