import React from 'react'
import FontAwesome from 'react-fontawesome'
import styled from 'react-emotion'

const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Loader = () => (
  <Wrapper>
    <FontAwesome name="cog" className="fa-spin fa-5x fa-fw" />
  </Wrapper>
)

export default Loader
