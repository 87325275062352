import { useEffect, useState, useCallback, useRef } from 'react'

const useExternals = aggregator => {
  const [externals, setExternals] = useState()
  const ref = useRef()

  const getExternalLib = useCallback(async () => {
    setExternals({ pending: true })
    fetch('/api/p10/public/external-lib-url', {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        setExternals({ pending: false })
        const onLoad = () => {
          const getToken = window.TPay?.HeaderEnrichment?.sessionToken

          if (getToken) {
            const token = getToken()
            !!token && setExternals({ token })
          } else {
            setExternals(undefined)
          }
        }
        const script = document.createElement('script')
        script.src = response?.text()
        script.async = true
        script.onload = onLoad
        ref.current = script
        document.body.appendChild(script)
      })
      .catch(() => {
        setExternals({ pending: false })
      })
  }, [])

  useEffect(() => {
    if (aggregator) {
      switch (aggregator) {
        case 'tpay':
          getExternalLib()
          break
        default:
          break
      }
    }

    return () => {
      !!ref.current && document.body.removeChild(ref.current)
    }
  }, [aggregator, getExternalLib])

  return externals
}

export default useExternals
