import React, { useContext } from 'react'
import { css } from 'react-emotion'
import { themeKey } from '../utils/theming'
import { animated } from 'react-spring'
import { Spring } from 'react-spring'
import { __RouterContext } from 'react-router'

const getClass = mobile => css`
  width: 100%;
  height: auto;
  position: ${mobile ? 'initial' : 'absolute'};
`

const AnimatedContent = ({ animation, mobile, children }) => {
  const { location } = useContext(__RouterContext)
  const themekey = themeKey(location)

  return (
    <Spring
      key={themekey}
      from={animation['atEnter']}
      to={animation['atActive']}
    >
      {props => (
        <animated.div style={props} className={getClass(mobile)}>
          {children}
        </animated.div>
      )}
    </Spring>
  )
}

export default AnimatedContent
