import React from 'react'
import Overlay from 'components/Overlay'
import Button from 'components/Button'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import { Spring } from 'react-spring'
import { zoomIn as animation } from 'animations'
import { css } from 'react-emotion'
import { themeProp } from 'utils/theming'

const cls = {
  container: (mobile, theme) => css`
    font-family: ${themeProp(['page', 'fontFamily'], theme)};
    position: absolute;
    z-index: 20;
    width: 100vw;
    height: 100dvh;
    overflow: ${mobile ? 'auto' : 'initial'};
    color: ${themeProp(['modal', 'color'], theme)};
    background: ${themeProp(['modal', 'backgroundColor'], theme)};
    background-size: cover;

    > div:nth-child(1) {
      padding: ${mobile ? '10px' : '0'};
    }
  `,
  content: css`
    height: 100%;
    width: 100%;
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  `,
  btnContainer: css`
    margin: 20px 0;
    width: 280px;
  `,
  checkbox: css`
    width: 24px;
    height: 24px;
    vertical-align: middle;
  `,
  label: css`
    white-space: nowrap;
  `,
  link: css`
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  `,
}

const Default = ({ location, rtl, t, mobile, theme, onClose }) => {
  return (
    <BackgroundImageWrapper
      className={cls.container(mobile, theme)}
      imgsrc={themeProp(['modal', 'backgroundImage'], theme)}
    >
      <Overlay theme={theme} rtl={rtl} location={location} onClose={onClose}>
        <Spring from={animation['atEnter']} to={animation['atActive']}>
          {props => (
            <div className={cls.content} key="content" style={props}>
              <div>{t('notification.text')}</div>
              <div className={cls.btnContainer}>
                <Button
                  text={t('notification.ok')}
                  theme={theme}
                  onClick={onClose}
                />
              </div>
            </div>
          )}
        </Spring>
      </Overlay>
    </BackgroundImageWrapper>
  )
}

export default Default
