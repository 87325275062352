import React, { Component } from 'react'
import { styles } from 'components/Game/AnswerSection'
import DragLayer from 'react-dnd/lib/DragLayer'
import { cx } from 'react-emotion'

const _isIE11 = !!window.MSInputMethodContext && !!document.documentMode

const layerStyles = (num, mobile) => ({
  position: _isIE11 ? 'expression(fixed)' : 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: (mobile ? num * 3 : num * 5) * num,
  width: '100%',
  height: '100%',
})

function getItemStyles(props) {
  const { currentOffset, num, mobile } = props
  if (!currentOffset) {
    return {
      display: 'none',
    }
  }

  const { y } = currentOffset

  const transform = `translateY(${y -
    (_isIE11 ? 85 : mobile ? 15 : 25) * num}px)`

  return {
    transform,
    WebkitTransform: transform,
  }
}

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }
}

class DragAnswerPreview extends Component {
  renderItem(item, mobile, rtl) {
    const { theme, text } = item
    return (
      <div
        className={cx(styles.answer(rtl, mobile), 'active')}
        theme={theme}
        mobile={mobile}
      >
        {text}
      </div>
    )
  }

  render() {
    const { item, isDragging, num, mobile } = this.props
    if (!isDragging) {
      return null
    }
    return (
      <div style={layerStyles(num, mobile)}>
        <div style={getItemStyles(this.props)}>
          {this.renderItem(item, mobile)}
        </div>
      </div>
    )
  }
}

export default DragLayer(collect)(DragAnswerPreview)
