import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { getDefaultLang } from 'utils/theming'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

export const I18nContext = React.createContext()

const I18nProvider = ({ children }) => {
  const location = useLocation()
  const {
    meta: { languages, defaultLang },
  } = React.useContext(ConfigurationContext)
  const lang = getDefaultLang(defaultLang, location)
  const search = useMemo(() => qs.parse(location.search) || {}, [
    location.search,
  ])
  const urlParams = new URLSearchParams(search)
  const lpId = urlParams.get('lpId')
  const isValidLang = languages.some(l => l.lang === lang)

  const langCheck = isValidLang ? lang : defaultLang
  const i18nState = {
    rtl: langCheck === 'ar',
    t: Function.prototype,
    lang,
  }

  const [i18n, setI18n] = useState(i18nState)

  const fetchMessages = useCallback(async () => {
    try {
      const lpIdTeaser = lpId === null ? '' : `&lpId=${lpId}`
      const response = await fetch(
        `/api/p10/public/texts?lang=${langCheck}${lpIdTeaser}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      const data = await response.json()
      if (!data || !data.path) throw Error('no translation path found!')

      const translations = await fetch(data.path, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const messages = await translations.json()
      const customTexts = data.customTexts

      await customTexts?.forEach(i => {
        messages[i.key] = i.value
      })
      /* --Old call of local translations for local testing-- */

      /*  const response = await fetch(`/messages/${lang}.json`)
      const messages = await response.json() */
      const rtl = langCheck === 'ar'
      setI18n(s => ({ ...s, t: k => messages[k] || '', lang, rtl }))
    } catch (e) {
      console.log({ ErrorWhileFetchingMessages: e })
    }
  }, [lang, langCheck, lpId])

  useEffect(() => {
    fetchMessages()
  }, [fetchMessages])

  return (
    <I18nContext.Provider value={{ i18n }}>{children}</I18nContext.Provider>
  )
}

export default I18nProvider
