import React, { Fragment, useContext } from 'react'
import { Private } from 'lib/auth-utils'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import { css } from 'react-emotion'
import { themeProp } from 'utils/theming'
import LangToggle from 'components/LangToggle'
import MobileHeader from 'components/MobileHeader'
import MobileMenu from 'components/MobileMenu'
import { Link } from 'react-router-dom'
import AccountToggle from 'components/AccountToggle'
import Footer from 'components/Footer'
import { HeaderLogo } from 'layouts/index'
import { useDataLayerEvents } from 'containers/dataLayerEvents'
import { ConfigurationContext } from 'containers/ConfigurationProvider'

const logoWidth1 = theme => themeProp(['content', 'logoImageWidth1'], theme)
const logoWidth2 = theme => themeProp(['content', 'logoImageWidth2'], theme)
const logoHeight1 = theme => themeProp(['content', 'logoImageHeight1'], theme)
const logoHeight2 = theme => themeProp(['content', 'logoImageHeight2'], theme)

const cls = {
  container: (mobile, theme) => css`
    font-family: ${themeProp(['page', 'fontFamily'], theme)};
    font-size: 14px;
    z-index: 20;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100dvh;
    max-height: -webkit-fill-available;
    overflow: ${mobile ? 'auto' : 'initial'};
    color: ${themeProp(['modal', 'color'], theme)};
    background-color: ${themeProp(['page', 'backgroundColor'], theme)};
    background: url(${themeProp(['page', 'backgroundImage'], theme)});
    background-size: cover;
  `,
  header: (mobile, theme, rtl) => css`
    position: relative;
    display: flex;
    flex-direction: ${rtl ? 'row-reverse' : 'row'};
    justify-content: space-between;
    align-items: center;
    min-height: ${mobile ? '56px' : 'unset'};
    max-height: ${mobile ? '103px' : 'unset'};
    padding: ${mobile ? '1px 15px' : '25px 70px 10px'};
    background: ${themeProp(['page', 'backgroundColor'], theme)};
  `,
  leftHeaderWrapper: rtl => css`
    display: flex;
    flex-direction: ${rtl ? 'row-reverse' : 'row'};
    align-items: center;
    font-size: 18px;

    a {
      margin: ${rtl ? '0 0 0 45px' : '0 45px 0 0'};
      &:last-child {
        margin: 0;
      }
    }
  `,

  logo1: (theme, rtl) => css`
    width: ${logoWidth1(theme) ? logoWidth1(theme) : 'auto'};
    height: ${logoHeight1(theme) ? logoHeight1(theme) : 'auto'};
    margin: ${rtl ? '0 0 0 45px' : '0 45px 0 0'};
  `,
  logo2: theme => css`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: ${logoWidth2(theme) ? logoWidth2(theme) : 'auto'};
    height: ${logoHeight2(theme) ? logoHeight2(theme) : 'auto'};
  `,
  contentWrapper: mobile => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: ${mobile ? 'calc(100% - 71px)' : '100%'};
    padding: ${mobile ? '15px' : '60px 150px'};
    box-sizing: border-box;
  `,
  content: mobile => css`
    display: flex;
    flex-direction: ${mobile ? 'column' : 'row'};
    align-items: center;
    justify-content: ${mobile ? 'normal' : 'center'};
    width: 100%;
    overflow: auto;
  `,
  contentHeader: mobile => css`
    font-size: ${mobile ? '20px' : '30px'};
    margin: ${mobile ? '0 0 15px' : '0 0 70px'};
  `,
  selectCard: (theme, mobile) => css`
    width: 100%;
    max-width: 700px;
    height: fit-content;
    box-sizing: border-box;
    border-radius: 15px;
    border-style: solid;
    border-width: 4px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    cursor: pointer;
    flex: 1;

    &:first-child {
      background-image: ${themeProp(
        ['content', 'cardBackgroundColor1'],
        theme
      )};
      border-image: ${themeProp(['content', 'cardBorder1'], theme)} 0;
      box-shadow: ${themeProp(['content', 'cardShadow1'], theme)};
      margin: ${mobile ? '0 0 25px' : '0 40px 12px 0'};
    }

    &:nth-child(2) {
      background-image: ${themeProp(
        ['content', 'cardBackgroundColor2'],
        theme
      )};
      border-image: ${themeProp(['content', 'cardBorder2'], theme)} 0;
      box-shadow: ${themeProp(['content', 'cardShadow2'], theme)};
      margin: ${mobile ? '0 0 25px' : '0 0 12px'};
    }
  `,
  selectCardPadding: mobile => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${mobile ? '10px' : '20px'};
    text-align: center;
  `,
  selectedCardTextHeight: css`
    visibility: hidden; /* hides the trial span on the 2nd card, which is used only for its size */
  `,
  cardHeader: (theme, mobile) => css`
    font-size: ${mobile ? '22px' : '30px'};
    margin: ${mobile ? '5px 0' : '14px 0'};
    font-weight: normal;
    color: ${themeProp(['content', 'cardHeaderColor'], theme)};
  `,
  trialInfo: (theme, mobile) => css`
    font-size: ${mobile ? '12px' : '16px'};
    color: ${themeProp(['content', 'trialMessageColor'], theme)};
    margin: ${mobile ? '7px 0 0' : '20px 0 0'};

    p {
      display: inline-block;
      margin: 0;
      color: ${themeProp(['content', 'trialPointsColor'], theme)};
    }
  `,
  cardImage: mobile => css`
    width: auto;
    height: auto;
    max-height: ${mobile ? '11vh' : 'initial'};
    max-width: ${mobile ? '105px' : '125px'};
    margin-bottom: ${mobile ? '12px' : '15px'};
  `,
  cardMessage: mobile => css`
    font-size: ${mobile ? '13px' : '18px'};
  `,
  rightHeaderWrapper: rtl => css`
    display: flex;
    flex-direction: ${rtl ? 'row-reverse' : 'row'};
    align-items: center;
  `,
  accountDropdownStyling: css`
    margin: 0 15px 0;
  `,
}

const Default = ({
  theme,
  mobile,
  location,
  rtl,
  t,
  user,
  lang,
  auth,
  footerlinks,
}) => {
  const tournamentsUrl = user.externalGameUrls?.TOURNAMENTS
  const { dataLayerPush } = useDataLayerEvents()
  const userId = !!user?.id ? user?.id : ''
  const userTypeAdvanced = !!user.username
    ? user?.userStatus === 'SUBSCRIBED'
      ? 'subscribed'
      : 'registered'
    : 'anonymous'
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const {
    meta: { defaultLang },
  } = useContext(ConfigurationContext)
  const dataLayerObject = {
    event: 'modeSelection',
    screenName: '/selectMode',
    selectedMode: 'score',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userTypeAdvanced,
  }
  const dataLayerObject2 = {
    event: 'modeSelection',
    screenName: '/selectMode',
    selectedMode: 'challenge',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userTypeAdvanced,
  }
  return (
    <Private user={user}>
      <BackgroundImageWrapper
        className={cls.container(mobile, theme)}
        imgsrc={themeProp(['modal', 'backgroundImage'], theme)}
      >
        {mobile ? (
          <Fragment>
            <MobileHeader location={location} user={user} rtl={rtl} />
            {location.state && location.state.menu ? (
              <MobileMenu location={location} />
            ) : null}
          </Fragment>
        ) : (
          <div className={cls.header(mobile, theme, rtl)}>
            <div className={cls.leftHeaderWrapper(rtl)}>
              <HeaderLogo
                theme={theme}
                to={{ pathname: '/home', search: location.search }}
                className={`${'unclickable'} ${'logo1'}`}
              />
              <Link to={`/howtooverlay?lang=${lang}`}>{t('howto.link')}</Link>
              <Link to={`/prizesoverlay?lang=${lang}`}>{t('prizes.link')}</Link>
            </div>
            <HeaderLogo
              theme={theme}
              to={{ pathname: '/home', search: location.search }}
              className={`${'unclickable'} ${'logo2'}`}
            />
            <div className={cls.rightHeaderWrapper(rtl)}>
              <div className={cls.accountDropdownStyling}>
                <AccountToggle theme={theme} auth={auth} />
              </div>
              <LangToggle theme={theme} />
            </div>
          </div>
        )}
        <div className={cls.contentWrapper(mobile)}>
          <div className={cls.contentHeader(mobile)}>
            {t('gameselection.header')}
          </div>
          <div className={cls.content(mobile)}>
            <Link
              onClick={() => dataLayerPush(dataLayerObject)}
              className={cls.selectCard(theme, mobile)}
              to={`/home?lang=${lang}${
                user?.enablePopups ? '&resumePopup=true' : ''
              }`}
            >
              <div className={cls.selectCardPadding(mobile)}>
                <img
                  alt=""
                  className={cls.cardImage(mobile)}
                  src={themeProp(['content', 'cardImage1'], theme)}
                  onError={ev =>
                    (ev.target.src =
                      'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
                  }
                />
                {/*<h2 className={cls.cardHeader(theme, mobile)}>{t('gameselection.card.1.header')}</h2>*/}
                <span className={cls.cardMessage(mobile)}>
                  {t('gameselection.card.1.message.a')}
                </span>
                <span className={cls.cardMessage(mobile)}>
                  {t('gameselection.card.1.message.b')}
                </span>
                {/*<span className={cls.trialInfo(theme, mobile)}>{t('gameselection.card.1.message.c')} <p>{user.points}</p></span>*/}
              </div>
            </Link>
            <a
              onClick={() => dataLayerPush(dataLayerObject2)}
              className={cls.selectCard(theme, mobile)}
              href={`${tournamentsUrl}?token=${user.token}&operator=${user.operatorCode}&country=${user.countryCode}&lang=${lang}`}
            >
              <div className={cls.selectCardPadding(mobile)}>
                <img
                  alt=""
                  className={cls.cardImage(mobile)}
                  src={themeProp(['content', 'cardImage2'], theme)}
                  onError={ev =>
                    (ev.target.src =
                      'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
                  }
                />
                {/*<h2 className={cls.cardHeader(theme, mobile)}>{t('gameselection.card.2.header')}</h2>*/}
                <span className={cls.cardMessage(mobile)}>
                  {t('gameselection.card.2.message.a')}
                </span>
                <span className={cls.cardMessage(mobile)}>
                  {t('gameselection.card.2.message.b')}
                </span>
              </div>
            </a>
          </div>
        </div>
        {!mobile ? (
          <Footer returnTo={'/select-game'} items={footerlinks} />
        ) : null}
      </BackgroundImageWrapper>
    </Private>
  )
}

export default Default
