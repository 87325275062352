import * as Sound from 'react-howler'
import * as R from 'ramda'
import React, { useContext } from 'react'
import Loader from 'components/Loader'
import Button from 'components/Button'
import styled, { css } from 'react-emotion'
import { themeProp } from 'utils/theming'
import { Link, withRouter } from 'react-router-dom'
import { backHome } from 'components/Result/layouts/Card'
import { I18nContext } from 'containers/I18nProvider'

const ResultWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${({ mobile }) => (mobile ? ' 0 15px 15px' : '0 ')};
  box-sizing: border-box;
`

const ScoreContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ mobile }) => (mobile ? '100%' : 'auto')};
  justify-content: center;
  background-color: ${({ theme }) =>
    themeProp(['content', 'backgroundColor'], theme)};
  border-radius: 5px;
  color: rgb(43, 30, 135);
  background: white;
  padding: ${({ mobile }) => (mobile ? '15px' : '30px 100px')};
  margin: ${({ mobile }) => (mobile ? '0 0 30px' : '0 0 40px')};
  box-sizing: border-box;
`

const ScoreHeader = styled('h3')`
  margin: ${({ mobile }) => (mobile ? '0 0 30px' : '0 0 60px')};
  font-size: ${({ mobile }) => (mobile ? '18px' : ' 22px')};
`

const Score = styled('span')`
  font-size: ${({ mobile }) => (mobile ? '40px' : '70px')};
  font-weight: bold;
`
const ResultMessage = styled('div')`
  font-size: ${({ mobile }) => (mobile ? '15px' : ' 22px')};
`

const PrizeContainer = styled('div')`
  margin: ${({ mobile }) => (mobile ? '0 0 20px' : '0 0 40px')};
  font-size: ${({ mobile }) => (mobile ? '25px' : '40px')};
  span {
    font-weight: bold;
    color: ${({ theme }) => themeProp(['content', 'scoreColor'], theme)};
    direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
  }
`

const PrizeImg = styled('div')`
  width: ${({ mobile }) => (mobile ? '60px' : '100px')};
  height: ${({ mobile }) => (mobile ? '36px' : '61px')};
  margin: ${({ mobile }) => (mobile ? '0 0 20px' : '0 0 25px')};
  background-image: url(${({ theme }) =>
    themeProp(['content', 'prizeImg'], theme)});
  background-repeat: no-repeat;
  background-size: contain;
`

const ButtonContainer = styled('div')`
  width: ${({ mobile }) => (mobile ? '100%' : '265px')};
`

const cantBuyClass = theme => css`
  margin: 0 0 30px;
  color: ${themeProp(['content', 'scoreColor'], theme)};
`

const Mixed = ({
  status,
  theme,
  user = {},
  onChange,
  onPlayAgain,
  onResubscribe,
  resultData = {},
  history,
  location,
  mobile,
  questions,
}) => {
  const {
    i18n: { t, rtl },
  } = useContext(I18nContext)
  const { canBuy, userStatus, credits: crds } = user
  const { result: { numOfErrors, currentStep } = {} } = resultData
  const position = currentStep ? currentStep.position : 0
  const credits = crds ? crds.length : undefined
  const canPlay = credits && credits > 0
  const played =
    status === 'timeout' || status === 'abandoned'
      ? parseInt(position, 10) - 1
      : 10
  const correctOnes = played - parseInt(numOfErrors, 10)

  return (
    <ResultWrapper mobile={mobile}>
      {!status ? (
        R.path(['loading'], resultData) ? (
          <Loader />
        ) : null
      ) : (
        <React.Fragment>
          <ScoreContainer theme={theme} mobile={mobile}>
            <ScoreHeader mobile={mobile}>
              {t('result.just.texts.header')}
            </ScoreHeader>
            <Score mobile={mobile}>
              {correctOnes}/{questions}
            </Score>
            {status === 'won' ? (
              <ResultMessage mobile={mobile}>
                <div>{t('result.just.texts.message.a')}</div>
                <div>{t('result.just.texts.message.b')}</div>
              </ResultMessage>
            ) : null}
          </ScoreContainer>
          <PrizeContainer theme={theme} mobile={mobile}>
            10/
            {questions} ={' '}
            <span dir={rtl ? 'rtl' : 'ltr'}>{t('result.prize')}</span>
          </PrizeContainer>
          <PrizeImg theme={theme} mobile={mobile} />
          <ButtonContainer mobile={mobile}>
            {canPlay ? (
              <Button
                onClick={onPlayAgain(history, location)}
                text={t('play.again')}
                theme={theme}
              />
            ) : canBuy ? (
              <Button
                to={{ pathname: '/credits', search: location.search }}
                text={t('buy.button')}
                theme={theme}
              />
            ) : userStatus === 'UNSUBSCRIBED' || userStatus === 'REGISTERED' ? (
              <Button
                text={t('login.button.start')}
                theme={theme}
                onClick={onResubscribe(user, onChange, history, location)}
              />
            ) : (
              <div className={cantBuyClass(theme)}>
                {t('result.cantbuy.subheader.a')}
              </div>
            )}
          </ButtonContainer>
          <Link
            className={backHome}
            to={{ pathname: '/home', search: location.search }}
          >
            {t('result.back.home')}
          </Link>
          {status === 'won' ? (
            <Sound
              src={themeProp(['won', 'sound'], theme)}
              playing={status === 'won'}
            />
          ) : null}
        </React.Fragment>
      )}
    </ResultWrapper>
  )
}

export default withRouter(Mixed)
