import { useState, useEffect, useCallback, useRef } from 'react'

export function usePoller({ tick, action, interval }) {
  const [lastPoll, setLastPoll] = useState(new Date().getTime())
  const [calling, setCalling] = useState(false)
  const valid = useRef()

  const poll = useCallback(async () => {
    if (!action) return
    valid.current && setCalling(true)
    await action()
    valid.current && setLastPoll(new Date().getTime())
    valid.current && setCalling(false)
  }, [action])

  useEffect(() => {
    valid.current = true
    const shouldPoll =
      tick &&
      !calling &&
      interval > 0 &&
      lastPoll + interval <= new Date().getTime()
    if (shouldPoll) {
      poll()
    }
    return () => {
      valid.current = false
    }
  }, [poll, lastPoll, calling, interval, tick])
}
