import { useContext } from 'react'
import { ConfigurationContext } from 'containers/ConfigurationProvider'

export const useGtag = () => {
  const {
    meta: { gtagId, awConversionId },
  } = useContext(ConfigurationContext)

  const gtagSend = () =>
    awConversionId &&
    window.gtag &&
    window.gtag('event', 'conversion', {
      send_to: `${gtagId}/${awConversionId}`,
    })

  return { gtagSend }
}
