import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'react-emotion'
import { themeProp } from 'utils/theming'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { __RouterContext } from 'react-router'
import { I18nContext } from 'containers/I18nProvider'

const NavContainer = styled('div')`
  display: flex;
  height: 60px;
  padding: 0 80px;
  margin: 25px 0 0;
  line-height: 20px;
  justify-content: space-between;
  direction: ${({ rtl }) => (!rtl ? 'rtl' : 'ltr')};
  box-sizing: border-box;
  &.top {
    height: auto;
    padding: 0;
    margin: 0;
  }
  &.logoFirst {
    height: auto;
    padding: 0;
    margin: 0;
  }
`

const Nav = styled('ul')`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  text-align: center;
  font-size: 18px;
  color: ${({ theme }) => themeProp(['navmenu', 'color'], theme)};
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
  gap: 30px;
  > li {
    list-style: none;
    width: auto;
    > a {
      display: block;
      padding: 0 15px;

      &.active {
        font-weight: 900;
        border-bottom: 3px solid;
        padding: 0 15px 7px;
      }
    }
  }
`

const NavMenuItem = styled('li')`
  list-style: none;
`

const NavMenu = ({ items, top, logoFirst }) => {
  const {
    i18n: { t, rtl },
  } = useContext(I18nContext)
  const { location } = useContext(__RouterContext)
  const { theme } = React.useContext(ConfigurationContext)

  return (
    <NavContainer
      rtl={rtl}
      top={top}
      logoFirst={logoFirst}
      className={`${top ? 'top' : ''} ${logoFirst ? 'logoFirst' : ''}`}
    >
      <Nav theme={theme} rtl={rtl}>
        {items
          ? items.map((item, idx) => (
              <NavMenuItem key={idx}>
                <NavLink
                  to={{
                    pathname: item.url,
                    state: { returnTo: item.url },
                    search: location.search,
                  }}
                >
                  {t(item.textKey)}
                </NavLink>
              </NavMenuItem>
            ))
          : null}
      </Nav>
    </NavContainer>
  )
}

export default NavMenu
