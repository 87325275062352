/*global FB*/
import * as R from 'ramda'
import React, { useContext, useEffect } from 'react'
import styled from 'react-emotion'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { I18nContext } from 'containers/I18nProvider'

const FbButton = styled('a')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: 12px auto;
  border-radius: 100px;
  background-color: ${({ fbBackgroundColor }) => fbBackgroundColor};
  color: ${({ fbColor }) => fbColor};
  border: ${({ fbColor }) => `1px solid ${fbColor}`};
  text-align: center;
  padding: 10px 0;
  font-size: 12px;
  white-space: nowrap;
  &:hover {
    background-color: ${({ fbBackgroundColor }) => fbBackgroundColor};
    color: ${({ fbColor }) => fbColor};
    border: ${({ fbColor }) => `1px solid ${fbColor}`};
  }
`
const FbIcon = styled('div')`
  display: inline-block;
  width: 11px;
  height: 20px;
  margin: ${({ rtl }) => (rtl ? '0 0 0 5px' : '0 5px 0 0')};
  background-image: url(${({ fbImage }) => fbImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-color: ${({ fbColor }) => fbColor};
`

const location = window.location
const host = R.join('', [location.protocol, '//', location.host])

const share = () => evt => {
  evt.preventDefault()
  FB.ui(
    {
      method: 'share',
      href: host,
    },
    _res => {
      window.ga &&
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'CTA',
          eventAction: 'FB_SHARE',
          eventLabel: '',
        })
    }
  )
}

const FbShare = ({ btnStyleClass }) => {
  const {
    i18n: { t, rtl },
  } = useContext(I18nContext)
  const {
    meta: { fbColor, fbBackgroundColor, fbImage, fbAPPID },
  } = useContext(ConfigurationContext)

  useEffect(() => {
    if (!!fbAPPID) {
      window.fbAsyncInit = function() {
        FB.init({
          appId: fbAPPID,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v2.7',
        })
      }
    }
  }, [fbAPPID])
  useEffect(() => {
    ;(function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  })

  const hasFBId = !!fbAPPID
  return hasFBId ? (
    <React.Fragment>
      <div id="fb-root" />
      <FbButton
        className={btnStyleClass}
        fbColor={fbColor}
        fbBackgroundColor={fbBackgroundColor}
        onClick={share()}
      >
        {fbImage ? (
          <FbIcon rtl={rtl} fbImage={fbImage} fbColor={fbColor} />
        ) : null}
        <span>{t('fb.share')}</span>
      </FbButton>
    </React.Fragment>
  ) : null
}

export default FbShare
