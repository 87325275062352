import React, { useContext } from 'react'
import DrawSlider from 'components/DrawSlider'
import useMobileQuery from 'containers/MobileQuery'
import FeedSlider from 'components/FeedSlider'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { I18nContext } from 'containers/I18nProvider'

const LandingContent = () => {
  const {
    i18n: { t, rtl, lang },
  } = useContext(I18nContext)
  const { theme } = useContext(ConfigurationContext)
  const { isMobile } = useMobileQuery()

  return (
    <React.Fragment>
      <DrawSlider theme={theme} rtl={rtl} lang={lang} t={t} />
      {isMobile ? <FeedSlider /> : null}
    </React.Fragment>
  )
}

export default LandingContent
