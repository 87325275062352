import React, { useContext } from 'react'
import useMobileQuery from 'containers/MobileQuery'
import * as Layouts from './layouts'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { useAvailableHeight } from 'containers/AvailableHeight'
import { AuthenticationContext } from 'containers/AuthProvider'
import { I18nContext } from 'containers/I18nProvider'

const onResubscribe = onAuth => (user, onChange) => _ =>
  user.singleStepSubscription
    ? onAuth()
    : onChange({
        ...user,
        userStatus: undefined,
        pinPrompted: false,
        sms: true,
      })

const Welcome = ({ location }) => {
  const {
    i18n: { t, rtl },
  } = useContext(I18nContext)
  const {
    auth: { user, onChange, onAuth },
  } = useContext(AuthenticationContext)
  const [availableHeight] = useAvailableHeight()
  const { isMobile } = useMobileQuery()
  const { theme, layout } = React.useContext(ConfigurationContext)
  const { canBuy, userStatus, credits: crds } = user
  const credits = crds ? crds.length : undefined
  const canPlay = credits && credits > 0
  const gamePath = '/game-intro'

  const props = {
    location,
    rtl,
    t,
    theme,
    user,
    userStatus,
    onChange,
    onResubscribe: onResubscribe(onAuth),
    availableHeight,
    canBuy,
    canPlay,
    gamePath,
  }

  const device = isMobile ? layout.phone : layout.default
  const Layout = Layouts[device] || Layouts['DEFAULT']

  return <Layout {...props} />
}

export default Welcome
