import React from 'react'
import fitty from 'fitty'
import { css } from 'emotion'

const cls = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
  `,
}

const style = {
  display: `inline-block`,
  whiteSpace: `normal`,
}

export class TextFit extends React.Component {
  element
  thing
  fitted = false
  initialized = false

  componentDidMount() {
    this.fit()
  }

  componentDidUpdate() {
    this.fit()
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.children !== this.props.children ||
      nextProps.minSize !== this.props.minSize ||
      nextProps.maxSize !== this.props.maxSize ||
      nextProps.multiLine !== this.props.multiLine
    ) {
      this.fitted = false
    }
  }

  componentWillUnmount() {
    this.thing.unsubscribe()
  }

  fit() {
    if (this.fitted) {
      return
    }
    if (!this.initialized) {
      const { minSize, maxSize } = this.props
      this.thing = fitty(this.element, { minSize, maxSize })
    } else {
      this.thing.fit()
    }
    this.fitted = true
    this.initialized = true
  }

  makeRef = el => {
    this.element = el
  }

  render() {
    const { makeRef: ref } = this
    const { children, text } = this.props
    const props = { style, ref }
    return (
      <div className={cls.container}>
        <span {...props}>
          {text}
          {children}
        </span>
      </div>
    )
  }
}

export default TextFit
