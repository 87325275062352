import React from 'react'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import CircularProgressbar from 'react-circular-progressbar'
import styled, { css } from 'react-emotion'
import { themeProp } from 'utils/theming'
import { Private } from 'lib/auth-utils'
import { HeaderLogo } from 'layouts/index'

const pageClass = ({ theme, mobile, nologo }) => css`
  font-family: ${themeProp(['page', 'fontFamily'], theme)};
  color: ${themeProp(['page', 'color'], theme)};
  background-color: ${themeProp(['page', 'backgroundColor'], theme)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${mobile ? '50%' : '50%'};
  width: 100vw;
  height: 100dvh;
  display: flex;
  margin: 0 auto;
  flex-flow: column;
  transition: background-color 0.2s ease-in;
  > div:nth-child(1) {
    min-height: ${nologo ? 0 : '56px'};
    max-height: ${nologo ? 'fit-content' : '103px'};
  }
`
const PregameLogo = styled('div')`
  width: 100%;
  display: flex;
  justify-content: ${({ rtl }) => (rtl ? 'flex-start' : 'flex-end')};
  > a {
    margin: ${({ mobile }) => (mobile ? '15px' : '24px')};
    width: ${({ theme, mobile }) => {
      const width = themeProp(['header', 'logoWidth'], theme)
      return width && mobile ? width : mobile ? '53px' : '100px'
    }};
    height: ${({ theme, mobile }) => {
      const width = themeProp(['header', 'logoHeight'], theme)
      return width && mobile ? width : mobile ? '56px' : '100px'
    }};
  }
`

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin: 40px 0 0;
  padding: 0 20px;
`

const Container = styled('div')`
  background: white;
  position: relative;
  color: red;
  width: ${({ mobile }) => (mobile ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: ${({ mobile }) => (mobile ? '35px 10px' : '50px 10px')};
  font-size: 20px;
  line-height: 20px;
  background-image: url(${({ theme }) =>
    themeProp(['page', 'backgroundIcon'], theme)});
  background-repeat: repeat-x;
  background-position: center;
  background-size: 50%;
  box-sizing: border-box;
`

const Header = styled('div')`
  position: absolute;
  height: 30px;
  top: -15px;
  padding: ${({ mobile }) => (mobile ? '0 30px' : `0 45px`)};
  line-height: 30px;
  font-size: ${({ mobile }) => (mobile ? '14px' : `18px`)};
  font-weight: bold;
  border-radius: 8px;
  color: ${({ theme }) => themeProp(['page', 'headerColor'], theme)};
  background-color: ${({ theme }) =>
    themeProp(['page', 'headerBackground'], theme)};
`

const clockImgDesktop = css`
  width: 250px;
  height: 186px;
  margin: 40px 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`

const clockImgMobile = css`
  width: 150px;
  height: 112px;
  margin: 40px 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`
const CircleContainer = styled('div')`
  width: ${({ mobile }) => (mobile ? '100px' : `150px`)};
  background-color: white;
`

const CircleStylesDesktop = theme => ({
  text: {
    fill: `${themeProp(['countdown', 'textColor'], theme)}`,
    fontSize: '50px',
  },
  path: {
    stroke: `${themeProp(['countdown', 'pathColor'], theme)}`,
    strokeWidth: '4',
  },
  trail: {
    stroke: `${themeProp(['countdown', 'trailColor'], theme)}`,
    strokeWidth: '4',
  },
})

const CircleStylesMobile = theme => ({
  text: {
    fill: `${themeProp(['countdown', 'textColor'], theme)}`,
    fontSize: '35px',
  },
  path: {
    stroke: `${themeProp(['countdown', 'pathColor'], theme)}`,
    strokeWidth: '4',
  },
  trail: {
    stroke: `${themeProp(['countdown', 'trailColor'], theme)}`,
    strokeWidth: '4',
  },
})

const TextContainer = styled('div')`
  text-align: center;
`

const HeaderText = styled('div')`
  font-weight: bold;
  font-size: ${({ mobile }) => (mobile ? '15px' : `18px`)};
  padding: ${({ mobile }) => (mobile ? '20px 0 10px' : `30px 0 20px`)};
  color: ${({ theme }) => themeProp(['page', 'textColor'], theme)};
`

const MainText = styled('div')`
  font-size: ${({ mobile }) => (mobile ? '14px' : `17px`)};
  color: ${({ theme }) => themeProp(['page', 'textColor'], theme)};
`

const Layout = ({
  location,
  theme,
  t,
  rtl,
  auth,
  mobile,
  counter,
  percent,
  showHeaderLogo,
}) => {
  return (
    <Private user={auth.user}>
      <BackgroundImageWrapper
        imgsrc={themeProp(['page', 'backgroundImage'], theme)}
        className={pageClass({ theme, nologo: true })}
      >
        <PregameLogo rtl={rtl} mobile={mobile} theme={theme}>
          {showHeaderLogo ? (
            <HeaderLogo
              theme={theme}
              rtl={rtl}
              mobile={mobile}
              className={'logo1'}
              to={{ pathname: '/home', search: location.search }}
            />
          ) : null}
        </PregameLogo>

        <Wrapper>
          <Container theme={theme} mobile={mobile}>
            <Header theme={theme} mobile={mobile}>
              {t('pregame.header')}
            </Header>
            <CircleContainer mobile={mobile}>
              <CircularProgressbar
                theme={theme}
                mobile={mobile}
                percentage={percent}
                text={counter}
                counterClockwise={true}
                styles={
                  mobile
                    ? CircleStylesMobile(theme)
                    : CircleStylesDesktop(theme)
                }
              />
            </CircleContainer>
            {t('pregame.headText') !== ' ' || t('pregame.mainText') !== ' ' ? (
              <TextContainer>
                <HeaderText theme={theme} mobile={mobile}>
                  {t('pregame.headText')}
                </HeaderText>
                <MainText theme={theme} mobile={mobile}>
                  {t('pregame.mainText')}
                </MainText>
              </TextContainer>
            ) : null}
          </Container>
          <BackgroundImageWrapper
            mobile={mobile}
            className={mobile ? clockImgMobile : clockImgDesktop}
            imgsrc={`/images/clock.png`}
          />
        </Wrapper>
      </BackgroundImageWrapper>
    </Private>
  )
}

export default Layout
