import { useState, useEffect, useRef } from 'react'
import useInterval from 'containers/IntervalProvider'

export function useTicker() {
  const [tick, setTick] = useState(0)
  const [setIntervalTime] = useInterval(() => setTick(t => t + 1))
  const valid = useRef()

  useEffect(() => {
    valid.current = true
    setIntervalTime(1000)
    return () => {
      setIntervalTime(null)
      valid.current = false
    }
  }, [tick, setIntervalTime])

  return [tick, setTick]
}
