import React, { useContext } from 'react'
import Button from 'components/Button'
import Loader from 'components/Loader'
import FbShare from 'components/FbShare'
import Conditional from 'containers/Conditional'
import styled, { css } from 'react-emotion'
import * as Sound from 'react-howler'
import { themeProp } from 'utils/theming'
import { Link, withRouter } from 'react-router-dom'
import { backHome } from 'components/Result/layouts/Card'
import path from 'ramda/src/path'
import { I18nContext } from 'containers/I18nProvider'

const ResultWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${({ mobile }) => (mobile ? ' 0 15px 15px' : '0 ')};
  box-sizing: border-box;
`

const Motivation = styled('h2')`
  position: absolute;
  min-width: 0%;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 45px;
  margin: 0;
  height: 38px;
  line-height: 38px;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  color: ${({ theme }) => themeProp(['content', 'headerColor'], theme)};
  border-radius: 8px;
  background-color: ${({ theme }) =>
    themeProp(['content', 'headerBackgroundColor'], theme)};
  white-space: nowrap;
`

const ScoreContainer = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ mobile }) => (mobile ? '100%' : '500px')};
  justify-content: center;
  background-color: ${({ theme }) =>
    themeProp(['content', 'backgroundColor'], theme)};
  border-radius: 5px;
  color: ${({ theme }) => themeProp(['content', 'color'], theme)};
  padding: ${({ mobile }) => (mobile ? '15px' : '30px 100px')};
  margin: ${({ mobile }) => (mobile ? '10px 0 30px' : '10px 0 50px')};
  background: white;
  background-image: url(${({ theme }) => themeProp(['backgroundIcon'], theme)});
  background-repeat: repeat-x;
  background-position: center;
  background-size: 50%;
  box-sizing: border-box;
`

const ScoreHeader = styled('h3')`
  margin: ${({ mobile }) => (mobile ? '15px 0' : '0 0 15px')};
  font-size: ${({ mobile }) => (mobile ? '18px' : ' 22px')};
`

const Score = styled('span')`
  margin: ${({ mobile }) => (mobile ? ' 0 0 10px' : '0 0 10px')};
  font-size: ${({ mobile }) => (mobile ? '35px' : '50px')};
  color: ${({ theme }) =>
    themeProp(['content', 'dynamicQuestionColor'], theme)};
  font-weight: bold;
`

const Points = styled('span')`
  margin: ${({ mobile }) => (mobile ? ' 0 0 10px' : '0')};
  font-size: ${({ mobile }) => (mobile ? '18px' : ' 22px')};
  font-weight: bold;
`

const ResultMessage = styled('div')`
  font-size: ${({ mobile }) => (mobile ? '15px' : ' 22px')};
  margin: ${({ mobile }) => (mobile ? '0' : ' 0 0 30px')};
`

const PrizeContainer = styled('div')`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
  margin: ${({ mobile }) => (mobile ? '0 0 20px' : '0 0 40px')};
  font-size: ${({ mobile }) => (mobile ? '25px' : '30px')};
  color: ${({ theme }) => themeProp(['content', 'staticQuestionColor'], theme)};

  span {
    color: ${({ theme }) => themeProp(['content', 'scoreColor'], theme)};
  }
`
const ButtonContainer = styled('div')`
  margin-top: ${({ mobile }) => (mobile ? 'auto' : '0')};
  width: ${({ mobile }) => (mobile ? '100%' : '265px')};
`

const imageContainerClass = mobile => css`
  margin: ${mobile ? '0 auto 20px' : '30px auto 50px'};
  max-width: 100%;
`

const fbStylingClass = (theme, mobile) => css`
  position: absolute;
  width: auto;
  height: 30px;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 0 20px;
  font-size: ${mobile ? '15px' : '15px'};
  line-height: 30px;
  color: ${themeProp(['content', 'fbColor'], theme)};
  background-color: ${themeProp(['content', 'fbBackgroundColor'], theme)};
  border: none;

  &:hover {
    border: none;
    color: ${themeProp(['content', 'fbColor'], theme)};
    background-color: ${themeProp(['content', 'fbBackgroundColor'], theme)};
  }
`
const homeButtonClass = theme => css`
  color: ${themeProp(['content', 'backHomeColor'], theme)};
`
const cantBuyClass = theme => css`
  margin: 0 0 30px;
  color: ${themeProp(['content', 'scoreColor'], theme)};
`
const Mixed = ({
  status,
  theme,
  user = {},
  onChange,
  onPlayAgain,
  onResubscribe,
  resultData = {},
  history,
  location,
  mobile,
  questions,
}) => {
  const {
    i18n: { t, rtl },
  } = useContext(I18nContext)
  const { canBuy, userStatus, credits: crds, points } = user
  const { result: { numOfErrors, currentStep } = {} } = resultData
  const position = currentStep ? currentStep.position : 0
  const credits = crds ? crds.length : undefined
  const canPlay = credits && credits > 0
  const played =
    status === 'timeout' || status === 'abandoned'
      ? parseInt(position, 10) - 1
      : 10
  const correctOnes = played - parseInt(numOfErrors, 10)

  return (
    <ResultWrapper mobile={mobile}>
      {!status ? (
        path(['loading'], resultData) ? (
          <Loader />
        ) : null
      ) : (
        <React.Fragment>
          <ScoreContainer theme={theme} mobile={mobile}>
            <Motivation theme={theme} mobile={mobile}>
              {t(`result.${status}.motivation`)}
            </Motivation>
            <ScoreHeader mobile={mobile}>
              {t('result.just.texts.header')}
            </ScoreHeader>
            <Score theme={theme} mobile={mobile}>
              <span dir={rtl ? 'rtl' : 'ltr'}>
                <span>{correctOnes} </span>
                <span>/</span>
                <span>{questions}</span>
              </span>
            </Score>
            <Conditional condition="pointsEnabled">
              {points ? (
                <Points dir={rtl ? 'rtl' : 'ltr'} mobile={mobile}>
                  {t('mixed.points').replace('{p}', points)}
                </Points>
              ) : null}
            </Conditional>
            {status === 'won' ? (
              <img
                alt=""
                className={imageContainerClass(mobile)}
                src={themeProp(['winIcon'], theme)}
                onError={ev =>
                  (ev.target.src =
                    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
                }
              />
            ) : null}
            <Conditional condition="fbShareEnabled">
              <FbShare btnStyleClass={fbStylingClass(theme, mobile)} />
            </Conditional>
          </ScoreContainer>
          {status === 'won' ? (
            <ResultMessage mobile={mobile}>
              <div>{t('result.just.texts.message.a')}</div>
              <div>{t('result.just.texts.message.b')}</div>
            </ResultMessage>
          ) : (
            <PrizeContainer theme={theme} mobile={mobile} rtl={rtl}>
              <div>
                10/{questions} = <span>{t('result.prize')}</span>
              </div>
              <span>{t('result.mixed.message.a')}</span>
              <span>{t('result.mixed.message.b')}</span>
              <span>{t('result.mixed.message.c')}</span>
            </PrizeContainer>
          )}

          <ButtonContainer mobile={mobile} theme={theme}>
            {canPlay ? (
              <Button
                onClick={onPlayAgain(history, location)}
                text={t('play.again')}
                theme={theme}
              />
            ) : canBuy ? (
              <Button
                to={{ pathname: '/credits', search: location.search }}
                text={t('buy.button')}
                theme={theme}
              />
            ) : userStatus === 'UNSUBSCRIBED' || userStatus === 'REGISTERED' ? (
              <Button
                text={t('login.button.start')}
                theme={theme}
                onClick={onResubscribe(user, onChange, history, location)}
              />
            ) : (
              <div className={cantBuyClass(theme)}>
                {t('result.cantbuy.subheader.a')}
              </div>
            )}
          </ButtonContainer>
          <Link
            className={(backHome, homeButtonClass(theme))}
            to={{ pathname: '/home', search: location.search }}
          >
            {t('result.back.home')}
          </Link>
          {status === 'won' ? (
            <Sound
              src={themeProp(['won', 'sound'], theme)}
              playing={status === 'won'}
            />
          ) : null}
        </React.Fragment>
      )}
    </ResultWrapper>
  )
}

export default withRouter(Mixed)
