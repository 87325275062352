import * as R from 'ramda'
import React, {
  useReducer,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import { read, create, update } from '@logicea/xhr-helpers/lib'
import { getSessionTokens } from './utils'
import { useTicker } from 'containers/TickProvider'
import { usePoller } from './Poller'
import { __RouterContext } from 'react-router'
import { ConfigurationContext } from './ConfigurationProvider'

const QUESTION_TIME = 10
const POLLING_INTERVAL = 3000
const initialState = { loading: false, errors: null, game: {} }

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'GAME_PENDING':
      return { ...state, loading: true }
    case 'GAME_FAILURE':
      return {
        ...state,
        loading: false,
        errors: payload,
      }
    case 'GAME_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: null,
        game: payload,
      }
    case 'GA_ANALYTICS_EVENT':
      window.ga &&
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'GAME',
          eventAction: payload.action,
          eventLabel: payload.label,
        })
      return state
    default:
      throw new Error()
  }
}

const gamePending = () => ({ type: 'GAME_PENDING' })
const gameSuccess = payload => ({ type: 'GAME_SUCCESS', payload })
const gameFailure = payload => ({ type: 'GAME_FAILURE', payload })
const gaEvent = payload => ({ type: 'GA_ANALYTICS_EVENT', payload })

const useGame = () => {
  const {
    meta: { questionCountdown },
  } = React.useContext(ConfigurationContext)
  const { history } = useContext(__RouterContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [tick, setTick] = useTicker()
  let valid = useRef()

  const sessionTokens = getSessionTokens()
  const token = sessionTokens ? sessionTokens.access_token : undefined
  const gameId = R.path(['game', 'id'], state)

  const fetchGame = useCallback(
    async init => {
      if (!init && !gameId) return
      const method = init ? create : read
      const args = init ? ['/p10/game', {}, token] : ['/p10/game', token]
      try {
        !!valid.current && dispatch(gamePending())
        const game = await method.apply(this, args)
        !!valid.current && dispatch(gameSuccess(game))
        return game
      } catch (error) {
        !!valid.current && dispatch(gameFailure(error))
        history.push('/home')
      }
    },
    [history, gameId, token]
  )

  const answer = async selected => {
    const label = R.path(['game', 'currentStep', 'position'], state)
    try {
      const game = await update('/p10/game/answer', selected, token)
      !!valid.current && dispatch(gameSuccess(game))
      !!valid.current && dispatch(gaEvent({ action: 'ANSWER', label }))
    } catch (error) {
      !!valid.current && dispatch(gameFailure(error))
    }
  }

  usePoller({ tick, action: fetchGame, interval: POLLING_INTERVAL })

  useEffect(() => {
    valid.current = true
    R.isEmpty(state.game) && fetchGame(true)
    return () => {
      valid.current = false
    }
  }, [state.game, fetchGame])

  useEffect(() => {
    !!gameId && dispatch(gaEvent({ action: 'NEW_GAME' }))
  }, [gameId])
  return [
    {
      ...state,
      tick: {
        value: tick,
        max: questionCountdown ? questionCountdown : QUESTION_TIME,
        serverValue: R.path(['game', 'counter'], state),
        onReset: setTick,
      },
    },
    { answer },
  ]
}

export default useGame
