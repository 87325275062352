import { useState, useContext, useCallback, useEffect } from 'react'
import { I18nContext } from 'containers/I18nProvider'

export function useExternalTnc() {
  const {
    i18n: { lang },
  } = useContext(I18nContext)
  const [filePath, setFilepath] = useState(false)

  const fetchExternalTnc = useCallback(async () => {
    try {
      const response = await fetch(`/api/p10/public/tc?lang=${lang}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      setFilepath(data.path)
    } catch (e) {
      console.log({ ErrorWhileFetchingMessages: e })
    }
  }, [lang])

  useEffect(() => {
    fetchExternalTnc()
  }, [fetchExternalTnc])
  return filePath
}
