import * as R from 'ramda'
import React, { Fragment, useContext, useState, useEffect } from 'react'
import Button from 'components/Button'
import Loader from 'components/Loader'
import styled, { css, cx } from 'react-emotion'
import useInterval from 'containers/IntervalProvider'
import * as Sound from 'react-howler'
import { themeProp } from 'utils/theming'
import { Link, withRouter } from 'react-router-dom'
import { backHome } from 'components/Result/layouts/Card'
import path from 'ramda/src/path'
import { I18nContext } from 'containers/I18nProvider'
import WonPrizeSelection from 'components/WonPrizeSelection'
import DrawSlider from 'components/DrawSlider'
import { FeedContext } from 'containers/FeedProvider'

const SLOT_HEIGHTS = {
  normal: mobile => (mobile ? 30 : 60),
  active: mobile => (mobile ? 50 : 80),
}

const ResultWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: ${({ mobile }) => (mobile ? ' 15px' : '0 ')};
  box-sizing: border-box;
`
const ButtonContainer = styled('div')`
  width: ${({ mobile }) => (mobile ? '100%' : '235px')};
  margin: ${({ mobile }) => (mobile ? '0' : '0 0 20px')};
`
const homeButtonClass = css`
  color: white;
`
const cantBuyClass = theme => css`
  margin: 0 0 30px;
  color: ${themeProp(['content', 'scoreColor'], theme)};
`
const getInitialSlotPosition = (mobile, questions) => {
  const normal = SLOT_HEIGHTS.normal(mobile)
  const active = SLOT_HEIGHTS.active(mobile)
  return (questions - 2) * normal + active * 3
}

const getFinalSlotPosition = (mobile, correct) => {
  const normal = SLOT_HEIGHTS.normal(mobile)
  const active = SLOT_HEIGHTS.active(mobile)
  const correction = mobile ? 5 : -20
  return (correct + 2) * normal + active + correction
}

const cls = {
  won: mobile => css`
    font-size: ${mobile ? 20 : 26}px;
    margin: 0;
  `,
  pointsContainer: (mobile, rtl) => css`
    direction: ${rtl ? 'rtl' : 'ltr'};
    width: ${mobile ? 100 : 50}%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  `,
  slotMachine: mobile => css`
    position: relative;
    height: ${mobile ? 100 : 200}px;
    overflow: hidden;
    width: 100%;
  `,
  slots: (mobile, questions, correct, start) => css`
    position: absolute;
    top: -${getInitialSlotPosition(mobile, questions)}px;
    left: 0;
    width: 100%;
    transform: translateY(
      ${start ? getFinalSlotPosition(mobile, correct) : 0}px
    );
    transition: all 0.5s linear;
  `,
  pointsRow: (mobile, theme) => css`
    opacity: 0.5;
    width: 100%;
    height: ${SLOT_HEIGHTS.normal(mobile)}px;
    font-size: ${mobile ? 14 : 28}px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    &.active {
      font-size: ${mobile ? 20 : 38}px;
      height: ${SLOT_HEIGHTS.active(mobile)}px;
      opacity: 1;
    }
    > span {
      width: 40%;
    }
    > div {
      position: relative;
      > img {
        height: ${SLOT_HEIGHTS.normal(mobile) - 20}px;
        margin: 6px 0;
      }
      > div {
        color: ${R.path(['totalPoints', 'pointsColor'], theme)};
        position: absolute;
        top: ${SLOT_HEIGHTS.active(mobile) - 5}px;
        font-size: ${mobile ? 14 : 20}px;
        white-space: nowrap;
      }
    }
    &.active {
      div > img {
        height: ${SLOT_HEIGHTS.active(mobile)}px;
      }
    }
  `,
  totalPointsRow: (theme, mobile) => css`
    width: calc(100% + 30px);
    margin: 8px 0;
    font-size: ${mobile ? 24 : 34}px;
    background-color: ${R.path(['totalPoints', 'background'], theme)};
    color: ${R.path(['totalPoints', 'color'], theme)};
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
      margin: 0 4px;
    }
    > img {
      height: ${mobile ? 24 : 34}px;
    }
  `,
  totalPointsText: theme => css`
    color: ${R.path(['totalPoints', 'textColor'], theme)};
  `,
  totalPointsValue: theme => css`
    color: ${R.path(['totalPoints', 'pointsColor'], theme)};
  `,
  wonRow: css`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    font-size: 24px;
    font-weight: 600;
    > div {
      width: 33%;
      white-space: nowrap;
      > img {
        width: 100%;
      }
    }
  `,
  wonBanner: mobile => css`
    height: ${mobile ? 48 : 53}px;
    > img {
      height: 100%;
    }
  `,
}

const AnswerPoints = ({
  active = false,
  assets,
  answers,
  questions,
  mobile,
  theme,
  t,
}) => (
  <div className={cx([cls.pointsRow(mobile, theme), active ? 'active' : ''])}>
    <span>
      {answers} / {questions}
    </span>
    <div>
      <img
        alt=""
        src={active ? assets['image'] : 'images/step.png'}
        onError={ev =>
          (ev.target.src =
            'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
        }
      />
      {active ? <div>{t(assets['bonusText'])}</div> : null}
    </div>
    <span>{t(assets['text'])}</span>
  </div>
)

const PointsWon = ({
  theme,
  questions,
  correct,
  points,
  mobile,
  status,
  rtl,
  t,
}) => {
  const [startSlot, setStartSlot] = useState(false)
  const [setIntervalTime] = useInterval(() => setStartSlot(_ss => true))
  const hasWonPoints = correct > 0
  const hasTimeout = status === 'TIMEOUT'
  const hasWon = status === 'WON'
  const allPoints = R.propOr([], ['points'], theme)

  useEffect(() => {
    !startSlot && setIntervalTime(100)
    return () => setIntervalTime(null)
  }, [setIntervalTime, startSlot])
  const { feed } = React.useContext(FeedContext)
  const feedType = feed.feedType || 'POINTS'
  return (
    <div className={cls.pointsContainer(mobile, rtl)}>
      {hasTimeout ? (
        <div className={cls.won(mobile)}>{t('result.timeout.motivation')}</div>
      ) : null}
      {hasWonPoints || hasWon ? (
        <div className={cls.won(mobile)}>{t('result.you.won')}</div>
      ) : null}
      {!hasWon ? (
        <div className={cls.slotMachine(mobile)}>
          <div className={cls.slots(mobile, questions, correct, startSlot)}>
            {R.reverse(allPoints).map((point, idx) => (
              <AnswerPoints
                key={idx}
                assets={point}
                active={idx === questions - correct}
                answers={questions - idx}
                questions={questions}
                mobile={mobile}
                theme={theme}
                t={t}
              />
            ))}
          </div>
        </div>
      ) : (
        <Fragment>
          <div className={cls.wonRow}>
            <div>
              {questions} / {questions}
            </div>
            <div>
              <img
                alt=""
                src={allPoints[questions]?.bonusImage}
                onError={ev =>
                  (ev.target.src =
                    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
                }
              />
            </div>
            <div>{t(allPoints[questions]?.text)}</div>
          </div>
          <div className={cls.wonBanner(mobile)}>
            <img
              alt=""
              src={allPoints[questions]?.image}
              onError={ev =>
                (ev.target.src =
                  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
              }
            />
          </div>
        </Fragment>
      )}
      {points && feedType !== 'NONE' ? (
        <div className={cls.totalPointsRow(theme, mobile)}>
          <span className={cls.totalPointsText(theme)}>
            {t('total.points')}
          </span>
          <img
            alt=""
            src={R.path(['totalPoints', 'image'], theme)}
            onError={ev =>
              (ev.target.src =
                'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
            }
          />
          <span className={cls.totalPointsValue(theme)}>{points}</span>
        </div>
      ) : null}
    </div>
  )
}

const SelectWinningPrize = ({
  status,
  theme,
  user = {},
  onChange,
  onPlayAgain,
  onResubscribe,
  resultData = {},
  history,
  location,
  mobile,
  questions,
}) => {
  const {
    i18n: { t, rtl, lang },
  } = useContext(I18nContext)
  const { canBuy, userStatus, credits: crds, points } = user
  const {
    result: { numOfErrors, currentStep, gameStatus, id: gameId } = {},
  } = resultData
  const position = currentStep ? currentStep.position : 0
  const credits = crds ? crds.length : undefined
  const canPlay = credits && credits > 0
  const played =
    status === 'timeout' || status === 'abandoned'
      ? parseInt(position, 10) - 1
      : 10
  const correctOnes = played - parseInt(numOfErrors, 10)

  return (
    <ResultWrapper mobile={mobile}>
      {!status ? (
        path(['loading'], resultData) ? (
          <Loader />
        ) : null
      ) : (
        <Fragment>
          <PointsWon
            status={gameStatus}
            theme={theme}
            questions={parseInt(questions || 10, 10)}
            correct={correctOnes}
            points={points}
            mobile={mobile}
            rtl={rtl}
            t={t}
          />
          {status.toLowerCase() === 'won' ? (
            <WonPrizeSelection gameId={gameId} />
          ) : (
            <DrawSlider theme={theme} rtl={rtl} lang={lang} t={t} />
          )}
          <ButtonContainer mobile={mobile} theme={theme}>
            {canPlay ? (
              <Button
                onClick={onPlayAgain(history, location)}
                text={t('play.again')}
                theme={theme}
              />
            ) : canBuy ? (
              <Button
                to={{ pathname: '/credits', search: location.search }}
                text={t('buy.button')}
                theme={theme}
              />
            ) : userStatus === 'UNSUBSCRIBED' || userStatus === 'REGISTERED' ? (
              <Button
                text={t('login.button.start')}
                theme={theme}
                onClick={onResubscribe(user, onChange, history, location)}
              />
            ) : (
              <div className={cantBuyClass(theme)}>
                {t('result.cantbuy.subheader.a')}
              </div>
            )}
            <div>{t('footer.ondemand')}</div>
            <Link
              className={(backHome, homeButtonClass)}
              to={{ pathname: '/home', search: location.search }}
            >
              {t('result.back.home')}
            </Link>
            {status === 'won' ? (
              <Sound
                src={themeProp(['won', 'sound'], theme)}
                playing={status === 'won'}
              />
            ) : null}
          </ButtonContainer>
        </Fragment>
      )}
    </ResultWrapper>
  )
}

export default withRouter(SelectWinningPrize)
