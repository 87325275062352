import * as R from 'ramda'
import React from 'react'
import useMobileQuery from 'containers/MobileQuery'
import styled, { css } from 'react-emotion'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import { useAvailableHeight } from 'containers/AvailableHeight'
import { themeProp } from 'utils/theming'
import { withRouter } from 'react-router-dom'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { FeedContext } from 'containers/FeedProvider'

const Container = styled('div')`
  width: 100%;
  height: 100%;
`

const contentClass = css`
  position: relative;
  height: inherit;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
`

const Header = styled('h2')`
  position: absolute;
  min-width: 0%;
  top: 0;
  left: 50%;
  padding: 0 45px;
  transform: translate(-50%, -50%);
  margin: 0;
  height: 38px;
  line-height: 38px;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  border-radius: 8px;
  color: ${({ theme }) => themeProp(['content', 'titleColor'], theme)};
  background-color: ${({ theme }) =>
    themeProp(['content', 'titleBackgroundColor'], theme)};
  z-index: 1;
  white-space: nowrap;
`
const mobileContentClass = (location, availableHeight, feedType) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 30px;
  height: ${feedType !== 'NONE'
    ? availableHeight - 433
    : availableHeight - 385}px;
  > div {
    height: ${location.pathname !== '/home' &&
    !location.pathname.includes(';jsession')
      ? 'auto'
      : '100%'};
  }
`

const MobileHeader = styled('h2')`
  position: absolute;
  left: 50%;
  top: -15px;
  transform: translateX(-50%);
  margin: 0;
  font-weight: normal;
  text-align: center;
  border-radius: 8px;
  color: ${({ theme }) => themeProp(['content', 'titleColor'], theme)};
  background-color: ${({ theme }) =>
    themeProp(['content', 'titleBackgroundColor'], theme)};
  z-index: 1;
  width: 75%;
  height: 35px;
  line-height: 35px;
  font-size: 18px;
`

const Content = ({ header, children, location }) => {
  const { theme } = React.useContext(ConfigurationContext)
  const { isMobile } = useMobileQuery()
  const [availableHeight] = useAvailableHeight()
  const { feed } = React.useContext(FeedContext)
  const feedType = feed.feedType || 'POINTS'

  return (
    <Container>
      <BackgroundImageWrapper
        className={
          isMobile
            ? mobileContentClass(location, availableHeight, feedType)
            : contentClass
        }
        theme={theme}
        imgsrc={themeProp(['content', 'backgroundImage'], theme)}
      >
        {header ? (
          isMobile ? null : (
            <Header theme={theme}>{header}</Header>
          )
        ) : null}
        {isMobile ? (
          <div style={{ position: 'relative' }}>
            {header ? (
              <MobileHeader theme={theme}>{header}</MobileHeader>
            ) : null}
            {children}
          </div>
        ) : (
          children
        )}
      </BackgroundImageWrapper>
    </Container>
  )
}

export default R.compose(withRouter)(Content)
