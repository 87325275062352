import React from 'react'
import FontAwesome from 'react-fontawesome'
import styled from 'react-emotion'

const Wrapper = styled('div')`
  width: 100vw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Overlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoadingOverlay = ({ children }) => (
  <Wrapper>
    {children}
    <Overlay>
      <FontAwesome name="cog" className="fa-spin fa-5x fa-fw" />
    </Overlay>
  </Wrapper>
)

export default LoadingOverlay
