import * as R from 'ramda'
import React, { Component } from 'react'
import { getEmptyImage } from 'react-dnd-html5-backend'
import DropTarget from 'react-dnd/lib/DropTarget'
import DragSource from 'react-dnd/lib/DragSource'

function getStyles(props) {
  const { isDragging } = props

  return {
    opacity: isDragging ? 0 : 1,
  }
}

const Types = {
  ANSWER: 'answer',
}

const answerTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) return

    // Determine rectangle on screen
    // const { decoratedComponentInstance } = component
    const hoverBoundingRect = component.decoratedComponentInstance.node.getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

    // Time to actually perform the action
    props.onMove(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  },
}

function targetCollect(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  }
}

const answerSource = {
  beginDrag(props) {
    const {
      id,
      index,
      children: {
        props: { theme, children: text },
      },
    } = props

    return {
      id: id,
      index: index,
      theme: theme,
      text: text,
    }
  },
}

function sourceCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
}

class DragAnswer extends Component {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    })
  }

  render() {
    const { connectDropTarget, connectDragSource, children } = this.props
    const refEl = el => {
      this.node = el
    }

    return connectDragSource(
      connectDropTarget(
        <div ref={refEl} style={getStyles(this.props)}>
          {children}
        </div>
      )
    )
  }
}

export default R.compose(
  DropTarget(Types.ANSWER, answerTarget, targetCollect),
  DragSource(Types.ANSWER, answerSource, sourceCollect)
)(DragAnswer)
