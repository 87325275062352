import React, { useState, useContext, useEffect } from 'react'
import { AuthenticationContext } from 'containers/AuthProvider'
import Helmet from 'react-helmet'

const Antifraud = () => {
  const { auth } = useContext(AuthenticationContext)
  const user = auth ? auth.user : {}
  const { operatorId, antifraudUrl, subscription } = user
  const filteredAntifraudUrl = antifraudUrl?.find(
    au => au.operatorId === operatorId
  )?.url
  const [script, setScript] = useState('')
  const { onAntifraudLoaded } = auth

  useEffect(() => {
    const getAntifraudScript = async filteredAntifraudUrl => {
      try {
        const response = await fetch(filteredAntifraudUrl, {
          method: 'GET',
        })
        const data = await response.json()
        onAntifraudLoaded()
        return data.s
      } catch (error) {
        console.info(error)
      }
    }
    if (!!filteredAntifraudUrl && script === '' && !subscription === false) {
      getAntifraudScript(filteredAntifraudUrl).then(script => {
        setScript(script)
        const event = new Event('DCBProtectRun')
        document.dispatchEvent(event)
      })
    }
  }, [filteredAntifraudUrl, script, onAntifraudLoaded, subscription])

  return script !== '' ? (
    <Helmet>
      <script id="test" defer>
        {`${script}`}
      </script>
    </Helmet>
  ) : null
}

export default Antifraud
