import React, { useContext } from 'react'
import { css } from 'react-emotion'
import { I18nContext } from 'containers/I18nProvider'
import { AuthenticationContext } from 'containers/AuthProvider'

const CheckmarkContainer = (rtl, mobile) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${rtl ? 'row-reverse' : 'row'};
  position: relative;
  font-size: ${mobile ? '11px' : '12px'};
  text-align: ${rtl ? 'right' : 'left'};
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  gap: 10px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 1;

    &:checked ~ .checkmark {
      background-color: #2196f3;
      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    z-index: 0;

    &:after {
      content: '';
      display: none;
      position: absolute;
      left: 6px;
      top: 0px;
      width: 6px;
      height: 12px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`

const TermsCheckBox = ({ onChangeEvent, mobile }) => {
  const {
    i18n: { t, rtl },
  } = useContext(I18nContext)
  const { auth } = useContext(AuthenticationContext)
  const { termsStatus } = auth
  return (
    <div className={CheckmarkContainer(rtl, mobile)}>
      <input type="checkbox" onChange={onChangeEvent} checked={termsStatus} />
      <div className="checkmark"></div>
      <span>{t('login.terms.checkbox')}</span>
    </div>
  )
}

export default TermsCheckBox
