import * as Layouts from './Layouts'
import React, { useContext, useState, useCallback } from 'react'
import useMobileQuery from 'containers/MobileQuery'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { I18nContext } from 'containers/I18nProvider'
import { AuthenticationContext } from 'containers/AuthProvider'

const SubscriptionConsent = ({
  customConsent,
  customClose,
  location,
  history,
}) => {
  const {
    i18n: { t, rtl },
  } = useContext(I18nContext)
  const { layout, theme } = useContext(ConfigurationContext)
  const { auth } = useContext(AuthenticationContext)
  const { isMobile } = useMobileQuery()
  const [consentState, setConsentState] = useState({ agreed: false })
  const { agreed } = consentState
  const toggleAgreed = useCallback(
    () => setConsentState(() => ({ agreed: !agreed })),
    [setConsentState, agreed]
  )
  const user = auth ? auth.user : {}
  const { onSubscribe, onChange } = auth
  const device = isMobile ? layout.phone : layout.default
  const Layout = Layouts[device] || Layouts['DEFAULT']

  return (
    <Layout
      history={history}
      location={location}
      rtl={rtl}
      t={t}
      mobile={isMobile}
      theme={theme}
      user={user}
      onChange={onChange}
      onClose={customClose}
      onSubscribe={customConsent || onSubscribe}
      agreed={agreed}
      toggleAgreed={toggleAgreed}
    />
  )
}

export default SubscriptionConsent
