import React, {
  useReducer,
  useCallback,
  useRef,
  useContext,
  useMemo,
} from 'react'
import { read } from '@logicea/xhr-helpers/lib'
import { AuthenticationContext } from './AuthProvider'

export const PopupContext = React.createContext()

const initialState = {
  loading: false,
  errors: null,
  popup: [],
}

const popupPending = () => ({ type: 'POPUP_PENDING' })
const popupSuccess = payload => ({ type: 'POPUP_SUCCESS', payload })
const popupFailure = payload => ({ type: 'POPUP_FAILURE', payload })

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'POPUP_PENDING':
      return { ...state, loading: true }
    case 'POPUP_FAILURE':
      return { ...state, loading: false, errors: payload }
    case 'POPUP_SUCCESS':
      return { ...state, loading: false, errors: undefined, popup: payload }
    default:
      throw new Error()
  }
}

const usePopup = category => {
  const { auth } = useContext(AuthenticationContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    user: { token = '' },
  } = auth
  let valid = useRef()
  const fetchPopup = useCallback(async () => {
    try {
      !!valid.current && dispatch(popupPending())
      const popup = !category
        ? await read('/p10/public/popup')
        : await read(`/p10/user/popup?category=${category}`, token)
      !!valid.current && dispatch(popupSuccess(popup))
    } catch (e) {
      !!valid.current && dispatch(popupFailure(e))
    }
  }, [dispatch, valid, token, category])

  useMemo(() => {
    valid.current = true
    fetchPopup()
    return () => {
      valid.current = false
    }
  }, [fetchPopup, valid])

  const { popup } = state
  return [popup]
}

export default usePopup
