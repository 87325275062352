import 'utils/polyfills'
import React, { lazy, Suspense } from 'react'
import Setup from 'components/Setup'
import AuthProvider from 'containers/AuthProvider'
import ConfigurationProvider from 'containers/ConfigurationProvider'
import Notification from 'components/Notification'
import HE from 'lib/headerEnrichment/HE'
import MetaTags from 'components/MetaTags'
import Analytics from 'components/Analytics'
import I18nProvider from 'containers/I18nProvider'
import Welcome from 'components/Welcome'
import GameIntro from 'components/GameIntro'
import Game from 'components/Game'
import Result from 'components/Result'
import Home from 'components/Home'
import Land from 'components/Land'
import ThankYou from 'components/ThankYou'
import FeedProvider from 'containers/FeedProvider'
import PopupContent from 'components/PopupContent'

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ContentLayout, OverlayLayout, ModalLayout } from 'layouts'
import LoadingOverlay from 'components/LoadingOverlay'
import PreGame from 'components/PreGame'
import SelectGamePrize from 'components/SelectGamePrize'
import SelectGame from 'components/SelectGame'
import UniversalStaticPixels from 'containers/UniversalStaticPixel'

const Account = lazy(() => import('components/Account'))
const DailyContent = lazy(() => import('components/DailyContent'))
const OptOut = lazy(() => import('components/OptOut'))
const HowTo = lazy(() => import('components/HowTo'))
const Prizes = lazy(() => import('components/Prizes'))
const TC = lazy(() => import('components/TC'))
const FAQS = lazy(() => import('components/FAQS'))
const Winners = lazy(() => import('components/Winners'))
const Credits = lazy(() => import('components/Credits'))
const Wallet = lazy(() => import('components/Wallet'))
const Transactions = lazy(() => import('components/Transactions'))
const WalletInfo = lazy(() => import('components/WalletInfo'))
const HowToOverlay = lazy(() => import('components/HowToOverlay'))
const PrizesOverlay = lazy(() => import('components/PrizesOverlay'))

const App = () => (
  <BrowserRouter>
    <Setup />
    <ConfigurationProvider>
      <I18nProvider>
        <AuthProvider>
          <MetaTags />
          <UniversalStaticPixels />
          <Analytics />
          <FeedProvider>
            <HE />
            <Notification />
            <PopupContent />
            <Suspense fallback={<LoadingOverlay />}>
              <Switch>
                <Route path="/welcome" component={Welcome} />
                <Route path="/game-intro" component={GameIntro} />
                <Route path="/pregame" component={PreGame} />
                <Route path="/game-prize" component={SelectGamePrize} />
                <Route path="/game" component={Game} />
                <Route path="/result" component={Result} />
                <Route path="/thankyou" component={ThankYou} />
                <Route path="/opt-out" component={OptOut} />
                <Route path="/select-game" component={SelectGame} />
              </Switch>
            </Suspense>
            <ContentLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <Switch>
                  <Route path="/" exact={true} component={Land} />
                  <Route path="/home" component={Home} />
                  <Route path="/howto" component={HowTo} />
                  <Route path="/prizes" component={Prizes} />
                  <Route path="/account" component={Account} />
                  <Route path="/daily-content" component={DailyContent} />
                  <Route component={Home} />
                </Switch>
              </Suspense>
            </ContentLayout>
            <ModalLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <Route path="/credits" exact={true} component={Credits} />
              </Suspense>
              <Suspense fallback={<LoadingOverlay />}>
                <Route path="/wallet" exact={true} component={Wallet} />
                <Route
                  path="/wallet-info"
                  exact={true}
                  component={WalletInfo}
                />
              </Suspense>
            </ModalLayout>

            <OverlayLayout>
              <Suspense fallback={<LoadingOverlay />}>
                <Switch>
                  <Route path="/faqs" component={FAQS} />
                  <Route path="/winners" component={Winners} />
                  <Route path="/tc" component={TC} />
                  <Route path="/transactions" component={Transactions} />
                  <Route path="/howtooverlay" component={HowToOverlay} />
                  <Route path="/prizesoverlay" component={PrizesOverlay} />
                </Switch>
              </Suspense>
            </OverlayLayout>
          </FeedProvider>
        </AuthProvider>
      </I18nProvider>
    </ConfigurationProvider>
  </BrowserRouter>
)

export default App
