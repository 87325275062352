import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'react-emotion'
import { themeProp } from 'utils/theming'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { __RouterContext } from 'react-router'
import { I18nContext } from 'containers/I18nProvider'
import { HeaderLogo } from 'layouts/index'
import useMobileQuery from 'containers/MobileQuery'

const Header = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding: 3px 0;
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
  background-color: ${({ theme }) =>
    themeProp(['header', 'backgroundColor'], theme)};
  border-bottom: ${({ theme }) => themeProp(['header', 'borderBottom'], theme)};
`

const TopLeft = styled('div')`
  padding: ${({ rtl }) => (rtl ? '0 15px 0 10px' : '0 10px 0 15px')};
  height: 100%;
  display: flex;
  align-items: center;
  > span:nth-child(2) {
    margin: 0 11px;
    font-size: 16px;
    color: ${({ theme }) => themeProp(['header', 'color'], theme)};
  }
`

const TopMiddle = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: ${({ toplogo }) => (toplogo ? 'auto' : 'unset')};
`

const TopRight = styled('div')`
  padding: 15px 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => themeProp(['header', 'color'], theme)};
  z-index: 4;
`

const MenuImg = styled('div')`
  display: inline-block;
  width: 18px;
  height: 14px;
  background: ${({ theme, menuState }) => {
    const imgname = menuState ? 'menuImgOpen' : 'menuImgClose'
    const imgurl = themeProp(['usermenu', imgname], theme)
    return imgurl ? `url(${imgurl}) no-repeat` : 'none'
  }};
  background-size: contain;
  background-position: center;
`

const borderedLogoContainer = (rtl, theme) => css`
  display: inherit;
  border-left: ${rtl
    ? `none`
    : `1px solid ${themeProp(['header', 'borderedLogoColor'], theme)}`};
  border-right: ${rtl
    ? `1px solid ${themeProp(['header', 'borderedLogoColor'], theme)}`
    : `none`};
  margin: ${rtl ? '0 15px 0 0' : '0 0 0 15px'};
  padding: ${rtl ? '0 15px 0 0' : '0 0 0 15px'};
`

const getMenuState = location => !(location.state && location.state.menu)

const MobileHeader = ({
  topLogo = false,
  reverseLogo,
  borderedLogos = false,
  logoFirst = false,
}) => {
  const {
    i18n: { rtl },
  } = useContext(I18nContext)
  const { theme } = useContext(ConfigurationContext)
  const { location } = useContext(__RouterContext)
  const menuState = getMenuState(location)
  const currentpathname = location.pathname
  const { isMobile } = useMobileQuery()

  return (
    <Header rtl={rtl} theme={theme} toplogo={Number(topLogo)}>
      <TopLeft theme={theme} rtl={rtl}>
        <HeaderLogo
          mobile={isMobile ? 'true' : 'false'}
          theme={theme}
          to={{ pathname: '/home', search: location.search }}
          className={`${
            currentpathname === '/select-game' ? 'unclickable' : ''
          } ${reverseLogo || borderedLogos || logoFirst ? 'logo2' : 'logo1'}`}
        />
        {borderedLogos ? (
          <div className={borderedLogoContainer(rtl, theme)}>
            <HeaderLogo
              mobile={isMobile ? 'true' : 'false'}
              theme={theme}
              to={{ pathname: '/home', search: location.search }}
              className={`${'unclickable'} ${'logo1'}`}
            />
          </div>
        ) : null}
      </TopLeft>
      {borderedLogos || logoFirst ? null : (
        <TopMiddle theme={theme} rtl={rtl} toplogo={Number(topLogo)}>
          <HeaderLogo
            mobile={isMobile ? 'true' : 'false'}
            theme={theme}
            to={{ pathname: '/home', search: location.search }}
            className={`${'unclickable'} ${reverseLogo ? 'logo1' : 'logo2'}`}
          />
        </TopMiddle>
      )}
      <TopRight theme={theme} rtl={rtl}>
        <NavLink
          to={{
            pathname: location.pathname,
            search: location.search,
            state: { menu: menuState },
          }}
        >
          <MenuImg theme={theme} menuState={menuState} />
        </NavLink>
      </TopRight>
    </Header>
  )
}

export default MobileHeader
