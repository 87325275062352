import * as R from 'ramda'
import React, { useContext, useEffect, useState } from 'react'
import useInterval from 'containers/IntervalProvider'
import { I18nContext } from 'containers/I18nProvider'
import { css, cx } from 'react-emotion'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { themeProp } from 'utils/theming'
import { FeedContext } from 'containers/FeedProvider'

const POINTS_RECORD_HEIGHT = 21

const cls = {
  pointsSlider: (idx, rtl) => css`
    direction: ${rtl ? 'rtl' : 'ltr'};
    height: 64px;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    > div {
      transition: ${idx ? 'all 1s' : 'none'};
      position: absolute;
      white-space: nowrap;
      width: fit-content;
      max-width: -webkit-fill-available;
      display: flex;
      flex-direction: column;
      top: ${POINTS_RECORD_HEIGHT * 2 - idx * POINTS_RECORD_HEIGHT}px;
      > div {
        display: flex;
        opacity: 0;
        font-size: 12px;
      }
      > div:nth-child(${idx + 1}) {
        opacity: 1;
      }
      > div:nth-child(${idx}) {
        opacity: 0.5;
      }
      > div:nth-child(${idx - 1}) {
        opacity: 0.2;
      }
    }
    &.flag {
      width: 16px;
    }
  `,
  rowWrapper: css`
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    height: ${POINTS_RECORD_HEIGHT}px;
    margin: 0 auto;
    > * {
      margin: 0 2px;
    }
  `,
  points: theme => css`
    color: ${themeProp(['page', 'pointSlideColor'], theme)};
  `,
  winnersName: css`
    flex: 0 1 auto;
    overflow: hidden;
  `,
}

const PointsFeed = ({ theme, lang, records, t }) => {
  return (
    <div>
      {records && records.length
        ? records.map((record, idx) => (
            <div key={idx} className={cx(['react-tel-input', cls.rowWrapper])}>
              {record?.country?.flag ? (
                <div
                  className={cx(['flag', R.toLower(record?.country?.flag)])}
                />
              ) : null}
              <span>{t('feedslider.a.player.from')} </span>
              <span>
                {record?.country?.name && record?.country?.name.strings
                  ? record?.country?.name.strings[lang]
                  : null}
              </span>
              <span> {t('feedslider.just.earned')} </span>
              <span className={cls.points(theme)}>{record?.points} </span>
              <span className={cls.points(theme)}>
                {t('feedslider.points')}!
              </span>
            </div>
          ))
        : null}
    </div>
  )
}

const WinnersFeed = ({ theme, lang, records, t }) => {
  return (
    <div>
      {records && records.length
        ? records.map((record, idx) => (
            <div key={idx} className={cx(['react-tel-input', cls.rowWrapper])}>
              {record.country.flag ? (
                <div className={cx(['flag', R.toLower(record.country.flag)])} />
              ) : null}
              <span className={cls.winnersName}>
                {record.name && record.name.strings
                  ? record.name.strings[lang]
                  : null}
              </span>
              <span> {t('feedslider.just.earned')} </span>
              <span className={cls.points(theme)}>
                {record.prize && record.prize.strings
                  ? record.prize.strings[lang]
                  : null}{' '}
              </span>
            </div>
          ))
        : null}
    </div>
  )
}
const CSVFeed = ({ lang, records }) => {
  return (
    <div>
      {records && records.length
        ? records.map((record, idx) => (
            <div key={idx} className={cx(['react-tel-input', cls.rowWrapper])}>
              <div>
                <span>
                  {record.text && record.text.strings
                    ? record.text.strings[lang]
                    : null}
                </span>
              </div>
            </div>
          ))
        : null}
    </div>
  )
}

const FeedSlider = () => {
  const {
    i18n: { t, rtl, lang },
  } = useContext(I18nContext)
  const { theme } = useContext(ConfigurationContext)
  const [pointIndex, setPointerIndex] = useState(0)
  const [setIntervalTime] = useInterval(() => setPointerIndex(t => t + 1))
  const { feed } = React.useContext(FeedContext)
  const feedType = feed.feedType || 'POINTS'
  const records = R.pathOr([], ['data'], feed)

  const FeedComponent =
    feedType === 'WINNERS'
      ? WinnersFeed
      : feedType === 'CSV'
      ? CSVFeed
      : PointsFeed

  useEffect(() => {
    if (pointIndex === records.length) {
      setPointerIndex(0)
    } else {
      setIntervalTime(4 * 1000)
    }
    return () => setIntervalTime(null)
  }, [pointIndex, setPointerIndex, records.length, setIntervalTime])

  return (
    <div className={cls.pointsSlider(pointIndex, rtl)}>
      <FeedComponent theme={theme} lang={lang} records={records} t={t} />
    </div>
  )
}

export default FeedSlider
