import React, { useContext } from 'react'
// import ResultProvider from 'containers/ResultProvider'
import compose from 'ramda/src/compose'
import unless from 'ramda/src/unless'
import isNil from 'ramda/src/isNil'
import pathOr from 'ramda/src/pathOr'
import toLower from 'ramda/src/toLower'
import useMobileQuery from 'containers/MobileQuery'
import * as Layouts from './layouts'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { AuthenticationContext } from 'containers/AuthProvider'
import useResult from 'containers/ResultProvider'
import { css } from 'react-emotion'
import { HeaderLogo } from 'layouts/index'
import { I18nContext } from 'containers/I18nProvider'
import { Private } from 'lib/auth-utils'
import { themeProp } from 'utils/theming'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'

const getGameStatus = data =>
  compose(unless(isNil, toLower), pathOr(null, ['result', 'gameStatus']))(data)

const onPlayAgain = gamePath => (history, location) => _ => {
  window.ga &&
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'CTA',
      eventAction: 'PLAY_AGAIN',
      eventLabel: '',
    })
  history.push({ pathname: gamePath, search: location.search })
}

const onResubscribe = onAuth => (user, onChange, history, location) => _ => {
  user.singleStepSubscription
    ? onAuth()
    : onChange({
        ...user,
        userStatus: undefined,
        pinPrompted: false,
        sms: true,
      })
  history.push({ pathname: '/home', search: location.search })
}

const pageClass = ({ theme }) => css`
  font-family: ${themeProp(['page', 'fontFamily'], theme)};
  color: ${themeProp(['page', 'color'], theme)};
  background-color: ${themeProp(['page', 'backgroundColor'], theme)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 100vw;
  height: 100dvh;
  max-height: -webkit-fill-available;
  display: flex;
  margin: 0 auto;
  flex-flow: column;
  transition: background-color 0.2s ease-in;
`
const ResultLogo = (rtl, isMobile, theme) => css`
  display: flex;
  width: 100%;
  justify-content: ${rtl ? 'flex-start' : 'flex-end'};
  min-height: ${theme.nologo ? 0 : 'auto'};
  max-height: ${theme.nologo ? 'fit-content' : 'none'};
  > a {
    margin: ${isMobile ? '15px' : '24px'};
  }
`

const Result = ({ location }) => {
  const [resultData] = useResult()
  const { auth } = useContext(AuthenticationContext)
  const {
    theme,
    layout,
    meta: { questions },
  } = React.useContext(ConfigurationContext)
  const {
    i18n: { rtl },
  } = useContext(I18nContext)
  const status = getGameStatus(resultData)
  const { isMobile } = useMobileQuery()
  const { user, onChange, onAuth } = auth || {}
  const device = isMobile ? layout.phone : layout.default
  const Layout = Layouts[device] || Layouts['DEFAULT']
  const showHeaderLogo = theme?.page?.showHeaderLogo

  return (
    <Private user={user}>
      <BackgroundImageWrapper
        theme={theme}
        className={pageClass({ theme })}
        imgsrc={themeProp(['page', 'backgroundImage'], theme)}
      >
        <div className={ResultLogo(rtl, isMobile, theme)}>
          {showHeaderLogo ? (
            <HeaderLogo
              theme={theme}
              mobile={isMobile ? 'true' : undefined}
              className={'logo1'}
              to={{ pathname: '/home', search: location.search }}
            />
          ) : null}
        </div>
        <Layout
          auth={auth}
          status={status}
          user={user}
          onChange={onChange}
          onPlayAgain={onPlayAgain('/game-intro')}
          onResubscribe={onResubscribe(onAuth)}
          theme={theme}
          mobile={isMobile}
          resultData={resultData}
          questions={questions}
        />
      </BackgroundImageWrapper>
    </Private>
  )
}

export default Result
