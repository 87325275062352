import * as R from 'ramda'
import React, { useContext } from 'react'
import { css, cx } from 'react-emotion'
import FontAwesome from 'react-fontawesome'
import { ConfigurationContext } from 'containers/ConfigurationProvider'

const theme = {
  azercell: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #a1a2a6;
    --active-fill-color: #b600ff;
    --active-text-color: #fff;
    --border-color: #b600ff;
    --past-fill-color: #b600ff;
    --past-text-color: #fff;
  `,
  beeline: css`
    --background-color: #ffc632;
    --fill-color: #f4f4f4;
    --text-color: #a1a2a6;
    --active-fill-color: #000;
    --active-text-color: #fff;
    --border-color: #000;
    --past-fill-color: #000;
    --past-text-color: #fff;
  `,
  celcinwin: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #a1a2a6;
    --active-fill-color: rgb(43, 30, 135);
    --active-text-color: #fff;
    --border-color: rgb(43, 30, 135);
    --past-fill-color: rgb(43, 30, 135);
    --past-text-color: #fff;
  `,
  djezzy: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #a1a2a6;
    --active-fill-color: #fff000;
    --active-text-color: #ff3c66;
    --border-color: #ff3c66;
    --past-fill-color: #fff000;
    --past-text-color: #ff3c66;
  `,
  flashwin: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #a1a2a6;
    --active-fill-color: rgb(43, 30, 135);
    --active-text-color: #fff;
    --border-color: rgb(43, 30, 135);
    --past-fill-color: rgb(43, 30, 135);
    --past-text-color: #fff;
  `,
  indosat: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #a1a2a6;
    --active-fill-color: #f7941d;
    --active-text-color: #fff;
    --border-color: #f7941d;
    --past-fill-color: #f7941d;
    --past-text-color: #fff;
  `,
  libyana: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #a1a2a6;
    --active-fill-color: rgb(43, 30, 135);
    --active-text-color: #fff;
    --border-color: rgb(43, 30, 135);
    --past-fill-color: rgb(43, 30, 135);
    --past-text-color: #fff;
  `,
  lifecell: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #a1a2a6;
    --active-fill-color: #22489a;
    --active-text-color: #fff;
    --border-color: #22489a;
    --past-fill-color: #22489a;
    --past-text-color: #fff;
  `,
  mtnNga: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #a1a2a6;
    --active-fill-color: rgb(15, 105, 135);
    --active-text-color: #fff;
    --border-color: rgb(43, 30, 135);
    --past-fill-color: rgb(15, 105, 135);
    --past-text-color: #fff;
  `,
  mtnZaf: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #a1a2a6;
    --active-fill-color: rgb(15, 105, 135);
    --active-text-color: #fff;
    --border-color: rgb(43, 30, 135);
    --past-fill-color: rgb(15, 105, 135);
    --past-text-color: #fff;
  `,
  syriatel: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #a1a2a6;
    --active-fill-color: #f60000;
    --active-text-color: #fff;
    --border-color: #f60000;
    --past-fill-color: #f60000;
    --past-text-color: #fff;
  `,
  ucell: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #a1a2a6;
    --active-fill-color: #642887;
    --active-text-color: #fff;
    --border-color: #642887;
    --past-fill-color: #642887;
    --past-text-color: #fff;
  `,
  mtnSAMusic: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #a1a2a6;
    --active-fill-color: #ffcb06;
    --active-text-color: #fff;
    --border-color: #ffcb06;
    --past-fill-color: #ffcb06;
    --past-text-color: #fff;
  `,
  stcbh: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #aaaaaa;
    --active-fill-color: #ff375e;
    --active-text-color: #fff;
    --border-color: #ff375e;
    --past-fill-color: #4f008c;
    --past-text-color: #fff;
  `,
  gloNga: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #aaaaaa;
    --active-fill-color: #4bb44e;
    --active-text-color: #fff;
    --border-color: #4bb44e;
    --past-fill-color: #4bb44e;
    --past-text-color: #fff;
  `,
  kcell: css`
    --background-color: #fff;
    --fill-color: #f4f4f4;
    --text-color: #aaaaaa;
    --active-fill-color: #652d86;
    --active-text-color: #fff;
    --border-color: #652d86;
    --past-fill-color: #652d86;
    --past-text-color: #fff;
  `,
}

const styles = {
  container: css`
    position: relative;
    z-index: 2;
    width: 40px;
    margin-top: -20px;
    height: 90%;
    border-radius: 30px;
    padding: 10px 0 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--background-color);
  `,
  pointWrapper: point => css`
    overflow: hidden;
    flex: ${point === 1 ? '0 1 auto' : '1 0 auto'};
  `,
  point: css`
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 18px;
      height: 18px;
      font-size: 10px;
      border-radius: 50%;
      background-color: var(--fill-color);
      color: var(--text-color);
      transition: all 0.3s linear;
    }
    &.past {
      > span {
        background-color: var(--past-fill-color);
        color: var(--past-text-color);
      }
    }
    &.active {
      > span {
        background-color: var(--active-fill-color);
        color: var(--active-text-color);
      }
    }
    &.active {
      padding: 2px;
      border: 1px solid var(--border-color);
    }
  `,
  line: css`
    width: 1px;
    margin: 0 auto;
    height: 100%;
    background-color: var(--fill-color);
    &.past,
    &.active {
      background-color: var(--border-color);
    }
  `,
}

const getQuestionRange = R.range(1, 11)

const getPointers = (range, position) =>
  R.map(point => {
    const className =
      position > point ? 'past' : position === point ? 'active' : ''
    return (
      <div key={point} className={cx(styles.pointWrapper(point))}>
        <div num={point} className={cx(styles.point, className)}>
          <span>{point}</span>
        </div>
        {point > 1 ? <div className={cx(styles.line, className)} /> : null}
      </div>
    )
  }, R.reverse(range))

const QIndex = ({ position }) => {
  const { meta } = useContext(ConfigurationContext)
  const appliedTheme = theme[meta.installation]

  return (
    <div className={cx(appliedTheme, styles.container)}>
      {getPointers(getQuestionRange, position, theme)}
      <FontAwesome
        name="question"
        style={{
          position: 'absolute',
          bottom: '6px',
          left: '25%',
          fontSize: '32px',
          color: 'var(--active-fill-color)',
        }}
      />
    </div>
  )
}

export default QIndex
