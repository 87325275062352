import React from 'react'
import Helmet from 'react-helmet'
// import sha256 from 'js-sha256'

const SnapChat = React.memo(({ snapChatId, msisdn }) => {
  // const hashedMsisdn = msisdn ? sha256(msisdn) : undefined
  return snapChatId ? (
    <Helmet>
      <script defer>
        {`
          (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function(){a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};a.queue=[];var s='script';r=t.createElement(s);r.defer=!0;r.src=n;var u=t.getElementsByTagName(s)[0];u.parentNode.insertBefore(r,u);})(window,document,'https://sc-static.net/scevent.min.js');

          snaptr(
            'init',
            '${snapChatId}',
            ${'' /*{'user_hashed_phone_number': '${hashedMsisdn}'}*/}
          );

          snaptr('track', 'PAGE_VIEW');
        `}
      </script>
    </Helmet>
  ) : null
})

export default SnapChat
