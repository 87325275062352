import React, {
  Fragment,
  useContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from 'react'
import qs from 'query-string'
import { I18nContext } from 'containers/I18nProvider'
import { setStorageItem } from 'containers/utils'
import { getTokenValues, getUtm } from 'containers/utils/authProvider'
import { read, create } from '@logicea/xhr-helpers/lib'
import SubscriptionConsent from 'components/SubscriptionConsent'

const initialState = {
  loading: false,
  errors: undefined,
  data: undefined,
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'FETCH_PENDING':
      return { ...state, loading: true }
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, errors: undefined, data: payload }
    case 'FETCH_FAILURE':
      return { ...state, loading: false, data: undefined, errors: payload }
    default:
      throw new Error()
  }
}

const fetchPending = () => ({ type: 'FETCH_PENDING' })
const fetchSuccess = payload => ({ type: 'FETCH_SUCCESS', payload })
const fetchFailure = payload => ({ type: 'FETCH_FAILURE', payload })

const BeelineImpl = ({ auth, location, history }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    i18n: { lang },
  } = useContext(I18nContext)
  const search = useMemo(() => qs.parse(location.search) || {}, [
    location.search,
  ])
  const { data } = state
  const urlUtm = getUtm(search)
  const { user, onHEConsent, onChange } = auth
  const { headerEnrichment, token } = user

  const discard = useCallback(() => {
    onChange({ ...user, headerEnrichment: false })
  }, [user, onChange])

  const consent = useCallback(
    ({ lang, ...utm }) => async () => {
      try {
        const tokens = await create('/p10/public/consent/validate', {
          utm,
          language: lang,
        })
        setStorageItem('auth', JSON.stringify(tokens))
        const authUser = getTokenValues(tokens)
        onHEConsent(authUser)
      } catch (e) {
        // do nothing
      }
    },
    [onHEConsent]
  )

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchPending())
      try {
        const data = await read('/p10/public/consent')
        data && dispatch(fetchSuccess(data))
      } catch (e) {
        dispatch(fetchFailure(e))
      }
    }
    if (headerEnrichment) {
      !data && fetchData()
    }
  }, [data, headerEnrichment])

  return !token && headerEnrichment ? (
    <SubscriptionConsent
      location={location}
      history={history}
      customClose={discard}
      customConsent={consent({ ...urlUtm, lang })}
    />
  ) : (
    <Fragment />
  )
}

export default BeelineImpl
