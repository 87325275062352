import React, { useContext } from 'react'
import FontAwesome from 'react-fontawesome'
import { Link } from 'react-router-dom'
import styled, { css } from 'react-emotion'
import { themeProp, hexToRgbA } from 'utils/theming'
import { IsAuthenticated } from 'lib/auth-utils'
import { BuyButton } from 'components/UserMenu'
import Conditional from 'containers/Conditional'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { __RouterContext } from 'react-router'
import { I18nContext } from 'containers/I18nProvider'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import { useDataLayerEvents } from 'containers/dataLayerEvents'

const Container = styled('div')`
  min-height: 30px;
  max-height: 30px;
  font-size: 13px;
  width: fit-content;
  min-width: 50%;
  margin: 5px 0;
  padding: 3px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-self: center;
  border-radius: 9px;
  color: ${({ theme }) => themeProp(['mobileUserMenu', 'color'], theme)};
  text-align: center;
  background-color: ${({ theme }) =>
    themeProp(['mobileUserMenu', 'backgroundColor'], theme)};
  gap: 10px;

  > div {
    display: flex;
    justify-content: space-around;
    flex: 1 0 auto;
    align-items: center;
    gap: 10px;
  }

  > div:nth-child(2) {
    border-left: 1px solid;
    border-color: ${({ theme }) =>
      hexToRgbA(themeProp(['mobileUserMenu', 'color'], theme), 0.4)};

    padding: 0 0 0 10px;
  }
  div:nth-child(3) {
    padding: 0 0 0 10px;
    border-left: 1px solid;
    border-color: ${({ theme }) =>
      hexToRgbA(themeProp(['mobileUserMenu', 'color'], theme), 0.4)};
  }
`
const mobileBuyButton = theme => css`
  color: ${themeProp(['mobileUserMenu', 'buyButtonColor'], theme)} !important;
  background-color: ${themeProp(
    ['mobileUserMenu', 'buyButtonBackground'],
    theme
  )};
  font-size: 10px;
  padding: 2px 6px;
  line-height: unset;
`

const Mobilespancontainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
`
const WalletStyle = css`
  display: inline-block;
  width: 13px;
  height: 10px;
  background-size: contain;
  background-repeat: no-repeat;
`

const MobileUserMenu = ({ user }) => {
  const {
    i18n: { t },
  } = useContext(I18nContext)
  const { theme } = useContext(ConfigurationContext)
  const { location } = useContext(__RouterContext)
  const {
    credits: crds,
    wallet,
    canSelectGame,
    totalPoints,
    enableBuy,
    username,
    id,
  } = user
  const credits = crds ? crds.length : undefined
  const balance = wallet ? wallet.balance : undefined
  const { dataLayerPush } = useDataLayerEvents()
  const userId = !!id ? id : ''
  const userType =
    user?.userStatus === 'SUBSCRIBED' ? 'subscribed' : 'anonymous'
  const userTypeAdvanced = !!username
    ? user?.userStatus === 'SUBSCRIBED'
      ? 'subscribed'
      : 'registered'
    : 'anonymous'
  const queryString = window.location.search
  const getPathName = window.location.pathname.split('&')
  const pathName = getPathName[0]
  const urlParams = new URLSearchParams(queryString)
  const {
    meta: { defaultLang },
  } = useContext(ConfigurationContext)
  const dataLayerObject = {
    event: 'selectGameMode',
    screenName: '/selectMode',
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userTypeAdvanced,
  }
  const dataLayerObject2 = {
    event: 'buyCredit',
    screenName: pathName,
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userType,
  }
  const UM = (
    <Container theme={theme}>
      <div>
        <Link to={{ pathname: '/account', search: location.search }}>
          <Mobilespancontainer>
            <FontAwesome name="heart" />
            <span>({Number(credits)})</span>
          </Mobilespancontainer>
        </Link>
        {user.canBuy && enableBuy ? (
          <BuyButton
            onClick={() => dataLayerPush(dataLayerObject2)}
            className={mobileBuyButton(theme)}
            theme={theme}
            to={{
              pathname: '/credits',
              state: { returnTo: location.pathname },
              search: location.search,
            }}
          >
            {t('buy.button')}
          </BuyButton>
        ) : null}
      </div>
      <Conditional condition="pointsEnabled">
        <div>
          <Link
            to={{ pathname: '/account', search: location.search }}
            onClick={() => dataLayerPush(dataLayerObject)}
          >
            <Mobilespancontainer>
              <FontAwesome name="star" />
              <span>
                {canSelectGame ? Number(totalPoints) : Number(user.points)}
              </span>
            </Mobilespancontainer>
          </Link>
        </div>
      </Conditional>
      {wallet ? (
        <div>
          <Link to={{ pathname: '/account', search: location.search }}>
            <Mobilespancontainer>
              <BackgroundImageWrapper
                className={WalletStyle}
                imgsrc={`/images/wallet.svg`}
              />
              <span>
                ({t('mywallet.prefix')}
                {Number(balance)})
              </span>
            </Mobilespancontainer>
          </Link>
        </div>
      ) : null}
    </Container>
  )

  return IsAuthenticated(user)(UM)
}

export default MobileUserMenu
