import * as R from 'ramda'
import React, { useReducer, useEffect, useCallback } from 'react'
import LoadingIntro from 'components/LoadingIntro'
import { useLocation } from 'react-router-dom'
import { read } from '@logicea/xhr-helpers/lib'
import { themeKey, themeProps, routeProp } from 'utils/theming'

export const ConfigurationContext = React.createContext()

const initialState = {
  loading: true,
  errors: undefined,
  conf: {},
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'CONF_PENDING':
      return { ...state, loading: true }
    case 'CONF_FAILURE':
      return { ...state, loading: false, errors: payload }
    case 'CONF_SUCCESS':
      return { ...state, loading: false, conf: payload }
    default:
      throw new Error()
  }
}

const confPending = () => ({ type: 'CONF_PENDING' })
const confSuccess = payload => ({ type: 'CONF_SUCCESS', payload })
const confFailure = payload => ({ type: 'CONF_FAILURE', payload })

const ConfigurationProvider = ({ children }) => {
  const location = useLocation()
  const [state, dispatch] = useReducer(reducer, initialState)

  const fetchAppConf = useCallback(async () => {
    try {
      dispatch(confPending())
      const cachehash = new Date().toISOString().slice(0, 10)
      const conf = await read(`/configuration?c=${cachehash}`)
      dispatch(confSuccess(conf))
    } catch (e) {
      confFailure(e)
    }
  }, [dispatch])

  useEffect(() => {
    R.isEmpty(state.conf) && fetchAppConf()
  }, [state.conf, fetchAppConf])

  const { loading, conf } = state
  const { meta } = conf
  const loc = themeKey(location)
  const theme = loading ? {} : themeProps(loc, conf)
  const layout = loading
    ? { default: 'DESKTOP', phone: 'MOBILE' }
    : routeProp(loc, 'layout', conf)
  const links = {
    navlinks: loading ? [] : routeProp(loc, 'navlinks', conf),
    footerlinks: loading ? [] : routeProp(loc, 'footerlinks', conf),
    menulinks: loading ? [] : routeProp(loc, 'menulinks', conf),
  }

  return state.loading ? (
    <div style={{ width: '100%', height: '100%' }}>
      <LoadingIntro />
    </div>
  ) : (
    <ConfigurationContext.Provider value={{ theme, layout, links, meta }}>
      {children}
    </ConfigurationContext.Provider>
  )
}

export default ConfigurationProvider
