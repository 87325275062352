import * as R from 'ramda'
import React, { Fragment, useContext, useEffect } from 'react'
import useMobileQuery from 'containers/MobileQuery'
import AnimatedContent from 'components/AnimatedContent'
import BackgroundImageWrapper from 'components/BackgroundImageWrapper'
import ActionForm from 'components/ActionForm'
import Footer from 'components/Footer'
import LangToggle from 'components/LangToggle'
import NavMenu from 'components/NavMenu'
import UserMenu, { FullLogo } from 'components/UserMenu'
import PinOverlay from 'components/PinOverlay'
import SubscriptionOverlay from 'components/SubscriptionOverlay'
import MobileUserMenu from 'components/MobileUserMenu'
import MobileHeader from 'components/MobileHeader'
import MobileMenu from 'components/MobileMenu'
import FeedSlider from 'components/FeedSlider'
import styled, { css } from 'react-emotion'
import { slideFromRight, slideFromLeft, slideFromTop } from 'animations'
import { themeProp } from 'utils/theming'
import { isAuthenticated } from 'lib/auth-utils'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { AuthenticationContext } from 'containers/AuthProvider'
import { I18nContext } from 'containers/I18nProvider'
import { useLocation } from 'react-router-dom'
import useInterval from 'containers/IntervalProvider'
import { getSessionTokens } from 'containers/utils'
import { NavLink } from 'react-router-dom'
import { FeedContext } from 'containers/FeedProvider'

const hasSentSms = R.compose(Boolean, R.prop('sms'))
const hasPinPrompted = R.compose(Boolean, R.prop('pinPrompted'))
const hasNotPinPrompted = R.complement(hasPinPrompted)
const shouldOverlay = R.allPass([hasSentSms, hasNotPinPrompted])
const shouldPinOverlay = R.anyPass([
  R.propEq('userStatus', 'SUBSCRIBED'),
  R.propEq('userStatus', 'UNSUBSCRIBED'),
  // R.propEq('userStatus', 'REGISTERED'),
])

const pageClass = ({ theme, mobile, nologo }) => css`
  font-family: ${themeProp(['page', 'fontFamily'], theme)};
  color: ${themeProp(['page', 'color'], theme)};
  background-color: ${themeProp(['page', 'backgroundColor'], theme)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${mobile ? '50%' : '50%'};
  width: 100vw;
  height: 100dvh;
  max-height: -webkit-fill-available;
  display: flex;
  margin: 0 auto;
  flex-flow: column;
  transition: background-color 0.2s ease-in;
  > div:nth-child(1) {
    min-height: ${nologo ? 'fit-content' : '56px'};
    max-height: ${nologo ? 'fit-content' : '103px'};
  }
`
export const logoFirstMenuContainer = rtl => css`
  display: flex;
  flex-direction: ${rtl ? 'row-reverse' : 'row'};
  gap: 54px;
  align-items: center;
`
const MainWrapper = styled('div')`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
`

const MainSection = styled('section')`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: ${({ feedType }) => (feedType === 'NONE' ? '64px 0 0' : '0')};
  padding: 2% 80px 10px;
  z-index: 12;
  justify-content: space-between;
  text-align: ${({ rtl }) => (rtl ? 'right' : 'left')};
  box-sizing: border-box;
`

const MainContent = styled('div')`
  overflow: ${({ mobile }) => (mobile ? 'auto' : 'initial')};
  color: ${({ theme }) => themeProp(['content', 'color'], theme)};
  flex: 1 0 auto;
  position: relative;
  top: auto;
  max-height: ${({ mobile, location }) =>
    mobile &&
    location.pathname !== '/home' &&
    !location.pathname.includes(';jsession')
      ? 'fit-content'
      : '100%'};
  max-width: 725px;
  padding: 20px 0 0;
  > div {
    height: 100%;
  }
`

const StyledOverlay = styled('div')`
  font-family: ${({ theme }) => themeProp(['page', 'fontFamily'], theme)};
  position: initial;
`

export const LangHolder = styled('div')`
  height: 45px;
  padding: 0 0 0 12px;
  display: flex;
  justify-content: ${({ rtl }) => (rtl ? 'flex-start' : 'flex-end')};
  align-items: center;
  &.topLogo {
    margin: ${({ rtl }) => (rtl ? '0 45px 0 0 ' : '0 0 0 45px')};
  }
`

const NoUserHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 103px;
  font-size: 14px;
  padding: 44px 80px 0;
  flex-direction: ${({ rtl }) => (rtl ? 'row-reverse' : 'row')};
  &.logoFirst {
    padding: 15px 54px;
    height: fit-content;
    align-items: center;
    background-color: ${({ theme }) =>
      themeProp(['header', 'backgroundColor'], theme)};
    border-bottom: ${({ theme }) =>
      themeProp(['header', 'borderBottom'], theme)};
  }
  &.topLogo {
    padding: ${({ rtl }) =>
      rtl ? '10px 80px 10px 25px' : '10px 25px 10px 80px'};
    height: initial;
    background-color: ${({ theme }) =>
      themeProp(['header', 'backgroundColor'], theme)};
  }
`

export const NavLangContainer = styled('div')`
  display: flex;
  align-items: center;
`

const CenterSection = styled('div')`
  display: flex;
  margin: 0 15px;
`
export const dualLogoContainer = rtl => css`
  display: flex;
  gap: 15px;
  flex-direction: ${rtl ? 'row-reverse' : 'row'};
`
export const headerImageBorder = theme => css`
  border: 1px solid ${themeProp(['header', 'borderedLogoColor'], theme)};
`

export const HeaderLogo = styled(NavLink)`
  cursor: pointer !important;
  background-size: contain !important;
  align-self: center;
  &.logoFirst {
    gap: 50px;
  }
  &.unclickable {
    pointer-events: none;
  }
  &.logo1 {
    display: ${({ theme }) => {
      const src = themeProp(['header', 'headerLogo_1'], theme)
      return src ? 'inline-block' : 'none'
    }};

    width: ${({ mobile, theme }) => {
      const width = mobile
        ? themeProp(['header', 'headerMobileLogoWidth_1'], theme)
        : themeProp(['header', 'headerDesktopLogoWidth_1'], theme)
      return width ? width : mobile ? '53px' : '80px'
    }};
    height: ${({ mobile, theme }) => {
      const height = mobile
        ? themeProp(['header', 'headerMobileLogoHeight_1'], theme)
        : themeProp(['header', 'headerDesktopLogoHeight_1'], theme)
      return height ? height : mobile ? '53px' : '80px'
    }};
    background: ${({ theme, mobile }) => {
      return mobile
        ? `url(${themeProp(
            ['header', 'headerLogo_1_mobile'],
            theme
          )}) no-repeat`
        : `url(${themeProp(['header', 'headerLogo_1'], theme)}) no-repeat`
    }};
  }
  &.logo2 {
    display: ${({ theme }) => {
      const src = themeProp(['header', 'headerLogo_2'], theme)
      return src ? 'inline-block' : 'none'
    }};
    width: ${({ mobile, theme }) => {
      const width = mobile
        ? themeProp(['header', 'headerMobileLogoWidth_2'], theme)
        : themeProp(['header', 'headerDesktopLogoWidth_2'], theme)
      return width ? width : mobile ? '53px' : '80px'
    }};
    height: ${({ mobile, theme }) => {
      const height = mobile
        ? themeProp(['header', 'headerMobileLogoHeight_2'], theme)
        : themeProp(['header', 'headerDesktopLogoHeight_2'], theme)
      return height ? height : mobile ? '53px' : '80px'
    }};
    background: ${({ theme }) =>
      `url(${themeProp(['header', 'headerLogo_2'], theme)}) no-repeat`};
  }
  &.logo3 {
    display: ${({ theme }) => {
      const src = themeProp(['header', 'headerLogo_3'], theme)
      return src ? 'inline-block' : 'none'
    }};
    width: ${({ mobile, theme }) => {
      const width = mobile
        ? themeProp(['header', 'headerMobileLogoWidth_3'], theme)
        : themeProp(['header', 'headerDesktopLogoWidth_3'], theme)
      return width ? width : mobile ? '53px' : '80px'
    }};
    height: ${({ mobile, theme }) => {
      const height = mobile
        ? themeProp(['header', 'headerMobileLogoHeight_3'], theme)
        : themeProp(['header', 'headerDesktopLogoHeight_3'], theme)
      return height ? height : mobile ? '53px' : '80px'
    }};
    background: ${({ theme }) =>
      `url(${themeProp(['header', 'headerLogo_3'], theme)}) no-repeat`};
  }
`

/*
 *                  rtl
 *  __________  __________
 * |____N___L| |L___N____|
 * | L |     | |      | L|
 * | A |   C | |   C  | A|
 * |   |     | |      |  |
 * |___|_____| |______|__|
 * |____F____| |____F____|
 *
 * NavMenu, Action, Content, Footer, Logo
 */

const TOP_LOGO_DESKTOP = ({
  location,
  rtl,
  theme,
  navlinks,
  footerlinks,
  content,
}) => {
  const { auth } = useContext(AuthenticationContext)
  const animation = rtl ? slideFromLeft : slideFromRight
  const user = auth ? auth.user : null
  const { feed } = React.useContext(FeedContext)
  const feedType = feed.feedType || 'POINTS'

  return (
    <BackgroundImageWrapper
      imgsrc={themeProp(['page', 'backgroundImage'], theme)}
      className={pageClass({ theme, nologo: true })}
      theme={theme}
    >
      {isAuthenticated(user) ? (
        <UserMenu items={navlinks} topLogo />
      ) : (
        <NoUserHeader rtl={rtl} className={'topLogo'} theme={theme}>
          <NavLangContainer>
            <NavMenu location={location} items={navlinks} top />
            <LangHolder className={'topLogo'}>
              <LangToggle />
            </LangHolder>
          </NavLangContainer>
          <HeaderLogo
            theme={theme}
            to={{ pathname: '/home', search: location.search }}
            className={'logo2'}
          />
        </NoUserHeader>
      )}
      <MainWrapper>
        {feedType !== 'NONE' ? <FeedSlider /> : null}
        <MainSection feedType={feedType} rtl={rtl}>
          {!rtl ? <ActionForm /> : null}
          <MainContent theme={theme} rtl={rtl}>
            <AnimatedContent animation={animation}>{content}</AnimatedContent>
          </MainContent>
          {rtl ? <ActionForm /> : null}
        </MainSection>
      </MainWrapper>
      {shouldOverlay(user) ? (
        shouldPinOverlay(user) ? (
          <PinOverlay />
        ) : (
          <SubscriptionOverlay />
        )
      ) : null}
      <Footer items={footerlinks} />
    </BackgroundImageWrapper>
  )
}

/*
 *                  rtl
 *  __________  __________
 * |____N____| |____N____|
 * | L |     | |      | L|
 * | A |   C | |   C  | A|
 * |   |     | |      |  |
 * |___|_____| |______|__|
 * |____F____| |____F____|
 *
 * NavMenu, Action, Content, Footer, Logo
 */

const TOP_MENUS_DESKTOP = ({
  location,
  rtl,
  theme,
  navlinks,
  footerlinks,
  content,
}) => {
  const { auth } = useContext(AuthenticationContext)
  const animation = rtl ? slideFromLeft : slideFromRight
  const user = auth ? auth.user : null
  const { feed } = React.useContext(FeedContext)
  const feedType = feed.feedType || 'POINTS'

  return (
    <BackgroundImageWrapper
      imgsrc={themeProp(['page', 'backgroundImage'], theme)}
      className={pageClass({ theme, nologo: true })}
      theme={theme}
    >
      {isAuthenticated(user) ? (
        <UserMenu items={navlinks} top />
      ) : (
        <NoUserHeader rtl={rtl}>
          <NavMenu location={location} items={navlinks} top />
          <CenterSection>
            <HeaderLogo
              theme={theme}
              to={{ pathname: '/home', search: location.search }}
              className={`${'unclickable'} ${'logo2'}`}
            />
          </CenterSection>
          <LangHolder>
            <LangToggle />
          </LangHolder>
        </NoUserHeader>
      )}
      <MainWrapper>
        {feedType !== 'NONE' ? <FeedSlider /> : null}
        <MainSection feedType={feedType} rtl={rtl}>
          {!rtl ? <ActionForm /> : null}
          <MainContent theme={theme} rtl={rtl}>
            <AnimatedContent animation={animation}>{content}</AnimatedContent>
          </MainContent>
          {rtl ? <ActionForm /> : null}
        </MainSection>
      </MainWrapper>
      {shouldOverlay(user) ? (
        shouldPinOverlay(user) ? (
          <PinOverlay />
        ) : (
          <SubscriptionOverlay />
        )
      ) : null}
      <Footer items={footerlinks} />
    </BackgroundImageWrapper>
  )
}

const LOGO_FIRST_DESKTOP = ({
  location,
  rtl,
  theme,
  navlinks,
  footerlinks,
  content,
}) => {
  const { auth } = useContext(AuthenticationContext)
  const animation = rtl ? slideFromLeft : slideFromRight
  const user = auth ? auth.user : null
  const { feed } = React.useContext(FeedContext)
  const feedType = feed.feedType || 'POINTS'

  return (
    <BackgroundImageWrapper
      imgsrc={themeProp(['page', 'backgroundImage'], theme)}
      className={pageClass({ theme, nologo: true })}
      theme={theme}
    >
      {isAuthenticated(user) ? (
        <UserMenu items={navlinks} logoFirst />
      ) : (
        <NoUserHeader rtl={rtl} className="logoFirst" theme={theme}>
          <div className={logoFirstMenuContainer(rtl)}>
            <div className={dualLogoContainer(rtl)}>
              <HeaderLogo
                theme={theme}
                to={{ pathname: '/home', search: location.search }}
                className={`${'logo2'} ${'logoFirst'}`}
              />
              <div className={headerImageBorder(theme)}></div>
              <HeaderLogo
                theme={theme}
                to={{ pathname: '/home', search: location.search }}
                className={`${'logo3'}`}
              />
            </div>
            <NavMenu location={location} items={navlinks} logoFirst />
          </div>
          <LangHolder>
            <LangToggle />
          </LangHolder>
        </NoUserHeader>
      )}
      <MainWrapper>
        {feedType !== 'NONE' ? <FeedSlider /> : null}
        <MainSection feedType={feedType} rtl={rtl}>
          {!rtl ? <ActionForm /> : null}
          <MainContent theme={theme} rtl={rtl}>
            <AnimatedContent animation={animation}>{content}</AnimatedContent>
          </MainContent>
          {rtl ? <ActionForm /> : null}
        </MainSection>
      </MainWrapper>
      {shouldOverlay(user) ? (
        shouldPinOverlay(user) ? (
          <PinOverlay />
        ) : (
          <SubscriptionOverlay />
        )
      ) : null}
      <Footer items={footerlinks} />
    </BackgroundImageWrapper>
  )
}

/*
 *                  rtl
 *  __________  __________
 * |____N____| |____N____|
 * |   |     | |      |  |
 * | A |   C | |   C  | A|
 * |   |     | |      |  |
 * |___|_____| |______|__|
 * |____F____| |____F____|
 *
 * NavMenu, Action, Content, Footer
 */

const DESKTOP = ({ location, rtl, theme, navlinks, footerlinks, content }) => {
  const { auth } = useContext(AuthenticationContext)
  const animation = rtl ? slideFromLeft : slideFromRight
  const user = auth ? auth.user : null
  return (
    <BackgroundImageWrapper
      imgsrc={themeProp(['page', 'backgroundImage'], theme)}
      className={pageClass({ theme })}
    >
      {isAuthenticated(user) ? (
        <UserMenu />
      ) : (
        <NoUserHeader rtl={rtl}>
          <FullLogo rtl={rtl}>
            <HeaderLogo
              theme={theme}
              rtl={rtl}
              className={'logo1'}
              to={{ pathname: '/home', search: location.search }}
            />
          </FullLogo>
          <LangHolder>
            <LangToggle />
          </LangHolder>
        </NoUserHeader>
      )}
      <MainWrapper>
        <NavMenu items={navlinks} />
        <MainSection rtl={rtl}>
          {!rtl ? <ActionForm /> : null}
          <MainContent theme={theme} rtl={rtl}>
            <AnimatedContent animation={animation}>{content}</AnimatedContent>
          </MainContent>
          {rtl ? <ActionForm /> : null}
        </MainSection>
      </MainWrapper>
      {shouldOverlay(user) ? (
        shouldPinOverlay(user) ? (
          <PinOverlay />
        ) : (
          <SubscriptionOverlay />
        )
      ) : null}
      <Footer items={footerlinks} />
    </BackgroundImageWrapper>
  )
}

/*
 * ACCOUNT
 *  __________
 * |____H____|
 * |         |
 * |         |
 * |    C    |
 * |         |
 * |_________|
 *
 * Header, Content
 */

const ACCOUNT = ({ rtl, theme, content, mobile, location, user }) => {
  return (
    <BackgroundImageWrapper
      imgsrc={themeProp(['page', 'backgroundImage'], theme)}
      className={pageClass({ theme })}
    >
      {mobile ? (
        <Fragment>
          <MobileHeader location={location} user={user} rtl={rtl} />
          {location.state && location.state.menu ? (
            <MobileMenu location={location} />
          ) : null}
        </Fragment>
      ) : (
        <UserMenu accountStyling />
      )}
      <Fragment>{content}</Fragment>
    </BackgroundImageWrapper>
  )
}

const LOGO_FIRST_ACCOUNT = ({
  rtl,
  theme,
  content,
  mobile,
  location,
  user,
}) => {
  return (
    <BackgroundImageWrapper
      imgsrc={themeProp(['page', 'backgroundImage'], theme)}
      className={pageClass({ theme, nologo: true })}
    >
      {mobile ? (
        <Fragment>
          <MobileHeader location={location} user={user} rtl={rtl} logoFirst />
          {location.state && location.state.menu ? (
            <MobileMenu location={location} />
          ) : null}
        </Fragment>
      ) : (
        <UserMenu logoFirst />
      )}
      <Fragment>{content}</Fragment>
    </BackgroundImageWrapper>
  )
}

const ACCOUNT_TOP_LOGO = ({ rtl, theme, content, mobile, location, user }) => {
  return (
    <BackgroundImageWrapper
      imgsrc={themeProp(['page', 'backgroundImage'], theme)}
      className={pageClass({ theme })}
    >
      {mobile ? (
        <Fragment>
          <MobileHeader
            theme={theme}
            location={location}
            user={user}
            rtl={rtl}
            topLogo
          />
          {location.state && location.state.menu ? (
            <MobileMenu location={location} />
          ) : null}
        </Fragment>
      ) : (
        <UserMenu accountStyling accountTopLogo />
      )}
      <Fragment>{content}</Fragment>
    </BackgroundImageWrapper>
  )
}
const REVERSE_LOGO_MOBILE = ({ location, theme, rtl, content }) => {
  const { auth } = useContext(AuthenticationContext)
  const animation = rtl ? slideFromLeft : slideFromRight
  const user = auth ? auth.user : null

  return (
    <BackgroundImageWrapper
      imgsrc={themeProp(['page', 'backgroundImage'], theme)}
      className={pageClass({ theme, mobile: true })}
    >
      <MobileHeader reverseLogo />
      <MobileUserMenu user={user} />
      <ActionForm mobile>
        <MainContent
          theme={theme}
          location={location}
          hasUser={isAuthenticated(user)}
          mobile
        >
          <AnimatedContent animation={animation} mobile>
            {content}
          </AnimatedContent>
        </MainContent>
      </ActionForm>
      {shouldOverlay(user) ? (
        shouldPinOverlay(user) ? (
          <PinOverlay mobile />
        ) : (
          <SubscriptionOverlay mobile />
        )
      ) : null}
      {location.state && location.state.menu ? <MobileMenu /> : null}
    </BackgroundImageWrapper>
  )
}

/*
 * Mobile Static Layout
 *  __________
 * |L___H__LM|
 * |         |
 * |    C    |
 * |_________|
 * |    A    |
 * |_________|
 *
 * Header, Content, Action
 */

const BORDERED_LOGOS_MOBILE = ({ location, theme, rtl, content }) => {
  const { auth } = useContext(AuthenticationContext)
  const animation = rtl ? slideFromLeft : slideFromRight
  const user = auth ? auth.user : null

  return (
    <BackgroundImageWrapper
      imgsrc={themeProp(['page', 'backgroundImage'], theme)}
      className={pageClass({ theme, mobile: true })}
    >
      <MobileHeader borderedLogos />
      <MobileUserMenu user={user} />
      <ActionForm mobile>
        <MainContent
          theme={theme}
          location={location}
          hasUser={isAuthenticated(user)}
          mobile
        >
          <AnimatedContent animation={animation} mobile>
            {content}
          </AnimatedContent>
        </MainContent>
      </ActionForm>
      {shouldOverlay(user) ? (
        shouldPinOverlay(user) ? (
          <PinOverlay mobile />
        ) : (
          <SubscriptionOverlay mobile />
        )
      ) : null}
      {location.state && location.state.menu ? <MobileMenu /> : null}
    </BackgroundImageWrapper>
  )
}

const TOP_LOGO_MOBILE = ({ location, theme, rtl, content }) => {
  const { auth } = useContext(AuthenticationContext)
  const animation = rtl ? slideFromLeft : slideFromRight
  const user = auth ? auth.user : null

  return (
    <BackgroundImageWrapper
      imgsrc={themeProp(['page', 'backgroundImage'], theme)}
      className={pageClass({ theme, mobile: true })}
    >
      <MobileHeader topLogo />
      <MobileUserMenu user={user} />
      <ActionForm mobile>
        <MainContent
          theme={theme}
          location={location}
          hasUser={isAuthenticated(user)}
          mobile
        >
          <AnimatedContent animation={animation} mobile>
            {content}
          </AnimatedContent>
        </MainContent>
      </ActionForm>
      {shouldOverlay(user) ? (
        shouldPinOverlay(user) ? (
          <PinOverlay mobile />
        ) : (
          <SubscriptionOverlay mobile />
        )
      ) : null}
      {location.state && location.state.menu ? <MobileMenu /> : null}
    </BackgroundImageWrapper>
  )
}

/*
 * Mobile Static Layout
 *  __________
 * |____H____|
 * |         |
 * |    C    |
 * |_________|
 * |    A    |
 * |_________|
 *
 * Header, Content, Action
 */

const MOBILE = ({ location, theme, rtl, content }) => {
  const { auth } = useContext(AuthenticationContext)
  const animation = rtl ? slideFromLeft : slideFromRight
  const user = auth ? auth.user : null

  return (
    <BackgroundImageWrapper
      imgsrc={themeProp(['page', 'backgroundImage'], theme)}
      className={pageClass({ theme, mobile: true })}
    >
      <MobileHeader />
      <MobileUserMenu user={user} />
      <ActionForm mobile>
        <MainContent
          theme={theme}
          location={location}
          hasUser={isAuthenticated(user)}
          mobile
        >
          <AnimatedContent animation={animation} mobile>
            {content}
          </AnimatedContent>
        </MainContent>
      </ActionForm>
      {shouldOverlay(user) ? (
        shouldPinOverlay(user) ? (
          <PinOverlay mobile />
        ) : (
          <SubscriptionOverlay mobile />
        )
      ) : null}
      {location.state && location.state.menu ? <MobileMenu /> : null}
    </BackgroundImageWrapper>
  )
}

const LOGO_FIRST_MOBILE = ({ location, theme, rtl, content }) => {
  const { auth } = useContext(AuthenticationContext)
  const animation = rtl ? slideFromLeft : slideFromRight
  const user = auth ? auth.user : null

  return (
    <BackgroundImageWrapper
      imgsrc={themeProp(['page', 'backgroundImage'], theme)}
      className={pageClass({ theme, mobile: true })}
    >
      <MobileHeader logoFirst />
      <MobileUserMenu user={user} />
      <ActionForm mobile>
        <MainContent
          theme={theme}
          location={location}
          hasUser={isAuthenticated(user)}
          mobile
        >
          <AnimatedContent animation={animation} mobile>
            {content}
          </AnimatedContent>
        </MainContent>
      </ActionForm>
      {shouldOverlay(user) ? (
        shouldPinOverlay(user) ? (
          <PinOverlay mobile />
        ) : (
          <SubscriptionOverlay mobile />
        )
      ) : null}
      {location.state && location.state.menu ? <MobileMenu /> : null}
    </BackgroundImageWrapper>
  )
}

/*
 *  ___________
 * |          |
 * |     C    |
 * |          |
 * |__________|
 * |_____F____|
 *
 * Content Footer (Overlay)
 */

const OVERLAY = ({ theme, mobile, content }) => (
  <StyledOverlay theme={theme}>
    <AnimatedContent overlay animation={slideFromTop} mobile={mobile}>
      {content}
    </AnimatedContent>
  </StyledOverlay>
)

/*
 *
 *  _________
 * |  _____ |
 * | |    | |
 * | | M  | |
 * | |____| |
 * |________|
 *
 * Modal
 */
const MODAL = ({ content }) => <div>{content}</div>

const layouts = {
  DESKTOP,
  MOBILE,
  OVERLAY,
  MODAL,
  TOP_MENUS_DESKTOP,
  TOP_LOGO_DESKTOP,
  LOGO_FIRST_DESKTOP,
  TOP_LOGO_MOBILE,
  ACCOUNT,
  ACCOUNT_TOP_LOGO,
  LOGO_FIRST_ACCOUNT,
  REVERSE_LOGO_MOBILE,
  BORDERED_LOGOS_MOBILE,
  LOGO_FIRST_MOBILE,
}

const DynamicLayout = ({ type, userPolling = false, children: content }) => {
  const {
    i18n: { rtl },
  } = useContext(I18nContext)
  const location = useLocation()
  const {
    auth: { user = {}, fetchCredits },
  } = useContext(AuthenticationContext)
  const {
    theme,
    links,
    layout,
    meta: { userPollingInterval },
  } = useContext(ConfigurationContext)
  const [setIntervalTime] = useInterval(fetchCredits)
  const { isMobile } = useMobileQuery()
  const sessionTokens = getSessionTokens()
  const token = sessionTokens ? sessionTokens.access_token : undefined
  const { credits } = user
  const hasCredits = credits && credits.length

  const props = {
    rtl,
    location,
    content,
    theme,
    mobile: isMobile,
    ...links,
  }

  const device = isMobile ? layout.phone : layout.default
  const Layout = layouts[type] || layouts[device]

  useEffect(() => {
    const interval =
      token &&
      !hasCredits &&
      Layout &&
      userPolling &&
      userPollingInterval &&
      userPollingInterval > 0
        ? userPollingInterval
        : null
    setIntervalTime(interval)
    return () => setIntervalTime(null)
  }, [
    Layout,
    setIntervalTime,
    userPolling,
    userPollingInterval,
    hasCredits,
    token,
  ])

  return Layout ? <Layout {...props} /> : null
}

export const ContentLayout = props => (
  <DynamicLayout userPolling={true} {...props} />
)
export const ModalLayout = props => <DynamicLayout type="MODAL" {...props} />
export const OverlayLayout = props => (
  <DynamicLayout type="OVERLAY" {...props} />
)
