import React, { useContext, Fragment } from 'react'
import { ConfigurationContext } from 'containers/ConfigurationProvider'

const UniversalStaticPixels = () => {
  const { meta } = useContext(ConfigurationContext)
  const enableStaticPixels = meta?.enableStaticPixels

  return enableStaticPixels ? (
    <Fragment>
      <script
        src="https://js.adsrvr.org/up_loader.1.1.0.js"
        type="text/javascript"
        defer
      ></script>
      <script type="text/javascript" defer>
        {`        
        ttd_dom_ready(function() {
          if (typeof TTDUniversalPixelApi === 'function') {
            var universalPixelApi = new TTDUniversalPixelApi();
            universalPixelApi.init("2tyjq2y", ["5swl8lw"], "https://insight.adsrvr.org/track/up");
          }
        });`}
      </script>
    </Fragment>
  ) : null
}

export default UniversalStaticPixels
