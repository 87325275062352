import {
  useReducer,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useMemo,
} from 'react'
import { read } from '@logicea/xhr-helpers/lib'
import { AuthenticationContext } from './AuthProvider'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'

const initialState = {
  loading: false,
  errors: null,
  isPublic: true,
  teasers: [],
}

const teaserPending = () => ({ type: 'TEASER_PENDING' })
const teaserSuccess = payload => ({ type: 'TEASER_SUCCESS', payload })
const teaserFailure = payload => ({ type: 'TEASER_FAILURE', payload })

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'TEASER_PENDING':
      return { ...state, loading: true, teasers: [] }
    case 'TEASER_FAILURE':
      return { ...state, loading: false, errors: payload }
    case 'TEASER_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: undefined,
        isPublic: payload.isPublic,
        teasers: payload.teasers,
      }
    default:
      throw new Error()
  }
}

const useTeaser = () => {
  const location = useLocation()
  const search = useMemo(() => qs.parse(location.search) || {}, [
    location.search,
  ])
  const urlParams = new URLSearchParams(search)
  const lpId = urlParams.get('lpId')
  const [state, dispatch] = useReducer(reducer, initialState)
  const { auth } = useContext(AuthenticationContext)
  const { isPublic } = state
  const {
    user: { token = '' },
  } = auth
  let valid = useRef()
  const tokenIsValid = !!token && token !== ''

  const fetchTeasers = useCallback(async () => {
    try {
      !!valid.current && dispatch(teaserPending())
      const teasers = await read('/p10/user/teaser', token)
      !!valid.current && dispatch(teaserSuccess({ teasers, isPublic: false }))
    } catch (e) {
      !!valid.current && dispatch(teaserFailure(e))
    }
  }, [token, dispatch])

  const fetchPublicTeasers = useCallback(async () => {
    try {
      !!valid.current && dispatch(teaserPending())
      const lpIdTeaser = lpId === null ? '' : `?lpId=${lpId}`
      const teasers = await read(`/p10/public/teaser${lpIdTeaser}`)

      !!valid.current && dispatch(teaserSuccess({ teasers, isPublic: true }))
    } catch (e) {
      !!valid.current && dispatch(teaserFailure(e))
    }
  }, [dispatch, lpId])

  useEffect(() => {
    valid.current = true
    if (tokenIsValid && isPublic) {
      fetchTeasers()
    } else if (!tokenIsValid) {
      fetchPublicTeasers()
    }
    return () => {
      valid.current = false
    }
  }, [tokenIsValid, isPublic, fetchPublicTeasers, fetchTeasers])

  return [state]
}

export default useTeaser
