import React, { useContext, Fragment } from 'react'
import styled from 'react-emotion'
import LangToggle from 'components/LangToggle'
import { NavLink } from 'react-router-dom'
import AccountToggle from 'components/AccountToggle'
import { NavLangContainer, LangHolder } from 'layouts/index'
import NavMenu from 'components/NavMenu'
import { themeProp, hexToRgbA } from 'utils/theming'
import { IsAuthenticated } from 'lib/auth-utils'
import Conditional from 'containers/Conditional'
import { ConfigurationContext } from 'containers/ConfigurationProvider'
import { AuthenticationContext } from 'containers/AuthProvider'
import { __RouterContext } from 'react-router'
import { I18nContext } from 'containers/I18nProvider'
import {
  HeaderLogo,
  dualLogoContainer,
  logoFirstMenuContainer,
  headerImageBorder,
} from 'layouts/index'
import { useDataLayerEvents } from 'containers/dataLayerEvents'

export const StyledHeader = styled('div')`
  height: ${({ mobile, toplogo, logoFirst }) =>
    mobile
      ? '56px'
      : toplogo
      ? 'initial'
      : logoFirst
      ? 'fit-content'
      : '103px'};
  font-size: 14px;
  padding: ${({ mobile, toplogo, logoFirst }) =>
    mobile
      ? '5px 20px 0'
      : toplogo
      ? '10px 25px 10px 80px'
      : logoFirst
      ? '15px 54px'
      : '44px 80px 0'};
  background-color: ${({ theme }) =>
    themeProp(['header', 'backgroundColor'], theme)};
  border-bottom: ${({ theme }) => themeProp(['header', 'borderBottom'], theme)};
  color: ${({ theme }) => themeProp(['header', 'color'], theme)};
  display: flex;
  justify-content: space-between;
  align-items: ${({ accountStyling, logoFirst }) =>
    accountStyling || logoFirst ? 'center' : 'baseline'};
`

const LeftSection = styled('div')`
  order: ${({ rtl }) => (rtl ? 2 : 0)};
  width: auto;
  height: ${({ top, toplogo }) => (top || toplogo ? 'auto' : '100%')};
  display: flex;
`

export const FullLogo = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ rtl }) => (rtl ? 'flex-end' : 'flex-start')};
`

const CenterSection = styled('div')`
  display: flex;
  order: 1;
  margin: 0 15px;
  justify-items: center;
`

const RightSection = styled('div')`
  order: ${({ rtl }) => (rtl ? 0 : 2)};
  width: auto;
  > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: ${({ rtl }) => (rtl ? 'row-reverse' : 'row')};
    gap: 15px;
  }
`

const SpanContainer = styled('div')`
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
  color: ${({ theme }) => themeProp(['usermenu', 'color'], theme)};
  display: flex;
  flex-direction: row;
  gap: 5px;

  span {
    display: inline-block;
    direction: initial;
  }
`
const Separator = styled('span')`
  width: 1px;
  height: 18px;
  display: inline-block;
  background-color: ${({ theme }) =>
    hexToRgbA(themeProp(['usermenu', 'color'], theme), 0.4)};
`

export const BuyButton = styled(NavLink)`
  padding: 4px 15px 2px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 8px;
  text-transform: uppercase;
  color: ${({ theme }) =>
    themeProp(['usermenu', 'buyColor'], theme)} !important;
  background-color: ${({ theme }) =>
    themeProp(['usermenu', 'buyBackgroundColor'], theme)};
`

const UserMenu = ({
  nodetail,
  mobile,
  full,
  items,
  top,
  topLogo,
  accountStyling,
  accountTopLogo,
  logoFirst,
}) => {
  const {
    i18n: { t, rtl },
  } = useContext(I18nContext)
  const { auth } = useContext(AuthenticationContext)
  const { theme } = useContext(ConfigurationContext)
  const { location } = useContext(__RouterContext)
  const { user } = auth
  const {
    credits: crds,
    wallet,
    canSelectGame,
    totalPoints,
    enableBuy,
    canBuy,
  } = user || {}
  const credits = crds ? crds.length : undefined
  const { dataLayerPush } = useDataLayerEvents()
  const userId = !!user?.id ? user?.id : ''
  const userType =
    user?.userStatus === 'SUBSCRIBED' ? 'subscribed' : 'anonymous'
  const queryString = window.location.search
  const getPathName = window.location.pathname.split('&')
  const pathName = getPathName[0]
  const urlParams = new URLSearchParams(queryString)
  const {
    meta: { defaultLang },
  } = useContext(ConfigurationContext)

  const dataLayerObject = {
    event: 'buyCredit',
    screenName: pathName,
    UILanguage: urlParams.get('lang') ?? defaultLang,
    userId: userId,
    userType: userType,
  }

  const logoFirstUM = (
    <StyledHeader
      theme={theme}
      mobile={mobile}
      accountStyling={accountStyling}
      logoFirst={logoFirst}
    >
      <LeftSection rtl={Number(rtl)} top={top} toplogo={Number(topLogo)}>
        <div className={logoFirstMenuContainer(rtl)}>
          <div className={dualLogoContainer(rtl)}>
            <HeaderLogo
              theme={theme}
              to={{ pathname: '/home', search: location.search }}
              className={` ${'logo2'}`}
            />
            <div className={headerImageBorder(theme)}></div>
            <HeaderLogo
              theme={theme}
              to={{ pathname: '/home', search: location.search }}
              className={` ${'logo3'}`}
            />
          </div>
          <NavMenu
            location={location}
            theme={theme}
            items={items}
            logoFirst={true}
          />
        </div>
      </LeftSection>
      <RightSection rtl={Number(rtl)} top={top} toplogo={Number(topLogo)}>
        {!nodetail ? (
          <div>
            <SpanContainer theme={theme} rtl={Number(rtl)}>
              <span>{t('user.menu.credits')}</span>
              <span>({Number(credits)})</span>
            </SpanContainer>
            {canBuy && enableBuy ? (
              <BuyButton
                onClick={() => dataLayerPush(dataLayerObject)}
                data-e2e="user-menu-buy"
                theme={theme}
                to={{
                  pathname: '/credits',
                  state: { returnTo: location.pathname },
                  search: location.search,
                }}
                rtl={rtl ? 1 : 0}
              >
                {t('user.menu.buy')}
              </BuyButton>
            ) : null}
            <Conditional condition="pointsEnabled">
              <Fragment>
                <Separator theme={theme} rtl={Number(rtl)} />
                <SpanContainer theme={theme} rtl={Number(rtl)}>
                  <span>{t('user.menu.chances')}</span>
                  <span>
                    ({canSelectGame ? Number(totalPoints) : Number(user.points)}
                    )
                  </span>
                </SpanContainer>
              </Fragment>
            </Conditional>
            {wallet ? (
              <Fragment>
                <Separator theme={theme} rtl={Number(rtl)} />
                <SpanContainer theme={theme} rtl={Number(rtl)}>
                  <span>{t('user.menu.mywallet')}</span>
                  <span>
                    ({t('mywallet.prefix')}
                    {Number(wallet?.balance)})
                  </span>
                </SpanContainer>
              </Fragment>
            ) : null}
            <Separator theme={theme} rtl={Number(rtl)} />
            <SpanContainer theme={theme} rtl={Number(rtl)}>
              <AccountToggle theme={theme} auth={auth} />
            </SpanContainer>
            <LangToggle theme={theme} />
          </div>
        ) : null}
      </RightSection>
    </StyledHeader>
  )

  const UM = (
    <StyledHeader
      theme={theme}
      mobile={mobile}
      accountStyling={accountStyling}
      toplogo={Number(topLogo)}
    >
      <LeftSection rtl={Number(rtl)} top={top} toplogo={Number(topLogo)}>
        {top ? (
          <NavMenu location={location} theme={theme} items={items} top={true} />
        ) : topLogo ? (
          <NavLangContainer>
            <NavMenu
              location={location}
              theme={theme}
              items={items}
              top={true}
            />
            <LangHolder className={'topLogo'}>
              <LangToggle theme={theme} />
            </LangHolder>
          </NavLangContainer>
        ) : !full ? (
          <FullLogo rtl={Number(rtl)}>
            <HeaderLogo
              theme={theme}
              to={
                !nodetail
                  ? { pathname: '/home', search: location.search }
                  : { pathname: '', search: location.search }
              }
              className={`${'logo1'}`}
            />
          </FullLogo>
        ) : (
          <HeaderLogo
            theme={theme}
            to={
              !nodetail
                ? { pathname: '/home', search: location.search }
                : { pathname: '', search: location.search }
            }
            className={`${'logo1'}`}
          />
        )}
      </LeftSection>
      {!topLogo && !accountTopLogo ? (
        <CenterSection>
          <HeaderLogo
            theme={theme}
            to={{ pathname: '/home', search: location.search }}
            className={`${'unclickable'} ${'logo2'}`}
          />
        </CenterSection>
      ) : null}
      <RightSection rtl={Number(rtl)} top={top} toplogo={Number(topLogo)}>
        {!nodetail ? (
          <div>
            <SpanContainer theme={theme} rtl={Number(rtl)}>
              <span>{t('user.menu.credits')}</span>
              <span>({Number(credits)})</span>
            </SpanContainer>

            {canBuy && enableBuy ? (
              <BuyButton
                onClick={() => dataLayerPush(dataLayerObject)}
                data-e2e="user-menu-buy"
                theme={theme}
                to={{
                  pathname: '/credits',
                  state: { returnTo: location.pathname },
                  search: location.search,
                }}
                rtl={rtl ? 1 : 0}
              >
                {t('user.menu.buy')}
              </BuyButton>
            ) : null}
            <Conditional condition="pointsEnabled">
              <Fragment>
                <Separator theme={theme} rtl={Number(rtl)} />
                <SpanContainer theme={theme} rtl={Number(rtl)}>
                  <span>{t('user.menu.chances')}</span>
                  <span>
                    ({canSelectGame ? Number(totalPoints) : Number(user.points)}
                    )
                  </span>
                </SpanContainer>
              </Fragment>
            </Conditional>
            {wallet ? (
              <Fragment>
                <Separator theme={theme} rtl={Number(rtl)} />
                <SpanContainer theme={theme} rtl={Number(rtl)}>
                  <span>{t('user.menu.mywallet')}</span>
                  <span>
                    ({t('mywallet.prefix')}
                    {Number(wallet?.balance)})
                  </span>
                </SpanContainer>
              </Fragment>
            ) : null}
            <Separator theme={theme} rtl={Number(rtl)} />
            <SpanContainer theme={theme} rtl={Number(rtl)}>
              <AccountToggle theme={theme} auth={auth} />
            </SpanContainer>
            {topLogo ? (
              <div>
                <HeaderLogo
                  theme={theme}
                  to={{ pathname: '/home', search: location.search }}
                  className={`${'logo2'}`}
                />
              </div>
            ) : (
              <LangToggle theme={theme} />
            )}
          </div>
        ) : null}
      </RightSection>
    </StyledHeader>
  )
  if (logoFirst) return IsAuthenticated(user)(logoFirstUM)
  return IsAuthenticated(user)(UM)
}

export default UserMenu
